import { useEffect, useState } from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import addedBill from "../../../Assets/icons/added_bill.png";
import deletedBill from "../../../Assets/icons/deleted_bill.png";
import uploadedBill from "../../../Assets/icons/uploaded_bill.png";
import getreturnBill from "../../../Assets/icons/getreturn_bill.png";
import { HiHomeModern } from "react-icons/hi2";
import { GrBasket } from "react-icons/gr";
import { RiCupFill } from "react-icons/ri";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  if (totalPages <= 6) {
    // If total pages are 6 or less, show all pages
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Calculate the range of page numbers to display

    // Adjust startPage and endPage if they cause the displayed pages to be more than 6
    if (window.innerWidth < 1280) {
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(totalPages, currentPage + 1);

      if (startPage === 1) {
        endPage = startPage + 2;
      } else if (endPage === totalPages) {
        startPage = endPage - 2;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
      // Push the calculated range of pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 3);

      if (startPage === 1) {
        endPage = startPage + 5;
      } else if (endPage === totalPages) {
        startPage = endPage - 5;
      } else {
        startPage = currentPage - 5 + 3;
        endPage = currentPage + 3;
      }
      // Push the calculated range of pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
  }

  return (
    <div className="flex items-center justify-center space-x-1">
      <div
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200 disabled:opacity-50"
      >
        <MdOutlineArrowBackIos className="w-4 h-4 text-[#686D76] " />
      </div>
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={` text-center w-6 py-0.5 text-xl text-[#686D76]  rounded-full hover:bg-gray-200 ${
            currentPage === number ? "bg-gray-300" : ""
          }`}
        >
          {number}
        </button>
      ))}
      <div
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-2 py-1 cursor-pointer rounded-md hover:bg-gray-200 disabled:opacity-50"
      >
        <MdArrowForwardIos className="w-4 h-4 text-[#686D76] " />
      </div>
    </div>
  );
};

const ItemNumber = ({ length }) => {
  return (
    <div>
      <p className=" text-[#686D76] text-BASE">Faturalar 1 den {length}</p>
    </div>
  );
};

function timeAgo(createdInfo) {
  const now = new Date();
  const createdTime = new Date(createdInfo);
  const diffInMilliseconds = now - createdTime;

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30); // Approximate

  if (diffInMinutes < 60) {
    return `${diffInMinutes} dakika önce`;
  } else if (diffInHours < 24) {
    return `${diffInHours} saat önce`;
  } else if (diffInDays < 7) {
    return `${diffInDays} gün önce`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} hafta önce`;
  } else {
    return `${diffInMonths} ay önce`;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function parseProductName(productName) {
  return productName.slice(0, productName.indexOf("-"));
}

export default function BillTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState({
    billType: "",
    billerName: "",
    createdInfo: "",
    billNo: "",
    firmApellation: "",
    productName: "",
    price: 0,
    income: 0,
    quota: 0,
  });

  const handleOpen = (index) => {
    setSelectedBill(data[index]);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentData = data.slice(firstIndex, lastIndex);

  const onPageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1280) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" relative flex-row flex-1 gap-8 w-full h-full items-center justify-center">
      <div className="  mb-4">
        {currentData.map((device, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleOpen((currentPage - 1) * itemsPerPage + index);
              }}
              className={`flex ${
                index !== 4 ? "border-b-2" : ""
              }  flex-1 flex-row gap-4 w-full h-28 items-center justify-center cursor-pointer hover:bg-[#DDDDDD]`}
            >
              <div className=" w-20 h-24 space-y-1 flex flex-col items-center justify-center">
                <p className=" text-[#686D76]  text-BASE">
                  {timeAgo(device.createdInfo)}
                </p>
                <p className=" text-[#B4B4B8] text-SM">
                  {formatDate(device.createdInfo)}
                </p>
              </div>
              <div className=" w-1/6 h-22 flex items-center justify-center">
                {device.billType === "Bill" ? (
                  <img className=" w-10 h-10" src={addedBill} />
                ) : device.billType === "FreeUpload" ? (
                  <img className=" w-10 h-10" src={uploadedBill} />
                ) : device.billType === "GetReturn" ? (
                  <img className=" w-10 h-10" src={getreturnBill} />
                ) : (
                  <img className=" w-10 h-10" src={deletedBill} />
                )}
              </div>
              <div className=" w-3/6 h-22 h-24 space-y-0.5 flex flex-col justify-center">
                <div className=" ">
                  <p className=" text-xl font-[600] tracking-wider">
                    {device.billType === "Bill"
                      ? "Fatura Eklendi"
                      : device.billType === "FreeUpload"
                      ? "Bedelsiz Yükleme Yapıldı"
                      : device.billType === "GetReturn"
                      ? "İade Alındı"
                      : "Fatura Silindi"}
                  </p>
                </div>
                <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                  <HiHomeModern className=" w-4 h-4" />
                  <p className=" ">{parseProductName(device.firmApellation)}</p>
                </div>
                <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                  <GrBasket className=" w-4 h-4" />
                  <p className=" ">{parseProductName(device.productName)}</p>
                </div>
                <div className=" text-[#686D76]  flex flex-row gap-2 text-BASE">
                  <RiCupFill className=" w-4 h-4" />
                  <p className=" text-BASE">{device.quota}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedBill.billType === "Bill"
              ? "Yüklenen Fatura Detayları"
              : selectedBill.billType === "FreeUpload"
              ? "Bedelsiz Yükleme Detayları"
              : selectedBill.billType === "GetReturn"
              ? "İade Detayları"
              : "Silinen Fatura Detayları"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`İşlemi Yapan: ${selectedBill.billerName}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`İşlem Tarihi: ${formatDate(selectedBill.createdInfo)}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Fatura No: ${selectedBill.billNo}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Firma: ${selectedBill.firmApellation}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Ürün: ${selectedBill.productName}`}
          </Typography>
          {!(
            selectedBill.billType === "FreeUpload" ||
            selectedBill.billType === "GetReturn"
          ) && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {`Birim Fiyat: ${selectedBill.price.toFixed(2)}`}
            </Typography>
          )}
          {!(
            selectedBill.billType === "FreeUpload" ||
            selectedBill.billType === "GetReturn"
          ) && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {`Gelir: ${selectedBill.income}`}
            </Typography>
          )}
        </Box>
      </Modal>

      <div className="flex flex-column items-center justify-between mt-4">
        <ItemNumber length={data.length} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
