import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
// Icon
import { useGetDeviceQuery } from "../../../store";
import styles from "../../../CustomStyles";
import FormatDateHourString from "../../../Components/Utils/FormatDateHourString";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";

function InfoDevicePage() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const ResultDevice = useGetDeviceQuery({ id, token });
  const Data = ResultDevice?.data?.data?.device || [];
  const [input, setInput] = useState({
    name: "",
    ip: "",
    gsmNo: "",
    serialNo: "",
    imei: "",
    userPassword: "",
    adminPassword: "",
    note: "",
    productInfo: [],
  });

  useEffect(() => {
    if (ResultDevice.status === "fulfilled") setInput({ ...Data });
  }, [ResultDevice]);

  return (
    <>
      {ResultDevice.isLoading ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : ResultDevice.error || ResultDevice?.data?.results === 0 ? (
        <UnfindDataWarn Data={"Cihaz bulunamadı"} />
      ) : (
        <>
          <div className=" flex flex-col gap-4 w-full h-full bg-white shadow-md  rounded-lg p-8  transition-all duration-300">
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 h-fit">
              {input.serialNo && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Firma İsmi</p>
                  <p className={`${styles.inputTag}`}>{input.firm.firmName}</p>
                </div>
              )}{" "}
              {input.serialNo && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Seri No</p>
                  <p className={`${styles.inputTag}`}>{input.serialNo}</p>
                </div>
              )}
              {input.cupNumber && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Göz Sayısı</p>
                  <p className={`${styles.inputTag} h-fit`}>
                    {input.cupNumber}
                  </p>
                </div>
              )}
              {input.cupNumber && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Son Bağlantı</p>
                  <p className={`${styles.inputTag} h-fit`}>
                    {FormatDateHourString(input.lastConnectionDate)}
                  </p>
                </div>
              )}
              {input.note && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Not</p>
                  <p className={`${styles.inputTag} h-fit`}>{input.note}</p>
                </div>
              )}
            </div>
          </div>
          <div className=" flex flex-col gap-2 mb-10">
            <p
              className={`${styles.DesignFieldHeader} border-b-4 w-fit border-main mt-4`}
            >
              Ürünler
            </p>
            <div className="flex max-sm:flex-col  md:grid-cols-2 gap-4 mt-4">
              {input.productInfo?.productInfo?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`grid w-fit px-12 justify-center relative items-center gap-4 bg-white border-4 border-main rounded-md shadow-md shadow-main py-4`}
                  >
                    <div
                      className={`${styles.text} absolute top-0 bg-main w-full text-center p-4 text-white `}
                    >
                      {item.productName}
                    </div>
                    <p className={`${styles.text} mt-14`}>Kota: {item.quota}</p>
                    <p className={`${styles.text}`}> Sayaç: {item.counter}</p>
                    <p className={`${styles.text}`}>
                      Kritik Seviye : {item.quotaWarning}
                    </p>
                    <p className={`${styles.text}`}>
                      Senkronizasyon Seviyesi: {item.syncLevel}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InfoDevicePage;
