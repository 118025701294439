import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Components/Menu/Sidebar";

const SiteLayout = function () {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  return (
    <div>
      {load ? (
        <div className=" flex flex-col gap-4 w-screen h-screen justify-center items-center">
          <div className="cup" style={{ width: "160px", height: "120px" }}>
            <span className="steam"></span>
            <span className="steam"></span>
            <span className="steam"></span>
            <div
              className="cup-handle"
              style={{ width: "60px", height: "50px" }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="mt-12 w-1200">
          <div className="md:ml-40">
            <Sidebar />
            <div className="max-md:ml-10 mr-10 max-md:mb-20 max-md:mt-20">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteLayout;
