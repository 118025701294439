import { BsCalendarWeekFill } from "react-icons/bs";
import CalendarContainer from "./CalendarContainer";
import styles from "../../../CustomStyles";
import { useState } from "react";

function CustomCalendar({ setDate, date }) {
  const [calendar, setCalendar] = useState(false);
  const [input, setInput] = useState("");

  const onChangeDate = (date) => {
    // tarih değerlerini ayrıştırın
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear();

    // "gg.aa.yyyy" formatında bir tarih dizesi oluşturun
    const formattedDate = `${day}.${month}.${year}`;
    setInput(formattedDate);
    setDate(date);
  };

  return (
    <div className="relative flex gap-4 items-center">
      <BsCalendarWeekFill
        onClick={() => {
          setCalendar(!calendar);
        }}
        className={`${styles.buttonIcon} z-0 text-main hover:text-slate-300 cursor-pointer`}
      />
      {calendar && (
        <>
          <div
            onClick={() => {
              setCalendar(!calendar);
            }}
            className="z-30 fixed h-screen w-screen top-0 left-0 bg-transparent"
          ></div>
          <div className="absolute z-40 top-14 -left-5 bg-white border-main border-4 rounded-md p-4 ">
            <CalendarContainer onChange={onChangeDate} value={date} />
          </div>
        </>
      )}
      {input && <p className={`${styles.inputTag} w-fit`}>{input}</p>}
    </div>
  );
}

export default CustomCalendar;
