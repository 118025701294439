import React from "react";
import { MuiTelInput } from "mui-tel-input";

export default function TelInput(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled = false,
  } = props;
  return (
    <MuiTelInput
      disabled={disabled}
      variant="outlined"
      label={label}
      name={name}
      value={value}
      defaultCountry="TR"
      onChange={(value) => {
        const target = { name, value };
        const e = { target: target };
        onChange(e);
      }}
      {...(error && { error: true, helperText: error })}
    />
  );
}
