import PaginationBar from "./PaginationBar";
import SortableTable from "./SortableTable";

export default function ListInfo({
  Config,
  Key,
  SetPaginationNumber,
  PaginationNumber,
  IsSearch,
  FilteredData,
  Data,
}) {
  const KeyFn = (data) => {
    return data[Key];
  };

  return (
    <>
      <div className="flex flex-col items-center z-0 mb-2 mt-8">
        <PaginationBar
          elements={IsSearch ? FilteredData : Data}
          info="Bu bilgilerde bir cihaz bulunamadı."
          paginationNumber={PaginationNumber}
          setPaginationNumber={SetPaginationNumber}
        />
      </div>
      <SortableTable
        data={IsSearch ? FilteredData : Data}
        config={Config}
        keyFn={KeyFn}
        paginationNumber={PaginationNumber}
      />
    </>
  );
}
