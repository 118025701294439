import { Outlet, useLocation } from "react-router-dom";
import styles from "../../CustomStyles";
import { NavLink } from "react-router-dom";

const UpdateDeviceSettingLayout = () => {
  const location = useLocation();
  return (
    <div className="">
      <div className="flex overflow-y-scroll no-scrollbar gap-4">
        <NavLink
          to="GenelAyar"
          state={{
            id: location.state.id,
            serialNo: location.state.serialNo,
            deviceName: location.state.deviceName,
            createdInfo: location.state.createdInfo,
            updatedInfo: location.state.updatedInfo,
          }}
          style={({ isActive }) =>
            isActive ? { backgroundColor: "#176B87", color: "white" } : {}
          }
          className={` flex border-4 border-main text-main px-4 py-2 z-20 rounded-lg transition-all duration-500`}
        >
          <p className={`${styles.DesignFieldHeader}`}>Genel </p>{" "}
          <p className={`${styles.DesignFieldHeader} max-sm:hidden`}>Ayarı</p>
        </NavLink>
        <NavLink
          to="GözAyarı"
          state={{
            id: location.state.id,
            serialNo: location.state.serialNo,
            deviceName: location.state.deviceName,
            createdInfo: location.state.createdInfo,
            updatedInfo: location.state.updatedInfo,
          }}
          style={({ isActive }) =>
            isActive ? { backgroundColor: "#176B87", color: "white" } : {}
          }
          className={`flex border-4 border-main text-main px-4 py-2 z-20 rounded-lg transition-all duration-500`}
        >
          <p className={`${styles.DesignFieldHeader}`}>Göz </p>
          <p className={`${styles.DesignFieldHeader} max-sm:hidden`}>Ayarı</p>
        </NavLink>
        <NavLink
          to="ProfilAyarı"
          state={{
            id: location.state.id,
            serialNo: location.state.serialNo,
            deviceName: location.state.deviceName,
            createdInfo: location.state.createdInfo,
            updatedInfo: location.state.updatedInfo,
          }}
          style={({ isActive }) =>
            isActive ? { backgroundColor: "#176B87", color: "white" } : {}
          }
          className={` flex border-4 border-main text-main px-4 py-2 z-20 rounded-lg transition-all duration-500`}
        >
          <p className={`${styles.DesignFieldHeader}`}>Profil </p>
          <p className={`${styles.DesignFieldHeader} max-sm:hidden`}>Ayarı</p>
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default UpdateDeviceSettingLayout;
