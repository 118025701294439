import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alerts from "../../../Components/Utils/Alert";
import useAuth from "../../../Hooks/useAuth";
import { useDeleteFirmByIDMutation } from "../../../store";
import Loader from "../../../Components/Utils/Loader";
import DeleteForm from "../../../Forms/DeleteForm";

function Delete() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;
  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [input, setInput] = useState("");
  const navigate = useNavigate();

  const [deleteFirm, resultDelete] = useDeleteFirmByIDMutation();

  useEffect(() => {
    setInput({
      id: id,
      token: token,
      password: "",
      passwordConfirm: "",
    });
  }, []);
  const handleDelete = (event) => {
    deleteFirm(input);
  };

  console.log(input);
  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        if (alert === 1) {
          setAlert(0);
          setMessage("");
          navigate("/Anasayfa/Firma");
        }
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    if (resultDelete.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
    }
    if (resultDelete.isSuccess === true) {
      setAlert(1);
      setMessage("İşlem başarı ile gerçekleşti !");
    }
  }, [resultDelete.isSuccess, resultDelete.isError]);

  return (
    <div className=" flex p-6 gap-4 ">
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      {resultDelete.isLoading ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : (
        <DeleteForm
          handleOpenModal={(values) => {
            setInput({ token, id, ...values });
            handleDelete();
          }}
          initialFValues={{
            password: "",
            passwordConfirm: "",
          }}
        />
      )}
    </div>
  );
}

export default Delete;
