import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";

export default function DeviceInfoEditForm({
  setDesignedInput,
  setShowConfirmModal,
  initialFValues,
}) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setShowConfirmModal(true);
      setDesignedInput({
        note: values.note,
      });
    }
  };

  return (
    <form
      className=" grid  gap-4 w-full bg-white p-4 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <div className=" grid md:grid-cols-3 gap-4 w-full ">
        <Controls.Input
          label="Açıklama"
          name="note"
          value={values.note}
          onChange={handleInputChange}
          error={errors.note}
        />
      </div>

      <button
        className={` z-20 max-md:fixed max-md:bottom-8 w-fit
        max-md:left-1/2 max-md:-translate-x-1/2 md:rounded-md rounded-full 
        max-md:border-l-4 max-md:border-b-4 max-md:border-r-4 max-md:border-white ${styles.button}`}
      >
        <p>Kaydet</p>
      </button>
    </form>
  );
}
