import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";

const firmApi = createApi({
  reducerPath: "firm",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      addFirm: builder.mutation({
        query: (input) => {
          return {
            url: `/firms`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Firm", "Sync", "Device"],
      }),
      updateFirmInfo: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/${input._id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Firm"],
      }),
      updateFirmSync: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/updateSync/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      updateFirmProductQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/updateFirmProductQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      updateTakeBackFirmProductQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/takeBackFirmProductQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      updateGiveFirmProductQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/giveFirmProductQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      divideQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/divideQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      getSyncByFirmID: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/sync/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Sync", "Device"],
      }),
      getDeviceByFirmID: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/devices/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Sync", "Device"],
      }),
      deleteFirmByID: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/${input.id}`,
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              password: input.password,
              passwordConfirm: input.passwordConfirm,
            },
          };
        },
        invalidatesTags: ["Firm"],
      }),
      getFirmByID: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getOtherFirms: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/otherFirms/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getFirmProducts: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/getFirmProducts/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getFirmQuotaByID: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/firmQuota/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getBelongUsersByFirmID: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/belongUsers/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getBelowFirmsByID: builder.query({
        query: ({ token, id }) => {
          return {
            url: `/firms/belowFirmsOfMainFirm/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      fetchStorageFirm: builder.query({
        query: (token) => {
          return {
            url: "/firms/getStorageFirms",
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      fetchFirm: builder.query({
        query: (token) => {
          return {
            url: "/firms",
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
    };
  },
});

export const {
  useFetchStorageFirmQuery,
  useAddFirmMutation,
  useFetchFirmQuery,
  useGetFirmByIDQuery,
  useGetBelowFirmsByIDQuery,
  useUpdateFirmInfoMutation,
  useUpdateFirmSyncMutation,
  useUpdateFirmProductQuotaMutation,
  useUpdateTakeBackFirmProductQuotaMutation,
  useUpdateGiveFirmProductQuotaMutation,
  useDeleteFirmByIDMutation,
  useGetBelongUsersByFirmIDQuery,
  useGetDeviceByFirmIDQuery,
  useGetSyncByFirmIDQuery,
  useDivideQuotaMutation,
  useGetFirmQuotaByIDQuery,
  useGetOtherFirmsQuery,
  useGetFirmProductsQuery,
} = firmApi;
export { firmApi };
