import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function DeviceSettingEditForm({
  Settings = {},
  setDesignedInput = () => {},
  setInputFieldName = () => {},
  setShowConfirmModal = () => {},
  initialFValues = {},
}) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setInputFieldName({});
      setShowConfirmModal(true);
      setDesignedInput(values);
    }
  };

  return (
    <div className=" grid gap-4 w-full ">
      {[
        { type: "SelectOptions", wrapperClass: "flex w-full" },
        { type: "Input", wrapperClass: "w-full flex" },
        {
          type: "Checkbox",
          wrapperClass:
            "border-[1px] text-black font-[100] border-black p-2 rounded-md",
        },
        { type: "Number", wrapperClass: "" },
      ].map(({ type, wrapperClass }) => {
        const filteredSettings = Object.keys(Settings).filter(
          (key) => Settings[key].type === type
        );

        return filteredSettings.length > 0 ? (
          <div
            className="grid md:grid-cols-3 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-2 gap-4 w-full"
            key={type}
          >
            {filteredSettings.map((key) => {
              const setting = Settings[key];
              const ControlComponent = Controls[setting.type];
              return (
                <div className={wrapperClass} key={key}>
                  <ControlComponent
                    label={setting.label}
                    name={key}
                    value={values[key]}
                    onChange={handleInputChange}
                    disabled={setting.disabled}
                    // Additional props specific to each control type can be added here
                    {...(setting.type === "Number" && {
                      min: setting.lowerBound,
                      max: setting.upperBound,
                    })}
                    {...(setting.type === "SelectOptions" && {
                      options: setting.options.map((option) => {
                        return { title: option };
                      }),
                    })}
                  />
                </div>
              );
            })}
          </div>
        ) : null;
      })}

      <button
        onClick={handleSubmit}
        className={` z-20 transition-all duration-200 text-main active:scale-90 
        fixed flex flex-col items-center justify-center md:bottom-10 md:right-10 rounded-full
        bottom-8 max-md:px-4 max-md:py-2 max-md:text-white max-md:bg-main max-md:left-1/2 max-md:-translate-x-1/2 max-md:pl-1 max-md:border-white max-md:border-b-4  max-md:border-l-4 max-md:border-r-4 `}
      >
        <FontAwesomeIcon
          className=" max-md:hidden"
          style={{ width: "4rem", height: "4rem" }}
          icon={faCloudArrowUp}
        />
        <p className={`${styles.BigtextTitle} `}>Ayarları Kaydet</p>
      </button>
    </div>
  );
}
