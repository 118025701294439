import { useState, useEffect } from "react";
import { MdAddBusiness } from "react-icons/md";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import useAuth from "../../../Hooks/useAuth";
import { NavLink } from "react-router-dom";
import Alerts from "../../../Components/Utils/Alert";
import styles from "../../../CustomStyles";
import { useNavigate } from "react-router-dom";
import { useAddFirmMutation } from "../../../store";
import Confirm from "../../../Components/Utils/Confirm";
import Loader from "../../../Components/Utils/Loader";
import CreateFirmForm from "../../../Forms/FirmForm/FirmForm";

function CreateFirmPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [inputFieldName, setInputFieldName] = useState({
    firmName: "Firma İsmi",
    mainFirmName: "Ana Firma İsmi",
    email: "Firma Email",
    tel: "Firma Telefon",
  });
  const [infoText, setInfoText] = useState("");
  const [confirm, setConfirm] = useState(false);

  const [add, resultAdd] = useAddFirmMutation();
  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [input, setInput] = useState({
    firmName: "",
    mainFirmName: "",
    isCorporate: false,
    officialID: "",
    taxNumber: "",
    taxOffice: "",
    email: "",
    tel: "",
    note: "",
    address: { text: "", city: "", town: "" },
    token: token,
  });

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        if (alert === 1) {
          setAlert(0);
          setMessage("");
          navigate("/Anasayfa/Firma");
        }
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    setConfirm(false);
    if (resultAdd.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
    }
    if (resultAdd.isSuccess === true) {
      setAlert(1);
      setMessage("İşlem başarı ile gerçekleşti !");
    }
  }, [resultAdd.isSuccess, resultAdd.isError]);

  const handleCloseModel = (bool) => {
    if (bool) {
      add(input);
      setConfirm(true);
      setInfoText("Eklemek istediğinize emin misiniz ?");
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      {/*
        Confirm Modal
      */}
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
    -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      {/*
      Top Absolute Information
      */}
      <div className=" z-40 w-full h-fit flex items-center justify-between border-b-4 border-main">
        <div
          className={`flex px-6 py-3 z-20 transition-all duration-200  
           text-main gap-4`}
        >
          <MdAddBusiness className={`${styles.PageHeaderIcon}`} />
          <p className={`${styles.PageHeader}`}>Firma Ekle</p>
        </div>
        <NavLink
          to="/Anasayfa/Firma"
          className={`flex px-6 py-3 z-20 transition-all duration-200  
             text-main hover:text-red-400 gap-4`}
        >
          <MdOutlineKeyboardBackspace
            className={`${styles.PageHeaderExitIcon}`}
          />
        </NavLink>
      </div>

      {/*
      Device information.
      */}
      {resultAdd.isLoading || confirm ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : (
        <div
          className={`flex w-full 
        mt-[2rem] flex-col justify-between gap-4 
        rounded-xl text-black`}
        >
          <CreateFirmForm
            setDesignedInput={setInput}
            setInputFieldName={setInputFieldName}
            setShowConfirmModal={setShowConfirmModal}
            initialFValues={{
              isCorporate: false,
              firmName: "",
              mainFirmName: "",
              officialNumber: "",
              taxNumber: "",
              taxOffice: "",
              email: "",
              tel: "",
              note: "",
              city: "",
              town: "",
              text: "",
            }}
            token={token}
          />
        </div>
      )}
    </div>
  );
}

export default CreateFirmPage;
