import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { BarChart } from "@mui/x-charts/BarChart";
import styles from "../../CustomStyles";

export function monthName(monthIndex) {
  switch (monthIndex) {
    case 1:
      return "Ocak";
    case 2:
      return "Şubat";
    case 3:
      return "Mart";
    case 4:
      return "Nisan";
    case 5:
      return "Mayıs";
    case 6:
      return "Haziran";
    case 7:
      return "Temmuz";
    case 8:
      return "Ağustos";
    case 9:
      return "Eylül";
    case 10:
      return "Ekim";
    case 11:
      return "Kasım";
    case 12:
      return "Aralık";
    default:
      return "Geçersiz ay numarası";
  }
}
export default function ConsumptionGraph({
  monthlyConsumption = [],
  dashboardType,
  dashboardConsumentIsLoading,
  warn,
  dashboardConsumentIsFetching,
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
  }, [dashboardType]);
  const Data = () => {
    const data = [];

    monthlyConsumption.forEach((log) => {
      let tempLog = {
        month: monthName(log.monthIndex),
        Çay: 0,
        "Türk Kahvesi": 0,
        "Filtre Kahve": 0,
      };

      log.productConsument.forEach((product) => {
        switch (product.productName) {
          case "Çay":
            tempLog["Çay"] = product.consument;
            break;
          case "Türk Kahvesi":
            tempLog["Türk Kahvesi"] = product.consument;
            break;
          case "Filtre Kahve":
            tempLog["Filtre Kahve"] = product.consument;
            break;
          default:
            break;
        }
      });
      data.push(tempLog);
    });
    return data;
  };

  useEffect(() => {
    setData(Data());
  }, [monthlyConsumption]);

  return (
    <>
      {dashboardConsumentIsLoading || dashboardConsumentIsFetching ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="bg-main"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : data.length === 0 ? (
        <div className=" flex w-full h-full row-start-4 col-start-2 items-center  justify-center font-SemiBold tracking-widest">
          <p className={`${styles.Bigtext}  p-4 rounded-md text-black`}>
            {warn}
          </p>
        </div>
      ) : (
        <BarChart
          dataset={data}
          xAxis={[{ scaleType: "band", dataKey: "month" }]}
          series={[
            { dataKey: "Çay", label: "Çay", color: "#5F8D4E" },
            {
              dataKey: "Türk Kahvesi",
              label: "Türk Kahvesi",
              color: "#6d4a3a",
            },
            {
              dataKey: "Filtre Kahve",
              label: "Filtre Kahve",
              color: "#322110",
            },
          ]}
          margin={{ bottom: 100 }}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 0,
            },
          }}
          height={500}
          borderRadius={5}
        />
      )}
    </>
  );
}
