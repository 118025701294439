import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { useFetchUserQuery } from "../../store";
import styles from "../../CustomStyles";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import ListInfo from "../../Components/Table/ListInfo";
import Loader from "../../Components/Utils/Loader";
import TopSearchInfo from "../../Components/SearchBar/TopSearchInfo";

function UsersPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const responseDatas = useFetchUserQuery(token);
  const User = responseDatas.data?.data?.users || [];
  // Exclude current user from DataUser
  const DataUser = User?.filter((user) => user._id !== auth._id);
  const inputFieldName = {
    name: "İsim",
    lastName: "Soyisim",
    email: "Email",
  };
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setIsSearch(false);
  }, []);

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (user) => (
        <div className="flex flex-row justify-center">
          <NavLink
            to={`Düzenle/${user._id}/Bilgi`}
            state={{
              id: user._id,
              name: user.name,
              lastName: user.lastName,
              createdInfo: user.createdInfo,
              updatedInfo: user.updatedInfo,
            }}
          >
            <button className={`${styles.tableButton}`}>
              <BsFillPencilFill className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>
        </div>
      ),
    },
    {
      label: "İsim",
      render: (user) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">İsim:</div>
          {user.name}
        </>
      ),
      sortValue: (user) => user.name,
    },
    {
      label: "Soyisim",
      render: (user) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Soyisim:</div>
          {user.lastName}
        </>
      ),
      sortValue: (user) => user.lastName,
    },
    {
      label: "Rol",
      render: (user) => {
        const rl =
          user.role === "playmaker"
            ? "Plasiyer"
            : user.role === "admin"
            ? "Admin"
            : user.role === "accounting"
            ? "Muhasebe"
            : user.role === "manufacturer"
            ? "Üretim"
            : user.role === "customer"
            ? "Müşteri"
            : user.role === "management"
            ? "Yönetici"
            : "";
        return (
          <>
            <div className=" md:hidden opacity-40 font-Bold">Rol:</div>
            {rl}
          </>
        );
      },
      sortValue: (user) => user.role,
    },
    {
      label: "İletişim",
      render: (user) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">İletişim:</div>
          <div>
            <div>{user.email}</div>
            <div>{user.tel}</div>
          </div>
        </>
      ),
    },
    {
      label: "Firma İsmi",
      render: (user) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Firma İsmi:</div>
          {user.firm?.firmName}
        </>
      ),
      sortValue: (user) => user.firm?.firmName,
    },
    {
      label: "Telefon",
      render: (user) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Telefon:</div>
          {user.tel}
        </>
      ),
    },
    {
      label: "Aktiflik",
      render: (user) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Aktivite:</div>
          {user.isActive ? "Aktif" : "Pasif"}
        </>
      ),
      sortValue: (user) => user.isActive,
    },
  ];

  return (
    <div className="">
      <div className=" w-full inline-block align-middle">
        <TopSearchInfo
          Length={DataUser.length}
          TopInfo={"Kullanıcı Listesi"}
          AddButton={() => {
            navigate("Oluştur");
          }}
          SearchBarData={DataUser}
          HandleSearch={handleSearch}
          SearchInputFieldName={inputFieldName}
        />
        {responseDatas.isFetching ? (
          <div className=" flex w-full h-full justify-center items-center">
            <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
          </div>
        ) : responseDatas.error || DataUser.length === 0 ? (
          <UnfindDataWarn Data={"Kullanıcı bulunamadı"} />
        ) : (
          <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 mb-5 overflow-hidden shadow border ">
            <ListInfo
              Config={config}
              Key={"_id"}
              SetPaginationNumber={setPaginationNumber}
              PaginationNumber={paginationNumber}
              IsSearch={isSearch}
              FilteredData={filteredData}
              Data={DataUser}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersPage;
