import { useState, ChangeEvent, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import styles from "../../../CustomStyles";
import criticQuotaDevice from "../../../Assets/icons/criticquota_device.png";
import unQuotaDevice from "../../../Assets/icons/unquota_device.png";
import unConnectedDevice from "../../../Assets/icons/unconnected_device.png";
import firmDevice from "../../../Assets/icons/firm_device.png";
import storageDevice from "../../../Assets/icons/storage_device.png";
import playmakerDevice from "../../../Assets/icons/playmaker_device.png";
import addedDevice from "../../../Assets/icons/added_device.png";

import { TextField } from "@mui/material";
import DeviceTable from "./DeviceTable";

function isThreeDaysApart(date1, date2) {
  // Tarihleri milisaniyeye çevirin
  const timeDifference = Math.abs(new Date(date1) - new Date(date2));

  // Farkı gün cinsine çevirin
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // 3 güne eşit olup olmadığını kontrol edin
  return daysDifference >= 3;
}

export default function DeviceInfoCard({
  warn,
  devices,
  isLoading,
  isFetching,
}) {
  const [expanded, setExpanded] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filterSerialNo, setFilterSerialNo] = useState("");
  const [filterCount, setFilterCount] = useState({
    playmaker: 0,
    firm: 0,
    storage: 0,
    service: 0,
    unConnected: 0,
    unQuota: 0,
    criticQuota: 0,
  });

  useEffect(() => {
    if (filterSerialNo === "" && devices) {
      setFilteredDevices(devices);
    } else if (devices) {
      setFilteredDevices(
        devices.filter((device) => device.serialNo.includes(filterSerialNo))
      );
    }
  }, [filterSerialNo]);
  useEffect(() => {
    if (devices) {
      const filterCount = {
        playmaker: 0,
        firm: 0,
        storage: 0,
        unConnected: 0,
        unQuota: 0,
        criticQuota: 0,
        service: 0,
        added: 0,
      };
      const now = new Date();
      devices.forEach((device) => {
        switch (device.deviceStatusName) {
          case "Plasiyer":
            filterCount.playmaker += 1;
            break;
          case "Firma":
            filterCount.firm += 1;
            break;
          case "Depo":
            filterCount.storage += 1;
            break;
          case "Servis":
            filterCount.service += 1;
            break;
          case "Üretim":
            filterCount.added += 1;
            break;
        }

        if (isThreeDaysApart(device.lastConnectionDate, now)) {
          filterCount.unConnected += 1;
        }
        device.productInfo?.productInfo?.some((product) => {
          let flag = 0;
          if (product.quota <= 0) {
            filterCount.unQuota += 1;
            flag = 1;
          }
          if (product.quota <= product.quotaWarning) {
            filterCount.criticQuota += 1;
            flag = 1;
          }

          if (flag === 1) return true;
        });
      });

      setFilterCount(filterCount);
    }
  }, [devices]);

  useEffect(() => {
    console.log(expanded);
    if (expanded === "playmaker") {
      setFilteredDevices(
        devices.filter((device) => device.deviceStatusName === "Plasiyer")
      );
    } else if (expanded === "firm") {
      setFilteredDevices(
        devices.filter((device) => device.deviceStatusName === "Firma")
      );
    } else if (expanded === "storage") {
      setFilteredDevices(
        devices.filter((device) => device.deviceStatusName === "Depo")
      );
    } else if (expanded === "service") {
      setFilteredDevices(
        devices.filter((device) => device.deviceStatusName === "Servis")
      );
    } else if (expanded === "added") {
      setFilteredDevices(
        devices.filter((device) => device.deviceStatusName === "Üretim")
      );
    } else if (expanded === "unQuota") {
      const temp = devices.filter((device) => {
        return device.productInfo.productInfo.some((product) => {
          return product.quota <= 0;
        });
      });

      setFilteredDevices(temp);
    } else if (expanded === "criticQuota") {
      const temp = devices.filter((device) => {
        return device.productInfo.productInfo.some((product) => {
          return product.quota <= product.quotaWarning;
        });
      });

      setFilteredDevices(temp);
    } else if (expanded === "unConnected") {
      const now = new Date();

      setFilteredDevices(
        devices.filter((device) => {
          console.log(isThreeDaysApart(device.lastConnectionDate, now));
          return isThreeDaysApart(device.lastConnectionDate, now);
        })
      );
    } else {
      setFilteredDevices(devices);
    }
  }, [expanded]);

  const handleExpand = (type) => {
    setExpanded((prev) => (prev === type ? null : type));
  };

  const handleMouseEnter = (type) => {
    setHovered(type);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  return (
    <div className="flex flex-col p-4 shadow-lg bg-white w-4/6 max-xl:w-full h-full rounded-lg ">
      <div className=" relative flex flex-col xl:h-18 gap-2 mb-4 ml-2">
        <p className="font-SemiBold mt-2 text-2xl tracking-widest text-main z-10">
          Cihaz Bilgileri
        </p>
        <div className="flex flex-wrap mt-2 flex-row gap-4">
          <div
            onClick={() => handleExpand("criticQuota")}
            onMouseEnter={() => handleMouseEnter("criticQuota")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#FFD18E] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "criticQuota" || hovered === "criticQuota"
                ? "w-40"
                : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={criticQuotaDevice}
              alt="Kritik Seviyedeki Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "criticQuota" && hovered !== "criticQuota"
                  ? "hidden"
                  : ""
              }`}
            >
              Kritik Seviye
            </p>
            <p className="mr-3 text-2xl">{filterCount.criticQuota}</p>
          </div>
          <div
            onClick={() => handleExpand("unQuota")}
            onMouseEnter={() => handleMouseEnter("unQuota")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#FCC8D1] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "unQuota" || hovered === "unQuota" ? "w-32" : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={unQuotaDevice}
              alt="Kotası Biten Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "unQuota" && hovered !== "unQuota" ? "hidden" : ""
              }`}
            >
              0 Kota
            </p>
            <p className="mr-3 text-2xl">{filterCount.unQuota}</p>
          </div>
          <div
            onClick={() => handleExpand("unConnected")}
            onMouseEnter={() => handleMouseEnter("unConnected")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#BBE9FF] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "unConnected" || hovered === "unConnected"
                ? "w-44"
                : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={unConnectedDevice}
              alt="Bağlanamayan Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "unConnected" && hovered !== "unConnected"
                  ? "hidden"
                  : ""
              }`}
            >
              Bağlanmayan
            </p>
            <p className="mr-3 text-2xl">{filterCount.unConnected}</p>
          </div>
          <div
            onClick={() => handleExpand("firm")}
            onMouseEnter={() => handleMouseEnter("firm")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#DEF9C4] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "firm" || hovered === "firm" ? "w-32" : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={firmDevice}
              alt="Bağlanamayan Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "firm" && hovered !== "firm" ? "hidden" : ""
              }`}
            >
              Firma
            </p>
            <p className="mr-3 text-2xl">{filterCount.firm}</p>
          </div>
          <div
            onClick={() => handleExpand("storage")}
            onMouseEnter={() => handleMouseEnter("storage")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#BBE9FF] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "storage" || hovered === "storage" ? "w-32" : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={storageDevice}
              alt="Bağlanamayan Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "storage" && hovered !== "storage" ? "hidden" : ""
              }`}
            >
              Depo
            </p>
            <p className="mr-3 text-2xl">{filterCount.storage}</p>
          </div>
          <div
            onClick={() => handleExpand("playmaker")}
            onMouseEnter={() => handleMouseEnter("playmaker")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#BBE9FF] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "playmaker" || hovered === "playmaker"
                ? "w-32"
                : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={playmakerDevice}
              alt="Bağlanamayan Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "playmaker" && hovered !== "playmaker"
                  ? "hidden"
                  : ""
              }`}
            >
              Plasiyer
            </p>
            <p className="mr-3 text-2xl">{filterCount.playmaker}</p>
          </div>
          <div
            onClick={() => handleExpand("service")}
            onMouseEnter={() => handleMouseEnter("service")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#BBE9FF] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "service" || hovered === "service" ? "w-32" : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={playmakerDevice}
              alt="Bağlanamayan Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "service" && hovered !== "service" ? "hidden" : ""
              }`}
            >
              Servis
            </p>
            <p className="mr-3 text-2xl">{filterCount.service}</p>
          </div>
          <div
            onClick={() => handleExpand("added")}
            onMouseEnter={() => handleMouseEnter("added")}
            onMouseLeave={handleMouseLeave}
            className={`flex justify-end items-center relative bg-[#FFACAC] rounded-l-3xl rounded-r-lg h-10 ${
              expanded === "added" || hovered === "added" ? "w-32" : "w-20"
            } transition-all cursor-pointer duration-300`}
          >
            <img
              className="absolute left-0 w-10 h-10"
              src={addedDevice}
              alt="Bağlanamayan Cihazlar"
            />
            <p
              className={`mr-3 ${
                expanded !== "added" && hovered !== "added" ? "hidden" : ""
              }`}
            >
              Üretim
            </p>
            <p className="mr-3 text-2xl">{filterCount.added}</p>
          </div>
          <TextField
            id="outlined-basic"
            label="Seri No"
            variant="outlined"
            size="small"
            color="secondary"
            value={filterSerialNo}
            onChange={(event) => {
              setFilterSerialNo(event.target.value);
            }}
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              width: "10rem",
            }}
            InputProps={{
              style: {
                borderColor: "black", // Çerçeve rengi
              },
            }}
            InputLabelProps={{
              style: {
                color: "black", // Label rengi
              },
            }}
          />
        </div>
      </div>
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="bg-main"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : !filteredDevices || filteredDevices?.length === 0 ? (
        <div className="flex w-full h-full row-start-4 col-start-2 items-center justify-center font-SemiBold tracking-widest">
          <p className={`${styles.Bigtext} p-4 rounded-md text-black`}>
            {warn}
          </p>
        </div>
      ) : (
        <>
          <DeviceTable
            filterSerialNo={filterSerialNo}
            expanded={expanded}
            data={filteredDevices}
          />
        </>
      )}
    </div>
  );
}
