import { useEffect, useState } from "react";
import styles from "../../CustomStyles";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { ThreeDots } from "react-loader-spinner";

const defaultProducts = [
  {
    name: "Çay",
    color: "#5F8D4E",
  },
  {
    name: "Türk Kahvesi",
    color: "#6d4a3a",
  },
  {
    name: "Filtre Kahve",
    color: "#322110",
  },
];

const createPieData = (consumption) => {
  let totalConsumption = 0;

  if (!Array.isArray(consumption)) {
    return [];
  }

  const dataMap = new Map();

  defaultProducts.forEach((product) => {
    dataMap.set(product.name, { ...product, amount: 0 });
  });

  consumption.forEach((dashBoardDevice = {}) => {
    if (dataMap.has(dashBoardDevice.productName)) {
      dataMap.get(dashBoardDevice.productName).amount =
        dashBoardDevice.consument || 0;
      totalConsumption += dashBoardDevice.consument || 0;
    }
  });

  const Data = Array.from(dataMap.values());

  return Data.filter((item) => item.amount > 0).map((item) => ({
    value: item.amount,
    color: item.color,
    label:
      item.name + " %" + Math.round((item.amount / totalConsumption) * 100),
  }));
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

export default function ProductConsumentCard({
  consumption = [],
  warn,
  dashboardType,
  dashboardConsumentIsLoading,
  dashboardConsumentIsFetching,
}) {
  const [pieData, setPieData] = useState([]);
  const { width } = useWindowSize();
  useEffect(() => {
    setPieData([]);
  }, [dashboardType]);

  useEffect(() => {
    setPieData(createPieData(consumption));
  }, [consumption]);

  if (!Array.isArray(consumption)) {
    return (
      <div className="w-full h-full row-start-4 col-start-2 items-center justify-center font-SemiBold tracking-widest">
        Veri yüklenirken bir sorun oluştu!
      </div>
    );
  }

  const calculateRadius = () => {
    if (width < 580) {
      return { cx: "65%", cy: "45%", innerRadius: 50, outerRadius: 150 };
    } else if (width < 1268) {
      return { cx: "55%", cy: "45%", innerRadius: 60, outerRadius: 180 };
    } else {
      return { cx: "60%", cy: "45%", innerRadius: 60, outerRadius: 180 };
    }
  };

  const options = calculateRadius();

  return (
    <>
      {dashboardConsumentIsLoading || dashboardConsumentIsFetching ? (
        <div className="flex-1  flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="bg-main"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : consumption.length === 0 ? (
        <div className=" flex w-full h-full row-start-4 col-start-2 items-center  justify-center font-SemiBold tracking-widest">
          <p className={`${styles.Bigtext}  p-4 rounded-md text-black`}>
            {warn}
          </p>
        </div>
      ) : (
        <PieChart
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 20,
            },
          }}
          height={485}
          margin={{ bottom: 25 }}
          series={[
            {
              arcLabel: (item) => `${item.value}`,
              highlightScope: { faded: "global", highlighted: "item" },
              data: pieData,
              ...options,

              paddingAngle: 4,
              cornerRadius: 10,
              startAngle: 0,
              endAngle: 360,
            },
          ]}
        />
      )}
    </>
  );
}
