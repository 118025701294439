import DashBoardPage from "./Pages/Dashboard/DashBoardPage";

// Device Pages
import DevicePage from "./Pages/Device/DevicePage.js";
import UpdateDeviceInfo from "./Pages/Device/Edit/UpdateDeviceInfo";
import UpdateDeviceSetting from "./Layout/Device/UpdateDeviceSettingLayout";
import DeleteDevice from "./Pages/Device/Edit/DeleteDevice";
import DetailDevice from "./Pages/Device/Info/DetailDevice";
import ReportDevice from "./Pages/Device/Info/ReportDevice";
import InfoDevice from "./Pages/Device/Info/InfoDevice";

//Firma pages
import FirmPage from "./Pages/Firm/FirmPage";
import CreateFirmPage from "./Pages/Firm/Create/CreateFirmPage.js";
import UpdateFirmInfo from "./Pages/Firm/Edit/UpdateInfo.js";
import UpdateFirmBill from "./Pages/Firm/Edit/ControllBill";
import ControlFirm from "./Pages/Firm/Edit/ControlFirms.js";
import ControlDevice from "./Pages/Firm/Edit/ControlDevice.js";
import DeleteFirm from "./Pages/Firm/Edit/DeleteFirm.js";
import InfoFirm from "./Pages/Firm/Info/InfoFirm";
import DetailFirm from "./Pages/Firm/Info/DetailFirm";
import ReportFirm from "./Pages/Firm/Info/ReportFirm";

//Users
import Users from "./Pages/Firm/Edit/ControlUsers.js";
import CreateUserPage from "./Pages/User/CreateUserPage";
import EditUserPage from "./Layout/User/EditUserLayout";
import UpdateUserInfo from "./Pages/User/Edit/UpdateInfo";
import DeleteUser from "./Pages/User/Edit/Delete";

import ProfilePage from "./Pages/ProfilePage";
import StockPage from "./Pages/Stock/StockPage";
import BillPage from "./Pages/BillPage";
import UsersPage from "./Pages/User/UsersPage";

import ProductInfoPage from "./Pages/Parameters/Product/Info/ProductInfoPage";
import TownPage from "./Pages/Parameters/Utils/TownPage";
import CityPage from "./Pages/Parameters/Utils/CityPage";
import ProductTypePage from "./Pages/Parameters/Product/Type/ProductTypePage.js";
import LoginPage from "./Pages/LoginPage/LoginPage.js";
import Page404 from "./Pages/Page404";

import { Routes, Route } from "react-router-dom";
import RequireAuth from "./Components/Utils/RequireAuth";

import Layout from "./Layout/Layout";
import SiteLayout from "./Layout/SiteLayout";

import DeviceLayout from "./Layout/Device/DeviceLayout";
import EditDevice from "./Layout/Device/EditDeviceLayout";
import InfoDevicePage from "./Layout/Device/InfoDeviceLayout";

import FirmLayout from "./Layout/Firm/FirmLayout.js";
import InfoFirmPage from "./Layout/Firm/InfoFirmLayout.js";
import EditFirmPage from "./Layout/Firm/EditFirmLayout";

import UserLayout from "./Layout/UserLayout";
import GeneralSetting from "./Pages/Device/Edit/Setting/GeneralSetting";
import CupSetting from "./Pages/Device/Edit/Setting/CupSetting";
import ProfileSetting from "./Pages/Device/Edit/Setting/ProfileSetting";
import CreateUserLayout from "./Layout/User/CreateUserLayout";
import UploadCommercialVideo from "./Pages/Device/Edit/UploadVideo";
import ReportPage from "./Pages/Reports/reports-page.js";
import ReportGeneralConsumentsPage from "./Pages/Reports/General/report-general-consuments-page.js";
import ReportsLayout from "./Layout/Reports/ReportsLayout.js";
import ReportFirmRemainderPage from "./Pages/Reports/Firm/report-firm-remainder-page.js";
import ReportFirmConsumentMonthlyPage from "./Pages/Reports/Firm/report-firm-consument-monthly-page.js";
import ReportFirmConsumentDailyPage from "./Pages/Reports/Firm/report-firm-consument-daily-page.js";
import ReportFirmConsumentTotalPage from "./Pages/Reports/Firm/report-firm-consument-total-page.js";
import ReportNewFirmsPage from "./Pages/Reports/Firm/report-firm-new-page.js";
import ReportFirmListBasedLocationPage from "./Pages/Reports/Firm/report-firm-list-based-location-page.js";
import ReportFirmUploadedBillPage from "./Pages/Reports/Firm/report-firm-uploaded-bill-page.js";

const ROLES = {
  Management: "management",
  Admin: "admin",
  Accounting: "accounting",
  Manufacture: "manufacturer",
  Playmaker: "playmaker",
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<LoginPage />} />
      </Route>

      <Route
        element={
          <RequireAuth
            allowedRoles={[
              ROLES.Admin,
              ROLES.Management,
              ROLES.Accounting,
              ROLES.Manufacture,
              ROLES.Playmaker,
            ]}
          />
        }
      >
        <Route path="Anasayfa" element={<SiteLayout />}>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.Admin,
                  ROLES.Management,
                  ROLES.Accounting,
                  ROLES.Manufacture,
                  ROLES.Playmaker,
                ]}
              />
            }
          >
            <Route path="Profil" element={<ProfilePage />} />
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.Admin, ROLES.Management]} />
            }
          >
            <Route path="Dashboard" element={<DashBoardPage />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.Admin,
                  ROLES.Management,
                  ROLES.Accounting,
                  ROLES.Manufacture,
                ]}
              />
            }
          >
            <Route path="Cihaz" element={<DeviceLayout />}>
              <Route index={true} element={<DevicePage />} />
              <Route path="Bilgi" element={<InfoDevicePage />}>
                <Route path="Genel" element={<InfoDevice />} />
                <Route path="İstatistik" element={<DetailDevice />} />
                <Route path="Rapor" element={<ReportDevice />} />
              </Route>
              <Route path="Düzenle" element={<EditDevice />}>
                <Route path="Bilgi" element={<UpdateDeviceInfo />} />
                <Route path="Ayar" element={<UpdateDeviceSetting />}>
                  <Route path="GenelAyar" element={<GeneralSetting />} />
                  <Route path="GözAyarı" element={<CupSetting />} />
                  <Route path="ProfilAyarı" element={<ProfileSetting />} />
                </Route>
                <Route path="Reklam" element={<UploadCommercialVideo />} />
                <Route path="Sil" element={<DeleteDevice />} />
              </Route>
            </Route>
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.Admin, ROLES.Management, ROLES.Accounting]}
              />
            }
          >
            <Route path="Firma" element={<FirmLayout />}>
              <Route index={true} element={<FirmPage />} />
              <Route path="Oluştur" element={<CreateFirmPage />} />
              <Route path="Bilgi" element={<InfoFirmPage />}>
                <Route path="Genel" element={<InfoFirm />} />
                <Route path="İstatistik" element={<DetailFirm />} />
                <Route path="Rapor" element={<ReportFirm />} />
              </Route>
              <Route path="Düzenle" element={<EditFirmPage />}>
                <Route path="Bilgi" element={<UpdateFirmInfo />} />
                <Route path="Fatura" element={<UpdateFirmBill />} />
                <Route path="Firmalar" element={<ControlFirm />} />
                <Route path="Cihazlar" element={<ControlDevice />} />
                <Route path="Kullanıcılar" element={<Users />} />
                <Route path="Sil" element={<DeleteFirm />} />
              </Route>
            </Route>
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.Admin,
                  ROLES.Management,
                  ROLES.Manufacture,
                ]}
              />
            }
          >
            <Route path="Stok" element={<StockPage />} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.Admin, ROLES.Management, ROLES.Accounting]}
              />
            }
          >
            <Route path="Fatura" element={<BillPage />} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.Admin, ROLES.Management, ROLES.Accounting]}
              />
            }
          >
            <Route path="Kullanıcı" element={<UserLayout />}>
              <Route index={true} element={<UsersPage />} />
              <Route path="Oluştur" element={<CreateUserLayout />}>
                <Route path="" element={<CreateUserPage />} />
              </Route>
              <Route path="Düzenle/:id" element={<EditUserPage />}>
                <Route path="Bilgi" element={<UpdateUserInfo />} />
                <Route path="Sil" element={<DeleteUser />} />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.Admin,
                  ROLES.Management,
                  ROLES.Accounting,
                  ROLES.Manufacture,
                ]}
              />
            }
          >
            <Route path="Rapor" element={<ReportsLayout />}>
              <Route path="Menu" element={<ReportPage />} />
              <Route
                path="GenelTüketim"
                element={<ReportGeneralConsumentsPage />}
              />
              <Route
                path="FirmaBakiyeSeviye"
                element={<ReportFirmRemainderPage />}
              />
              <Route
                path="FirmaAylıkTüketim"
                element={<ReportFirmConsumentMonthlyPage />}
              />
              <Route
                path="FirmaGünlükTüketim"
                element={<ReportFirmConsumentDailyPage />}
              />
              <Route
                path="FirmaToplamTüketim"
                element={<ReportFirmConsumentTotalPage />}
              />
              <Route
                path="FirmaAramızaKatılan"
                element={<ReportNewFirmsPage />}
              />
              <Route
                path="FirmaLokasyonaGöre"
                element={<ReportFirmListBasedLocationPage />}
              />
              <Route
                path="YüklenenFaturalar"
                element={<ReportFirmUploadedBillPage />}
              />
            </Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="ÜrünBilgisi" element={<ProductInfoPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="ÜrünTipi" element={<ProductTypePage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="İlçe" element={<TownPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="Şehir" element={<CityPage />} />
          </Route>

          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default App;
