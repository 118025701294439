import PropTypes from "prop-types";
import styles from "../../CustomStyles";

function Confirm({
  input = {},
  inputFieldName = {},
  infoText = "",
  handleCloseModel,
  showConfirmModal = false,
}) {
  return (
    <>
      <div
        onClick={() => handleCloseModel(false)}
        className={` ${
          showConfirmModal
            ? "bg-black opacity-80 "
            : " bg-transparent pointer-events-none "
        } fixed z-[60]  inset-0 `}
      ></div>
      <div
        className={` ${
          showConfirmModal ? "" : " opacity-0 pointer-events-none "
        }  z-[70] fixed w-fit left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 border-4 border-main rounded-md `}
      >
        <div className="rounded-md bg-white p-4 flex flex-col gap-5">
          {Object.keys(input).map((key, index) => {
            if (inputFieldName && inputFieldName.hasOwnProperty(key)) {
              return (
                <div className=" flex items-center" key={index}>
                  <p className={`${styles.textTitle} mr-2`}>
                    {inputFieldName[key]}:
                  </p>
                  <p className={`${styles.p}`}>{input[key]}</p>
                </div>
              );
            }
            return null;
          })}
          {infoText && <p className={`${styles.p}`}>{infoText}</p>}
          <div className="flex gap-2 items-center justify-center">
            <button
              onClick={() => handleCloseModel(true)}
              className={`${styles.buttonSearch}`}
            >
              Onay
            </button>
            <button
              onClick={() => handleCloseModel(false)}
              className={`${styles.buttonSearch}`}
            >
              İptal
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

Confirm.propTypes = {
  input: PropTypes.object,
  inputFieldName: PropTypes.object,
  infoText: PropTypes.string,
  handleCloseModel: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.bool,
};

export default Confirm;
