import { ThreeDots } from "react-loader-spinner";
import styles from "../../../CustomStyles";
import { RxBarChart } from "react-icons/rx";
import { useEffect, useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import FilterIncome from "./FilterIncome";

const defaultProducts = [
  {
    name: "Çay",
    color: "#5F8D4E",
  },
  {
    name: "Türk Kahvesi",
    color: "#6d4a3a",
  },
  {
    name: "Filtre Kahve",
    color: "#322110",
  },
];

const createPieData = (consumption) => {
  let totalConsumption = 0;

  if (!Array.isArray(consumption)) {
    return [];
  }

  const dataMap = new Map();

  defaultProducts.forEach((product) => {
    dataMap.set(product.name, { ...product, amount: 0 });
  });

  consumption.forEach((dashBoardDevice = {}) => {
    if (dataMap.has(dashBoardDevice.productName)) {
      dataMap.get(dashBoardDevice.productName).amount =
        dashBoardDevice.consument || 0;
      totalConsumption += dashBoardDevice.consument || 0;
    }
  });

  const Data = Array.from(dataMap.values());

  return Data.filter((item) => item.amount > 0).map((item) => ({
    value: item.amount,
    color: item.color,
    label: item.name,
  }));
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

export default function IncomeInfoCard({
  warn,
  incomes,
  isLoading,
  isFetching,
}) {
  const [pieData, setPieData] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [dashboardType, setDashboardType] = useState("thisyear");

  const { width } = useWindowSize();
  useEffect(() => {
    setPieData([]);
  }, [dashboardType]);

  useEffect(() => {
    if (incomes && dashboardType === "today") {
      const object = Object.keys(incomes.dailyRevenue).map((key) => ({
        productName: key,
        consument: incomes.dailyRevenue[key],
      }));
      setConsumption(object);
    } else if (incomes && dashboardType === "thisweek") {
      const object = Object.keys(incomes.weeklyRevenue).map((key) => ({
        productName: key,
        consument: incomes.weeklyRevenue[key],
      }));
      setConsumption(object);
    } else if (incomes && dashboardType === "thismonth") {
      const object = Object.keys(incomes.monthlyRevenue).map((key) => ({
        productName: key,
        consument: incomes.monthlyRevenue[key],
      }));
      setConsumption(object);
    } else if (incomes && dashboardType === "thisyear") {
      const object = Object.keys(incomes.yearlyRevenue).map((key) => ({
        productName: key,
        consument: incomes.yearlyRevenue[key],
      }));
      setConsumption(object);
    }
  }, [incomes, dashboardType]);

  useEffect(() => {
    setPieData(createPieData(consumption));
  }, [consumption]);

  if (!Array.isArray(consumption)) {
    return (
      <div className="w-full h-full row-start-4 col-start-2 items-center justify-center font-SemiBold tracking-widest">
        Veri yüklenirken bir sorun oluştu!
      </div>
    );
  }

  const calculateRadius = () => {
    if (width < 580) {
      return { cx: "75%", cy: "40%", innerRadius: 50, outerRadius: 80 };
    } else if (width < 1268) {
      return { cx: "85%", innerRadius: 50, outerRadius: 90 };
    } else {
      return { cx: "85%", innerRadius: 40, outerRadius: 100 };
    }
  };

  const options = calculateRadius();

  return (
    <div className=" relative flex flex-col p-4 shadow-lg bg-white w-2/6 max-xl:w-full h-80 rounded-lg ">
      <div className=" relative flex md:flex-row flex-col items-center  justify-between gap-2 mb-4 ml-2">
        <p className="font-SemiBold mt-2 text-2xl tracking-widest text-black z-10">
          Mali Tablo
        </p>
        <FilterIncome
          button={
            "z-10 p-2 border-2 border-main hover:bg-white hover:text-main rounded-md transition-all duration-300 shadow-xl hover:scale-110"
          }
          handleClick={setDashboardType}
          active={dashboardType}
        />
      </div>
      <div className=" absolute  left-24 -bottom-20">
        <RxBarChart className=" w-64 h-64 text-white opacity-30" />
      </div>
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="bg-main"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : pieData.length === 0 ? (
        <div className="flex w-full h-full row-start-4 col-start-2 items-center justify-center font-SemiBold tracking-widest">
          <p className={`${styles.Bigtext} p-4 rounded-md text-black`}>
            {warn}
          </p>
        </div>
      ) : (
        <>
          <div className=" flex flex-row justify-between">
            <div className=" max-sm:hidden flex flex-col ml-2 sm:w-4/6 mt-4 gap-4">
              {pieData.map((item, index) => {
                return (
                  <div key={index} className="flex items-center gap-1">
                    <p className="text-[#686D76]  text-center font-SemiBold text-2xl">
                      {item.label}
                    </p>
                    <p className="text-[#B4B4B8] text-center font-SemiBold text-2xl">
                      {item.value} ₺
                    </p>
                  </div>
                );
              })}
            </div>
            <div className=" flex w-full sm:w-4/6  h-56 ">
              <PieChart
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "black",
                    fontWeight: "bold",
                    fontSize: 0,
                  },
                }}
                slotProps={{
                  legend: {
                    direction: "column",
                    position: { horizontal: "right" },
                    padding: 0,
                    hidden: true,
                  },
                }}
                series={[
                  {
                    arcLabel: (item) => `${item.value}`,
                    highlightScope: { faded: "global", highlighted: "item" },
                    data: pieData,
                    ...options,

                    paddingAngle: 4,
                    cornerRadius: 10,
                    startAngle: 0,
                    endAngle: 360,
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
