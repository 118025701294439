import React, { useEffect, useState } from "react";
import Controls from "../Components/Form/Controls";
import { useForm } from "../Components/Form/useForm";
import styles from "../CustomStyles";
import { IoMdAddCircle } from "react-icons/io";
import { useFetchFirmQuery } from "../store";

const isValidEmail = (email) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailPattern.test(email);
};

export default function UserForm({ handleOpenModal, token, initialFValues }) {
  const responseFirms = useFetchFirmQuery(token);
  const Firms = responseFirms?.data?.data?.firms || [];

  const firmOptions = [
    ...Firms?.map((option) => {
      return { id: option._id, title: option.firmName };
    }),
  ];
  const roleOptions = [
    { id: "1", title: "Yönetici" },
    { id: "2", title: "Muhasebe" },
    { id: "3", title: "Üretim" },
    { id: "4", title: "Plasiyer" },
    { id: "5", title: "Müşteri" },
  ];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "Kullanıcı rolü seçilmedi.";
    if ("firmName" in fieldValues)
      temp.firmName = fieldValues.firmName ? "" : "Firma seçilmedi.";
    if ("email" in fieldValues)
      temp.email = isValidEmail(fieldValues.email)
        ? ""
        : "Doğru bir email adresi girin.";
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "İsim girin.";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "Soyisim girin.";
    if ("tel" in fieldValues)
      temp.tel = fieldValues.tel ? "" : "Telefon girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (values.firmName === "Bayiner") {
      if (values.role === "Müşteri") {
        setValues({ ...values, role: "" });
      }
    }
  }, [values.role, values.firmName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleOpenModal(values);
    }
  };

  return (
    <form
      className=" grid  gap-4 w-full bg-white p-4 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <div className=" grid md:grid-cols-3 gap-4 w-full ">
        <Controls.SelectOptions
          label="Rol"
          name="role"
          value={values.role}
          onChange={handleInputChange}
          options={roleOptions}
          error={errors.role}
        />
        <Controls.SelectOptions
          label="Firma İsmi"
          name="firmName"
          value={values.firmName}
          onChange={handleInputChange}
          options={firmOptions}
          error={errors.firmName}
        />
        <Controls.Input
          label="İsim"
          name="name"
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <Controls.Input
          label="Soyisim"
          name="lastName"
          value={values.lastName}
          onChange={handleInputChange}
          error={errors.lastName}
        />
        <Controls.Input
          label="Email"
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors.email}
        />
        <Controls.TelInput
          label="Telefon"
          name="tel"
          value={values.tel}
          onChange={handleInputChange}
          error={errors.tel}
        />
      </div>
      <button className={` w-fit self-end ${styles.button}`}>
        <IoMdAddCircle className={`text-center ${styles.buttonIcon}`} />
        <p>Kaydet</p>
      </button>
    </form>
  );
}
