import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
// Icon
import { useLazyGetFirmLogReportQuery } from "../../../store";
import styles from "../../../CustomStyles";

import { BsFileEarmarkBarGraph } from "react-icons/bs";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";

import { HiOutlineDocumentReport } from "react-icons/hi";
import FormatHourString from "../../../Components/Utils/Date/FormatHourString";
import FormatDateString from "../../../Components/Utils/Date/FormatDateString";
import CustomCalendar from "../../../Components/Utils/Date/CustomCalendar";
import ListInfo from "../../../Components/Table/ListInfo";
import { GenerateFormattedDate } from "../../../Components/Utils/Date/GenerateFormattedDate";
import SelectType from "../../../Components/Utils/SelectType";
import {
  formatToTurkeyDate,
  formatToTurkeyTime,
} from "../../../Components/Utils/FormatDateHourString";
import SidebarList from "../../../Components/Utils/SidebarList";
import ExportExcel from "../../../Components/Utils/ExportExcel";

function groupAndSumConsumptions(logs, filterType) {
  const grouped = {};

  logs.forEach((log) => {
    const date = new Date(log.createdInfo);
    let key;

    switch (filterType) {
      case "Day":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")} ${String(
          date.getHours()
        ).padStart(2, "0")}:00`;
        break;
      case "Month":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}`;
        break;
      case "Year":
        key = `${date.getFullYear()}`;
        break;
      default:
        key = "default";
        break;
    }

    if (!grouped[key]) grouped[key] = {};
    if (!grouped[key][log.productName]) {
      grouped[key][log.productName] = {
        consument: 0,
      };
    }
    grouped[key][log.productName].consument += log.consument;
  });

  return grouped;
}

function renderOutput(filteredItems, filterType) {
  const groupedData = groupAndSumConsumptions(filteredItems.logs, filterType);

  console.log(filteredItems.logs);
  return (
    <table>
      <thead>
        <tr>
          <th className="px-4 py-2">Tarih</th>
          {filterType === "Day" && <th className="px-4 py-2">Saat</th>}{" "}
          <th className="px-4 py-2">Ürün İsmi</th>
          <th className="px-4 py-2">Toplam Tüketim</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(groupedData).map(([dateTime, products]) =>
          Object.entries(products).map(
            ([productName, { consument }], index) => (
              <tr key={index}>
                <td className="p-2 text-center">{dateTime.split(" ")[0]}</td>
                {filterType === "Day" && (
                  <td className="p-2 text-center">
                    {filterType === "Day" ? dateTime.split(" ")[1] : "—"}
                  </td>
                )}
                <td className="p-2 text-center">{productName}</td>
                <td className="p-2 text-center">{consument}</td>
              </tr>
            )
          )
        )}
      </tbody>
    </table>
  );
}
function renderExcelData(filteredItems, filterType) {
  const groupedData = groupAndSumConsumptions(filteredItems.logs, filterType);

  // Yeni veri yapısı oluşturmak için `map` fonksiyonları ve içeride bir ternary operatör kullanılacak
  const excelData = Object.entries(groupedData).flatMap(
    ([dateTime, products]) =>
      Object.entries(products).map(([productName, { consument }]) =>
        filterType === "Day"
          ? {
              Ürün_İsmi: productName,
              Toplam_Tüketim: consument,
              Saat: dateTime.split(" ")[1],
              Tarih: dateTime.split(" ")[0],
            }
          : {
              Ürün_İsmi: productName,
              Toplam_Tüketim: consument,
              Tarih: dateTime,
            }
      )
  );

  return excelData;
}

function groupAndMergeLogs(data, filterType) {
  const grouped = {};

  data.forEach((item) => {
    if (!item.logs || item.logs.length === 0) return;

    const date = new Date(item.date);
    let key;

    switch (filterType) {
      case "Day":
        key = date.toISOString().split("T")[0]; // YYYY-MM-DD formatında bir anahtar oluşturur
        break;
      case "Month":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}`; // YYYY-MM formatında bir anahtar oluşturur
        break;
      case "Year":
        key = `${date.getFullYear()}`; // YYYY formatında bir anahtar oluşturur
        break;
      default:
        // Eğer tanımlı bir filterType yoksa, işlem yapma
        return;
    }

    // Eğer bu anahtara sahip bir grup zaten varsa, logs'ları birleştir
    if (!grouped[key]) {
      grouped[key] = [...item.logs];
    } else {
      grouped[key] = [...grouped[key], ...item.logs];
    }
  });

  // Sonuç olarak oluşturulan gruplanmış ve birleştirilmiş logs'ları bir diziye çevir
  return Object.keys(grouped).map((key) => ({
    date: key,
    logs: grouped[key],
  }));
}

function ReportFirm() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [choiceDate, setChoiceDate] = useState("");
  const [info, setInfo] = useState(false);
  const [input, setInput] = useState({
    id: id,
    token: token,
    type: "",
    createdInfo: "",
    createdInfoSecond: "",
  });
  const [filterType, setFilterType] = useState("Day");
  const [date, setDate] = useState(new Date());
  const [dateSecond, setDateSecond] = useState(new Date());
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [getReport, { data, isFetching, isLoading, isError }] =
    useLazyGetFirmLogReportQuery();
  const [Datas, setDatas] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const config = [
    {
      label: "İncele",
      render: (data) => (
        <div className="flex items-center justify-center">
          <button
            onClick={() => {
              console.log(data.date);
              setChoiceDate(data.date);
              setSelectedDate(data.date);
              setInfo(true);
            }}
            className={`${styles.button} w-fit`}
          >
            <BsFileEarmarkBarGraph className={`${styles.buttonIcon}`} />
          </button>
        </div>
      ),
    },
    {
      label: "Rapor Tarihi",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Rapor Tarihi:</div>
          {data.date}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (input.type !== prevInputType) {
      setInfo(false);
      setDatas([]); // Datas'ı boş bir dizi yap
    } else {
      setDatas(
        data?.data?.filter((item) => item.logs && item.logs.length > 0) || []
      );
    }

    setPrevInputType(input.type); // Mevcut input.type değerini önceki değer olarak kaydedin
  }, [data, input.type]);

  const [prevInputType, setPrevInputType] = useState(input.type);

  if (Datas.length !== 0 && input.type === "Consument") {
    const combinedProductTotalsObj = {
      label: "Tüketim Toplamları",
      render: (data) => {
        const productTotals = {};

        data?.logs?.forEach((log) => {
          if (log) {
            if (log && log.productName && typeof log.consument === "number") {
              if (productTotals[log.productName]) {
                productTotals[log.productName] += log.consument;
              } else {
                productTotals[log.productName] = log.consument;
              }
            }
          }
        });

        return (
          <div>
            {Object.keys(productTotals).map((productName) => (
              <div key={productName}>
                <strong>{productName}:</strong> {productTotals[productName]}
              </div>
            ))}
          </div>
        );
      },
    };

    config.push(combinedProductTotalsObj);
  }

  const ReportReturn = (item, date) => {
    let output = null;
    console.log(date);
    const fileName = {
      Consument: "Tüketim",
      UpdateInfo: "Bilgi Değişikliği",
      UpdateBill: "Fatura Hareketi",
    };
    if (date === "" || item.length === 0) {
      return null;
    }

    const filteredItems = item.find((entry) => entry.date === date);

    if (!filteredItems) {
      return null;
    }

    let excelData = [];
    if (input.type === "Consument") {
      let productTotals = {};

      filteredItems?.logs.forEach((log) => {
        if (productTotals[log.productName]) {
          productTotals[log.productName] += log.consument;
        } else {
          productTotals[log.productName] = log.consument;
        }
      });

      console.log(filteredItems);
      output = renderOutput(filteredItems, filterType);
      excelData = renderExcelData(filteredItems, filterType);
    }
    if (input.type === "UpdateInfo") {
      output = (
        <table className=" ">
          <thead>
            <tr>
              <th className=" px-4 py-2">İsim</th>
              <th className=" px-4 py-2">Soyisim</th>
              <th className=" px-4 py-2">Bilgi</th>
              <th className=" px-4 py-2">İlk Değer</th>
              <th className=" px-4 py-2">Son Değer</th>
              <th className=" px-4 py-2">Saat</th>
              <th className=" px-4 py-2">Tarih</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems?.logs.map((log, logIndex) =>
              log.info.map((info, infoIndex) => (
                <tr className=" odd:bg-input" key={`${logIndex}-${infoIndex}`}>
                  <td className=" p-2 text-center">{log.name}</td>
                  <td className=" p-2 text-center">{log.lastName}</td>
                  <td className=" p-2 text-center">{info.infoName}</td>
                  <td className=" p-2 text-center">{info.valueFrom}</td>
                  <td className=" p-2 text-center">{info.valueTo}</td>
                  <td className=" p-2 text-center">
                    {FormatHourString(log.createdInfo)}
                  </td>
                  <td className=" p-2 text-center">
                    {FormatDateString(log.createdInfo)}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      );
      excelData = filteredItems.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        Bilgi: log.info.infoName,
        İlk_Değer: log.info.valueFrom,
        Son_Değer: log.info.valueTo,
        Saat: FormatHourString(log.createdInfo),
        Tarih: FormatDateString(log.createdInfo),
      }));
    } else if (input.type === "UpdateBill") {
      output = (
        <table className=" ">
          <thead>
            <tr>
              <th className=" px-4 py-2">İsim</th>
              <th className=" px-4 py-2">Soyisim</th>
              <th className=" px-4 py-2">İşlem</th>
              <th className=" px-4 py-2">Fatura Numarası</th>
              <th className=" px-4 py-2">Ürün Kodu</th>
              <th className=" px-4 py-2">Ürün İsmi</th>
              <th className=" px-4 py-2">Miktar</th>
              <th className=" px-4 py-2">Gelir</th>
              <th className=" px-4 py-2">Saat</th>
              <th className=" px-4 py-2">Tarih</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems?.logs.map((log, logIndex) => (
              <tr className=" odd:bg-input" key={`${logIndex}`}>
                <td className=" p-2 text-center">{log.name}</td>
                <td className=" p-2 text-center">{log.lastName}</td>
                <td className={` p-2 text-center `}>
                  <p
                    style={{
                      backgroundColor: `${
                        log.operation === "Added"
                          ? "#A6FF96"
                          : log.operation === "FreeUpload"
                          ? "#EBEF95"
                          : log.operation === "GetReturn"
                          ? "#FFC8C8"
                          : "#FF6969"
                      }`,
                    }}
                    className=" rounded-md shadow-md p-2"
                  >
                    {log.operation === "Added"
                      ? "Ekleme"
                      : log.operation === "FreeUpload"
                      ? "Bedelsiz Yükleme"
                      : log.operation === "GetReturn"
                      ? "İade"
                      : "Silme"}
                  </p>
                </td>
                <td className=" p-2 text-center">{log.info.billNo}</td>
                <td className=" p-2 text-center">{log.info.productCode}</td>
                <td className=" p-2 text-center">{log.info.productName}</td>
                <td className=" p-2 text-center">{log.info.quota}</td>
                <td className=" p-2 text-center">{log.info.income}</td>
                <td className=" p-2 text-center">
                  {formatToTurkeyTime(log.createdInfo)}
                </td>
                <td className=" p-2 text-center">
                  {formatToTurkeyDate(log.createdInfo)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
      excelData = filteredItems.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        İşlem:
          log.operation === "Added"
            ? "Ekleme"
            : log.operation === "FreeUpload"
            ? "Bedelsiz Yükleme"
            : log.operation === "GetReturn"
            ? "İade"
            : "Tanımsız",
        Fatura_Numarası: log.info.billNo,
        Ürün_Kodu: log.info.productCode,
        Ürün_İsmi: log.info.productName,
        Miktar: log.info.quota,
        Gelir: log.info.income,
        Saat: formatToTurkeyTime(log.createdInfo),
        Tarih: formatToTurkeyDate(log.createdInfo),
      }));
    }

    return (
      <div className="space-y-4">
        <h2 className="text-xl font-bold text-center">{date} Tarihli Rapor</h2>
        <div className="w-full flex gap-6 items-center justify-center">
          <ExportExcel excelData={excelData} fileName={fileName[input.type]} />
        </div>
        {output}
      </div>
    );
  };

  const handleGetLogData = () => {
    if (input.createdInfo && input.type) {
      getReport(input);
    }
  };
  const handleFilterType = (type) => {
    setFilterType(type);
  };
  useEffect(() => {
    setInput({ ...input, createdInfo: GenerateFormattedDate(date) });
  }, [date]);

  useEffect(() => {
    setInput({
      ...input,
      createdInfoSecond: GenerateFormattedDate(dateSecond),
    });
  }, [dateSecond]);

  return (
    <>
      <SidebarList
        info={info}
        onClick={() => {
          setInfo(false);
          setChoiceDate("");
          setSelectedDate(null);
        }}
      >
        {ReportReturn(groupAndMergeLogs(Datas, filterType), selectedDate)}
      </SidebarList>

      <div className="flex max-md:flex-col md:items-center gap-4 w-fit ">
        <button
          onClick={() => handleFilterType("Day")}
          className={`${
            filterType !== "Day" ? "bg-white" : "bg-red-500"
          }  border-red-500 border-4 p-2 rounded-md w-fit`}
        >
          <p
            className={`${
              filterType === "Day" ? "text-white" : "text-red-500"
            } text-xl `}
          >
            Günlük
          </p>
        </button>
        <button
          onClick={() => handleFilterType("Month")}
          className={`${
            filterType !== "Month" ? "bg-white" : "bg-red-500"
          }  border-red-500 border-4 p-2 rounded-md w-fit`}
        >
          <p
            className={`${
              filterType === "Month" ? "text-white" : "text-red-500"
            } text-xl `}
          >
            Aylık
          </p>
        </button>
        <button
          onClick={() => handleFilterType("Year")}
          className={`${
            filterType !== "Year" ? "bg-white" : "bg-red-500"
          }  border-red-500 border-4 p-2 rounded-md w-fit`}
        >
          <p
            className={`${
              filterType === "Year" ? "text-white" : "text-red-500"
            } text-xl `}
          >
            Yıllık
          </p>
        </button>
        <SelectType
          label="Rapor Tipi"
          options={[
            { label: "Tüketim", value: "Consument" },
            { label: "Fatura Hareketi", value: "UpdateBill" },
            { label: "Bilgi Değişikliği", value: "UpdateInfo" },
          ]}
          selectedValue={input.type}
          onChange={(value) => {
            setInput({ ...input, type: value });
          }}
        />
        <CustomCalendar setDate={setDate} date={date} />
        <CustomCalendar setDate={setDateSecond} date={dateSecond} />

        <button onClick={handleGetLogData} className={`${styles.button} w-fit`}>
          <p className="">Raporu Getir</p>
          <HiOutlineDocumentReport className={`${styles.buttonIcon}`} />
        </button>
      </div>
      <div className="flex flex-col items-center mb-10 gap-6 bg-white rounded-xl overflow-hidden shadow-md ">
        {isFetching || isLoading ? (
          <div className=" flex w-full h-full justify-center items-center">
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#c0efff"
              color="#004080"
            />
          </div>
        ) : isError || Datas?.length === 0 ? (
          <UnfindDataWarn Data={"Rapor bulunamadı"} />
        ) : (
          <ListInfo
            Config={config}
            Key={"date"}
            SetPaginationNumber={setPaginationNumber}
            PaginationNumber={paginationNumber}
            IsSearch={false}
            FilteredData={groupAndMergeLogs(Datas, filterType)}
            Data={groupAndMergeLogs(Datas, filterType)}
          />
        )}{" "}
      </div>
    </>
  );
}

export default ReportFirm;
