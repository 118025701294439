import { createTheme } from "@mui/material/styles";

const CustomStyles = {
  cay: "#5F8D4E",
  filtreKahve: "#322110",
  türkKahvesi: "#6d4a3a",
  //Dashboard
  Dashboard_InfoCard_Icon_Size:
    "xl:w-7 xl:h-7 lg:w-6 lg:h-6 md:w-5 md:h-5 w-5 h-5",

  //LOGIN
  LoginInputField:
    "flex flex-col max-md:w-full justify-center gap-[6rem] px-[4rem] py-[6rem] transition-all duration-500 rounded-xl bg-white sm:shadow-2xl ",
  LoginLogo: "w-[20rem] h-[6rem] self-center ",
  LoginTagIcon: "xl:w-9 xl:h-9 w-8 h-8 text-main",
  LogintagText: "xl:text-2xl font-SemiBold ml-2 text-main ",
  LogininputTag:
    "w-full xl:text-xl text-BASE h-[2.5rem] rounded-md shadow-lg  p-3 ",
  LoginButton:
    "xl:text-xl text-BASE tracking-widest items-center gap-4 text-white flex bg-main rounded-md px-4 py-2 transition-all duration-300 active:scale-90",
  LoginChangeText: " xl:text-BASE text-SM font-Medium text-main",

  //HEADER
  PageHeaderIcon: "mr-4 w-[2.5rem] h-[2.5rem]",
  PageHeaderExitIcon: "w-[2.5rem] h-[4rem]",
  PageHeader: " font-SemiBold tracking-[0.15rem] text-[1rem]",
  // INPUT FİELD
  DesignFieldHeader:
    "font-SemiBold tracking-[0.15rem] xl:text-2xl lg:text-xl md:text-BASE text-SM",

  // INPUT
  tagText: "font-SemiBold text-[0.90rem] ",
  InputCheckBox: "w-6 h-6 ml-3 ",
  inputTag:
    "w-full h-11 input rounded-md shadow border-1 p-3 bg-input text-[0.85rem] ",
  tagIcon: "xl:w-9 xl:h-9 w-8 h-8 text-main",

  // DropDown
  DropDownPanel: "rounded-md transition-all duration-100 h-11",
  DropDownText: "xl:text-xl md:text-BASE text-SM",

  //Buttons
  button:
    "text-[1rem] cursor-pointer  items-center gap-4 text-white flex bg-main rounded-md px-4 py-2 transition-all duration-300 active:scale-90",
  buttonIcon: "xl:w-8 xl:h-8 md:w-7 md:h-7 w-6 h-6",
  tinyButtonIcon: "xl:w-6 xl:h-6 md:w-5 md:h-5 w-4 h-4",
  tableButton:
    "flex items-center justify-center py-2 pl-4 pr-3 rounded-full transition duration-500 text-main hover:text-gray-400",

  //Dashboard
  button2:
    "  bg-white text-main border-2 border-white hover:bg-main hover:text-white rounded-md transition-all duration-300 shadow-xl",

  //Search Bar
  inputSearch:
    "w-full h-11 input rounded-md shadow border-1 p-3 xl:text-xl md:text-BASE text-SM",
  buttonSearch:
    "xl:text-xl text-BASE items-center gap-2 text-white active:text-white hover:text-main flex bg-main rounded-md px-4 py-2 active:bg-main hover:bg-white border-2 border-main",

  //Cart Title
  Bigtext: "font-SemiBold text-3xl",
  BigtextTitle: " font-Bold text-3xl tracking-widest  ",

  //Card
  textTitle: "font-SemiBold text-sm ",
  text: "text-xl md:text-BASE text-SM ",

  cardTitle: "font-SemiBold tracking-wider xl:text-2xl md:text-xl text-BASE",
};

export default CustomStyles;
