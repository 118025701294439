import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
// Icon
import { useLazyGetDeviceLogReportQuery } from "../../../store";
import styles from "../../../CustomStyles";

import { BsFileEarmarkBarGraph } from "react-icons/bs";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import FormatDateString from "../../../Components/Utils/Date/FormatDateString";
import CustomCalendar from "../../../Components/Utils/Date/CustomCalendar";
import ListInfo from "../../../Components/Table/ListInfo";
import SelectType from "../../../Components/Utils/SelectType";
import SidebarList from "../../../Components/Utils/SidebarList";

function getKeyByFilterType(date, filterType) {
  switch (filterType) {
    case "Day":
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:00`;
    case "Month":
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}`;
    case "Year":
      return `${date.getFullYear()}`;
    default:
      return "default";
  }
}

function groupAndSumConsumptions(logs, filterType) {
  const grouped = {};

  logs.forEach((log) => {
    const date = new Date(log.createdInfo);
    const key = getKeyByFilterType(date, filterType); // Modüler hale getirildi

    if (!grouped[key]) grouped[key] = {};
    if (!grouped[key][log.productName]) {
      grouped[key][log.productName] = {
        consument: 0,
        firmNames: new Set(),
      };
    }

    grouped[key][log.productName].consument += log.consument;
    if (filterType === "Day") {
      grouped[key][log.productName].firm = log.firm;
      grouped[key][log.productName].deviceStatusName = log.deviceStatusName;
      grouped[key][log.productName].playMaker = log.playMaker;
      grouped[key][log.productName].activity = log.activity;
    }
  });

  return grouped;
}

function renderTableHeader(filterType) {
  return (
    <tr>
      <th className="px-4 py-2">Tarih</th>
      <th className="px-4 py-2">Saat</th>
      <th className="px-4 py-2">Ürün İsmi</th>
      <th className="px-4 py-2">Toplam Tüketim</th>
      {filterType === "Day" && <th className="px-4 py-2">Firma</th>}
      {filterType === "Day" && <th className="px-4 py-2">Cihaz Durumu</th>}
      {filterType === "Day" && <th className="px-4 py-2">Plasiyer</th>}
      {filterType === "Day" && <th className="px-4 py-2">Aktiflik</th>}
    </tr>
  );
}

function renderTableBody(groupedData, filterType) {
  return (
    <>
      {Object.entries(groupedData).map(([dateTime, products]) =>
        Object.entries(products).map(
          (
            [
              productName,
              { consument, firm, deviceStatusName, playMaker, activity },
            ],
            index
          ) => (
            <tr key={index}>
              <td className="p-2 text-center">{dateTime.split(" ")[0]}</td>
              <td className="p-2 text-center">
                {filterType === "Day" ? dateTime.split(" ")[1] : "—"}
              </td>
              <td className="p-2 text-center">{productName}</td>
              <td className="p-2 text-center">{consument}</td>
              {filterType === "Day" && (
                <td className="p-2 text-center">{firm}</td>
              )}
              {filterType === "Day" && (
                <td className="p-2 text-center">{deviceStatusName}</td>
              )}
              {filterType === "Day" && (
                <td className="p-2 text-center">
                  {playMaker ? playMaker : "Boş"}
                </td>
              )}
              {filterType === "Day" && (
                <td className="p-2 text-center">
                  {activity ? "Aktif" : "Pasif"}
                </td>
              )}
            </tr>
          )
        )
      )}
    </>
  );
}

function renderOutput(filteredItems, filterType) {
  const groupedData = groupAndSumConsumptions(filteredItems.logs, filterType);

  return (
    <>
      <thead>
        {renderTableHeader(filterType)} {/* Modüler hale getirildi */}
      </thead>
      <tbody>
        {renderTableBody(groupedData, filterType)}{" "}
        {/* Modüler hale getirildi */}
      </tbody>
    </>
  );
}

// ReportReturn fonksiyonunu dışarı alındı
function ReportReturn({ item, date, input, filterType }) {
  let output = null;
  if (date === "" || item.length === 0) {
    return null;
  }
  const filteredItems = item.find((entry) => entry.date === date);

  if (input.type === "Consument") {
    let productTotals = {};

    // Yeni bir dizi oluşturarak mevcut nesneleri değiştirmiyoruz
    const updatedLogs = filteredItems.logs.map((log) => {
      const logDate = new Date(log.createdInfo);
      logDate.setUTCHours(logDate.getUTCHours() + 3); // 3 saat ekle
      return {
        ...log,
        createdInfo: logDate.toISOString(), // Güncellenmiş tarihi ISO formatında ayarla
      };
    });

    updatedLogs.forEach((log) => {
      if (productTotals[log.productName]) {
        productTotals[log.productName] += log.consument;
      } else {
        productTotals[log.productName] = log.consument;
      }
    });

    // Güncellenmiş logları kullanarak renderOutput çağrısı yapıyoruz
    output = renderOutput({ ...filteredItems, logs: updatedLogs }, filterType);
  }

  // Diğer türler için aynı şekilde devam ediliyor...

  return output;
}

function ReportDevice() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;
  const [filterType, setFilterType] = useState("Day");
  const [date, setDate] = useState(new Date());
  const [dateSecond, setDateSecond] = useState(new Date());
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [choiceDate, setChoiceDate] = useState("");
  const [info, setInfo] = useState(false);
  const [input, setInput] = useState({
    id: id,
    token: token,
    type: "",
    createdInfo: "",
    createdInfoSecond: "",
  });

  const [getReport, { data, refetch, isFetching, isLoading, isError }] =
    useLazyGetDeviceLogReportQuery();
  const [Datas, setDatas] = useState([]);

  useEffect(() => {
    if (input.type !== prevInputType) {
      setInfo(false);
      setDatas([]);
    } else {
      setDatas(
        data?.data?.filter((item) => item.logs && item.logs.length > 0) || []
      );
    }

    setPrevInputType(input.type); // Mevcut input.type değerini önceki değer olarak kaydedin
  }, [data, input.type]);

  const [prevInputType, setPrevInputType] = useState(input.type);

  const config = [
    {
      label: "İncele",
      render: (data) => (
        <div className=" flex items-center justify-center">
          <button
            onClick={() => {
              setChoiceDate(data.date);
              setInfo(true);
            }}
            className={`${styles.button}  w-fit`}
          >
            <BsFileEarmarkBarGraph className={`${styles.buttonIcon}`} />
          </button>
        </div>
      ),
    },
    {
      label: "Rapor Tarihi",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Rapor Tarihi:</div>
          {FormatDateString(data.date)}
        </>
      ),
    },
  ];

  if (Datas.length !== 0 && input.type === "Consument") {
    const combinedProductTotalsObj = {
      label: "Tüketim Toplamları",
      render: (data) => {
        const productTotals = {};

        data?.logs?.forEach((log) => {
          if (log && log.productName && typeof log.consument === "number") {
            if (productTotals[log.productName]) {
              productTotals[log.productName] += log.consument;
            } else {
              productTotals[log.productName] = log.consument;
            }
          }
        });

        return (
          <div>
            {Object.keys(productTotals).map((productName) => (
              <div key={productName}>
                <strong>{productName}:</strong> {productTotals[productName]}
              </div>
            ))}
          </div>
        );
      },
    };

    config.push(combinedProductTotalsObj);
  }

  const handleGetLogData = () => {
    if (input.createdInfo && input.type) {
      getReport(input);
    }
  };
  const handleFilterType = (type) => {
    setFilterType(type);
  };

  useEffect(() => {
    const startOfDay = new Date(date);
    startOfDay.setUTCHours(0, 0, 0, 0); // Günün başlangıcı
    const endOfDay = new Date(date);
    endOfDay.setUTCHours(23, 59, 59, 999); // Günün sonu

    setInput({
      ...input,
      createdInfo: new Date(
        startOfDay.getTime() - 3 * 60 * 60 * 1000
      ).toISOString(), // 3 saat öncesi
    });
  }, [date]);

  useEffect(() => {
    const startOfSecondDay = new Date(dateSecond);
    startOfSecondDay.setUTCHours(0, 0, 0, 0); // İkinci günün başlangıcı

    setInput({
      ...input,
      createdInfoSecond: new Date(
        startOfSecondDay.getTime() - 3 * 60 * 60 * 1000
      ).toISOString(), // 3 saat öncesi
    });
  }, [dateSecond]);

  function groupAndMergeLogs(data, filterType) {
    const grouped = {};

    data.forEach((item) => {
      if (!item.logs || item.logs.length === 0) return;

      const date = new Date(item.date);
      let key;

      switch (filterType) {
        case "Day":
          key = date.toISOString().split("T")[0];
          break;
        case "Month":
          key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
            2,
            "0"
          )}`;
          break;
        case "Year":
          key = `${date.getFullYear()}`;
          break;
        default:
          return;
      }

      if (!grouped[key]) {
        grouped[key] = [...item.logs];
      } else {
        grouped[key] = [...grouped[key], ...item.logs];
      }
    });

    return Object.keys(grouped).map((key) => ({
      date: key,
      logs: grouped[key],
    }));
  }

  return (
    <>
      <SidebarList
        info={info}
        onClick={() => {
          setInfo(false);
          setChoiceDate("");
        }}
      >
        {ReportReturn({
          item: groupAndMergeLogs(Datas, filterType),
          date: choiceDate,
          input,
          filterType,
        })}{" "}
        {/* Değişiklik yapıldı */}
      </SidebarList>
      <div className="flex max-md:flex-col md:items-center gap-4 w-fit ">
        <button
          onClick={() => handleFilterType("Day")}
          className={`${
            filterType !== "Day" ? "bg-white" : "bg-red-500"
          }  border-red-500 border-4 p-2 rounded-md w-fit`}
        >
          <p
            className={`${
              filterType === "Day" ? "text-white" : "text-red-500"
            } text-xl `}
          >
            Günlük
          </p>
        </button>
        <button
          onClick={() => handleFilterType("Month")}
          className={`${
            filterType !== "Month" ? "bg-white" : "bg-red-500"
          }  border-red-500 border-4 p-2 rounded-md w-fit`}
        >
          <p
            className={`${
              filterType === "Month" ? "text-white" : "text-red-500"
            } text-xl `}
          >
            Aylık
          </p>
        </button>
        <button
          onClick={() => handleFilterType("Year")}
          className={`${
            filterType !== "Year" ? "bg-white" : "bg-red-500"
          }  border-red-500 border-4 p-2 rounded-md w-fit`}
        >
          <p
            className={`${
              filterType === "Year" ? "text-white" : "text-red-500"
            } text-xl `}
          >
            Yıllık
          </p>
        </button>
        <SelectType
          label="Rapor Tipi"
          options={[
            { label: "Tüketim", value: "Consument" },
            { label: "Ayar Değişikliği", value: "UpdateSetting" },
            { label: "Bilgi Değişikliği", value: "UpdateInfo" },
            { label: "Manual Kota Yükleme", value: "UpdateManualQuota" },
          ]}
          selectedValue={input.type}
          onChange={(value) => {
            setChoiceDate("");
            setInput({ ...input, type: value });
          }}
        />
        <CustomCalendar setDate={setDate} date={date} />
        <CustomCalendar setDate={setDateSecond} date={dateSecond} />

        <button onClick={handleGetLogData} className={`${styles.button} w-fit`}>
          <p className="">Getir</p>
        </button>
      </div>
      <div className="flex flex-col w-full items-center gap-6 bg-white rounded-xl overflow-hidden shadow-md mb-20 ">
        {isFetching ? (
          <div className=" flex w-full h-full justify-center items-center">
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#c0efff"
              color="#004080"
            />
          </div>
        ) : isError || Datas?.length === 0 ? (
          <UnfindDataWarn Data={"Rapor bulunamadı"} />
        ) : (
          <ListInfo
            Config={config}
            Key={"date"}
            SetPaginationNumber={setPaginationNumber}
            PaginationNumber={paginationNumber}
            IsSearch={false}
            FilteredData={groupAndMergeLogs(Datas, filterType)}
            Data={groupAndMergeLogs(Datas, filterType)}
          />
        )}
      </div>
    </>
  );
}

export default ReportDevice;
