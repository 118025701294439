import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation, useParams } from "react-router-dom";
import SortableTable from "../../../Components/Table/SortableTable";
import PaginationBar from "../../../Components/Table/PaginationBar";
import { useGetBelongUsersByFirmIDQuery } from "../../../store/firmApi";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";

function ControlUsers() {
  const location = useLocation();

  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [paginationNumber, setPaginationNumber] = useState(1);

  const [input, setInput] = useState({});
  const ResultBelongUsers = useGetBelongUsersByFirmIDQuery({ id, token });
  const DataUser = ResultBelongUsers?.data?.data?.users || [];

  const config = [
    {
      label: " İsim",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">İsim:</div>
          {data.name}
        </>
      ),
      sortValue: (data) => data.name,
    },
    {
      label: " Soyisim",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Soyisim:</div>
          {data.lastName}
        </>
      ),
      sortValue: (data) => data.lastName,
    },
    {
      label: "İletişim",
      render: (data) => (
        <div className=" flex flex-col">
          <div className=" md:hidden opacity-40 font-Bold">İletişim:</div>

          <div>
            <b> Email:</b>
            {"  "} {data.email}
          </div>
          <div>
            <b>Telefon:</b>
            {"  "} {data.tel}
          </div>
        </div>
      ),
    },
  ];
  const keyFn = (data) => {
    return data._id;
  };
  useEffect(() => {
    setInput({
      ...input,
      id: id,
      token: token,
    });
  }, []);

  return (
    <div className=" grid w-full">
      {ResultBelongUsers.isFetching ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : ResultBelongUsers?.data?.results === 0 ||
        ResultBelongUsers.isError === true ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10`}
        >
          <UnfindDataWarn Data={"Bu firmaya bağlı kullanıcı bulunamadı !"} />
        </div>
      ) : (
        <div className=" flex flex-col w-full gap-4 mb-20 transition-all duration-300 bg-white p-4 mt-8">
          <div className="flex flex-col w-full items-center">
            <PaginationBar
              elements={DataUser}
              info="Bu bilgilerde bir fatura bulunamadı."
              paginationNumber={paginationNumber}
              setPaginationNumber={setPaginationNumber}
            />
          </div>
          <div className=" w-full">
            <SortableTable
              data={DataUser}
              config={config}
              keyFn={keyFn}
              paginationNumber={paginationNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ControlUsers;
