import { NavLink } from "react-router-dom";

export default function TopBar({ links, backLink }) {
  return (
    <div className="z-40 w-full h-fit flex items-center justify-between border-b-4 border-main">
      <div className="grid grid-flow-col md:gap-6 py-3 overflow-y-scroll no-scrollbar">
        {links.map((link) => (
          <NavLink
            key={link.to}
            to={link.to}
            state={link.state}
            style={({ isActive }) =>
              isActive ? { color: "rgb(248, 113, 113)" } : {}
            }
            className={`flex justify-center items-center gap-1 transition-all duration-200 text-main hover:text-red-400`}
          >
            {link.icon}
            {link.label}
          </NavLink>
        ))}
      </div>
      {backLink && (
        <NavLink
          to={backLink.path}
          className={`max-md:fixed max-md:top-2 max-md:right-2 flex md:px-6 z-40 transition-all duration-200 text-main hover:text-red-400 gap-4`}
        >
          {backLink.icon}
        </NavLink>
      )}
    </div>
  );
}
