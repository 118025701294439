import { useEffect, useState } from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { HiHomeModern } from "react-icons/hi2";
import { RiCupFill } from "react-icons/ri";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImConnection } from "react-icons/im";
import { FiActivity } from "react-icons/fi";
import { GiPositionMarker } from "react-icons/gi";
import styles from "../../../CustomStyles";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  if (totalPages <= 6) {
    // If total pages are 6 or less, show all pages
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Calculate the range of page numbers to display

    // Adjust startPage and endPage if they cause the displayed pages to be more than 6
    if (window.innerWidth < 1280) {
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(totalPages, currentPage + 1);

      if (startPage === 1) {
        endPage = startPage + 2;
      } else if (endPage === totalPages) {
        startPage = endPage - 2;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
      // Push the calculated range of pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 3);

      if (startPage === 1) {
        endPage = startPage + 5;
      } else if (endPage === totalPages) {
        startPage = endPage - 5;
      } else {
        startPage = currentPage - 5 + 3;
        endPage = currentPage + 3;
      }
      // Push the calculated range of pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
  }

  return (
    <div className="flex items-center justify-center space-x-1">
      <div
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200 disabled:opacity-50"
      >
        <MdOutlineArrowBackIos className="w-4 h-4 text-[#686D76] " />
      </div>
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={` text-center w-6 py-0.5 text-xl text-[#686D76]  rounded-full hover:bg-gray-200 ${
            currentPage === number ? "bg-gray-300" : ""
          }`}
        >
          {number}
        </button>
      ))}
      <div
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-2 py-1 cursor-pointer rounded-md hover:bg-gray-200 disabled:opacity-50"
      >
        <MdArrowForwardIos className="w-4 h-4 text-[#686D76] " />
      </div>
    </div>
  );
};

const ItemNumber = ({ length }) => {
  return (
    <div>
      <p className=" text-[#686D76] text-BASE">Cihazlar 1 den {length}</p>
    </div>
  );
};

function timeAgo(createdInfo) {
  const now = new Date();
  const createdTime = new Date(createdInfo);
  const diffInMilliseconds = now - createdTime;

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30); // Approximate

  if (diffInMinutes < 60) {
    return `${diffInMinutes} dakika önce`;
  } else if (diffInHours < 24) {
    return `${diffInHours} saat önce`;
  } else if (diffInDays < 7) {
    return `${diffInDays} gün önce`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} hafta önce`;
  } else {
    return `${diffInMonths} ay önce`;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function parseProductName(productName) {
  return productName.slice(0, productName.indexOf("-"));
}

export default function DeviceTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({
    deviceName: "",
    serialNo: "",
    firm: { firmName: "" },
    lastConnectionDate: "",
    deviceStatusName: "",
    isActive: "",
    productInfo: [],
  });

  const handleOpen = (index) => {
    setSelectedDevice(data[index]);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentData = data.slice(firstIndex, lastIndex);

  const onPageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1280) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" relative flex-row flex-1 gap-8 w-full h-full items-center justify-center">
      <div className="  mb-4">
        {currentData.map((device, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleOpen((currentPage - 1) * itemsPerPage + index);
              }}
              className={`flex ${
                index !== 4 ? "border-b-2" : ""
              }  flex-1 flex-row gap-4 w-full xl:h-26 h-24 items-center justify-center cursor-pointer hover:bg-[#DDDDDD]`}
            >
              <div className=" w-2/6 h-22 space-y-1 flex flex-col items-center justify-center">
                <p className=" text-[#686D76]  text-BASE">
                  {device.deviceName}
                </p>
                <p className=" text-[#B4B4B8] text-SM">{device.serialNo}</p>
              </div>
              <div className=" max-md:hidden w-1/6 h-22 space-y-1 flex flex-row gap-1 items-center justify-center">
                <HiHomeModern className=" w-4 h-4" />
                <p className=" text-[#686D76]  text-BASE">
                  {device.firm.firmName}
                </p>
              </div>
              <div className="max-md:hidden  w-1/6 h-22 h-24 space-y-0.5 flex flex-col justify-center">
                <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                  <GiPositionMarker className=" w-4 h-4" />
                  <p className=" ">{device.deviceStatusName}</p>
                </div>
                <div className=" text-[#686D76]  flex flex-row gap-2 items-center text-BASE">
                  <ImConnection className=" w-4 h-4" />
                  <div>
                    <p className=" text-[#686D76]  text-BASE">
                      {timeAgo(device.lastConnectionDate)}
                    </p>
                    <p className=" text-[#B4B4B8] text-SM">
                      {formatDate(device.lastConnectionDate)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="max-md:hidden w-1/6 h-22 h-24 space-y-0.5 flex flex-col justify-center">
                <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                  <FiActivity />

                  <p className=" ">{device.isActive ? "Aktif" : "Pasif"}</p>
                </div>
              </div>
              <div className="max-md:w-3/6 w-2/6 h-22 h-24 space-y-0.5 flex flex-col justify-center">
                {device.productInfo.productInfo.map((product, index) => {
                  return (
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <RiCupFill className=" w-4 h-4" />
                      <div className=" flex flex-row items-center gap-2">
                        <p className=" text-[#686D76]  text-BASE">
                          {product.productName}
                        </p>
                        <p className=" text-[#B4B4B8] text-BASE">
                          {product.quota}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-row ">
            <NavLink
              state={{
                id: selectedDevice._id,
                serialNo: selectedDevice.serialNo,
                deviceName: selectedDevice.deviceName,
                createdInfo: selectedDevice.createdInfo,
                updatedInfo: selectedDevice.updatedInfo,
              }}
              to={`/Anasayfa/Cihaz/Düzenle/Bilgi`}
            >
              <button className={`${styles.tableButton}`}>
                <BsFillPencilFill className={`w-[1.5rem] h-[1.5rem]`} />
              </button>
            </NavLink>
            <NavLink
              to={`/Anasayfa/Cihaz/Bilgi/Genel`}
              state={{
                id: selectedDevice._id,
                serialNo: selectedDevice.serialNo,
                deviceName: selectedDevice.deviceName,
                createdInfo: selectedDevice.createdInfo,
                updatedInfo: selectedDevice.updatedInfo,
                isActive: selectedDevice.isActive,
              }}
            >
              <button className={`${styles.tableButton}`} onClick={() => {}}>
                <TbReportAnalytics className={`w-[1.5rem] h-[1.5rem]`} />
              </button>
            </NavLink>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Makine: ${selectedDevice.deviceName}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Seri No: ${selectedDevice.serialNo}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Firma: ${selectedDevice.firm.firmName}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Cihaz Durumu: ${selectedDevice.deviceStatusName}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Cihaz Aktif/Pasif: ${
              selectedDevice.isActive ? "Aktif" : "Pasif"
            }`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Ürün: 
            ${selectedDevice?.productInfo?.productInfo?.map(
              (product, index) => {
                return product.productName + "(" + product.quota + ")";
              }
            )}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Son Bağlantı: ${timeAgo(selectedDevice.lastConnectionDate)}`}
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-column items-center justify-between">
        <ItemNumber length={data.length} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
