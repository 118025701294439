import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation, useParams } from "react-router-dom";
import Alerts from "../../../Components/Utils/Alert";
import { useGetFirmByIDQuery, useUpdateFirmInfoMutation } from "../../../store";
import Loader from "../../../Components/Utils/Loader";
import Confirm from "../../../Components/Utils/Confirm";
import CreateFirmForm from "../../../Forms/FirmForm/FirmForm";

function UpdateInfo() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [alert, setAlert] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const ResultMainFirm = useGetFirmByIDQuery({ id, token });
  const [update, resultUpdate] = useUpdateFirmInfoMutation();
  const DataFirm = ResultMainFirm?.data?.data?.firm || [];

  const [inputFieldName, setInputFieldName] = useState({});
  const [infoText, setInfoText] = useState("");
  const [input, setInput] = useState({
    _id: "",
    firmName: "",
    mainFirm: "",
    isActive: "",
    officialNumber: "",
    taxNumber: "",
    taxOffice: "",
    isCorporate: "",
    tel: "",
    email: "",
    address: { city: "", town: "", text: "" },
    playMakers: [],
    token: token,
  });

  useEffect(() => {
    if (ResultMainFirm.status === "fulfilled")
      setInput({ ...DataFirm, token: token });
  }, [ResultMainFirm]);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    setConfirm(false);
    if (resultUpdate.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
      setInput({
        ...DataFirm,
        token: token,
      });
    }
    if (resultUpdate.isSuccess === true) {
      setAlert(1);
      setMessage("İşlem başarı ile gerçekleşti !");
    }
  }, [resultUpdate.isSuccess, resultUpdate.isError]);

  const handleCloseModel = (bool) => {
    if (bool) {
      update({ ...input, _id: id });
      setConfirm(true);
      setInfoText("Değişiklikleri kaydetmek istediğinize emin misiniz ?");
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
    }
  };

  console.log(input);
  return (
    <div>
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />
      {ResultMainFirm.isFetching || resultUpdate.isLoading || confirm ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : (
        <div
          className={`flex w-full 
      mt-[2rem] flex-col justify-between gap-4 
      rounded-xl text-black`}
        >
          <CreateFirmForm
            setDesignedInput={setInput}
            setInputFieldName={setInputFieldName}
            setShowConfirmModal={setShowConfirmModal}
            initialFValues={{
              isActive: input.isActive,
              isCorporate: input.isCorporate,
              firmName: input.firmName,
              mainFirmName: input.mainFirm?.firmName,
              officialNumber: input.officialNumber,
              taxNumber: input.taxNumber,
              taxOffice: input.taxOffice,
              email: input.email,
              tel: input.tel,
              note: input.note,
              city: input.address.city,
              town: input.address.town,
              text: input.address.text,
            }}
            token={token}
          />
        </div>
      )}
    </div>
  );
}

export default UpdateInfo;
