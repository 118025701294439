import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";
import { useFetchFirmQuery, useFetchPlayMakerQuery } from "../../store";

export default function DevicePositionFirm({
  setDesignedInput,
  initialFValues,
  token,
}) {
  const responseFirm = useFetchFirmQuery(token);
  const Firms = responseFirm.data?.data?.firms || [];
  const FirmsOptions = [
    ...Firms?.map((option) => {
      return { title: option.firmName };
    }),
  ];
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "Operasyon nedenini belirtiniz.";
    if ("firmName" in fieldValues)
      temp.firmName = fieldValues.firmName ? "" : "Firma seçiniz.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setDesignedInput({
        deviceStatusName: values.deviceStatusName,
        description: values.description,
        connect: values.connect,
        firmName: values.firmName,
      });
    }
  };

  return (
    <form
      className=" grid  gap-10  w-full items-center justify-center"
      onSubmit={handleSubmit}
    >
      <p className="justify-self-center text-main font-SemiBold text-3xl">
        Firmaya Konumlandırma
      </p>
      <div className=" grid gap-4">
        <Controls.SelectOptions
          label="Firmalar Listesi"
          name="firmName"
          value={values.firmName}
          onChange={handleInputChange}
          options={FirmsOptions}
          error={errors.firmName}
        />
        <Controls.Input
          label="Açıklama"
          name="description"
          value={values.description}
          onChange={handleInputChange}
          error={errors.description}
        />
        <Controls.Checkbox
          label="Makine bağlantısı kurulsun mu ?"
          name="connect"
          value={values.connect}
          onChange={handleInputChange}
        />
      </div>

      <button
        className={` z-20 md:rounded-md rounded-full w-fit justify-self-center
         ${styles.button}`}
      >
        <p>Kaydet</p>
      </button>
    </form>
  );
}
