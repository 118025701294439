import { useState } from "react";
import { NavLink } from "react-router-dom";
import useUsersContext from "../../Hooks/use-user-context";
import useAuth from "../../Hooks/useAuth";
import { BsChevronDown } from "react-icons/bs";

export default function Sidebarlink({ link, isHover, roles, linkInfo, GO }) {
  const [open, setOpen] = useState(false);
  const activeClassName = "font-Bold border-l-2 border-white  w-full";
  const { LogOut } = useUsersContext();
  const { auth, setAuth } = useAuth();
  const handleLogout = async () => {
    try {
      const token = auth.accessToken;
      localStorage.clear();
      setAuth(null);
      await LogOut(token);
    } catch (err) {}
  };

  if (link.roles?.includes(auth?.role) !== false) {
    if (link.childrens) {
      return (
        <div className={`flex flex-col w-full`}>
          <button
            onClick={() => {
              setOpen(!open);
            }}
            className="py-5 px-4 justify-start items-center flex  gap-9 w-full hover:bg-white hover:text-main"
          >
            {link.icon}
            <div className=" flex items-center gap-4">
              <p className={` transition-all  ${linkInfo} `}>{link.label}</p>
              <BsChevronDown className=" " onClick={() => setOpen(!open)} />
            </div>
          </button>
          <div className={`${open ? " flex flex-col pl-4 " : "hidden"}`}>
            {link?.childrens?.map((child, index) => (
              <div key={index}>
                <Sidebarlink
                  link={child}
                  isHover={isHover}
                  roles={roles}
                  linkInfo={linkInfo}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className=" w-full flex">
          <NavLink
            to={link.path}
            className={({ isActive }) =>
              isActive ? activeClassName : "w-full"
            }
            onClick={() => {
              if (link.label === "Çıkış") handleLogout();
            }}
          >
            <div className="flex gap-10 w-full h-12 items-center py-5 px-4 hover:bg-white hover:text-main">
              {link.icon}
              <p className={`flex transition-all ${linkInfo}`}>{link.label}</p>
            </div>
          </NavLink>
        </div>
      );
    }
  }
}
