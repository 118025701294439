import { useEffect, useState } from "react";
import styles from "../../CustomStyles";
import useAuth from "../../Hooks/useAuth";
import ProductConsumentCard from "./ProductConsumentCard";
import FilterButton from "./FilterButton";
import ConsumpitonGraph from "./ConsumptionGraph";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import BillInfoCard from "./Bills/BillInfoCard";
import {
  useGetDashBoardBillsQuery,
  useGetDashBoardConsumentsQuery,
  useGetDashBoardDevicesQuery,
} from "../../store/dashboardApi";
import DeviceInfoCard from "./Devices/DeviceInfoCard";
import FirmInfoCard from "./Firms/FirmInfoCard";
import IncomeInfoCard from "./Incomes/IncomeInfoCard";

function DashBoardPage() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const dashboardLinks = [
    {
      type: "Firma",
      text: "Ürün Tüketimleri",
      graphText: "Yıllık Tüketim Hareketi",
      warn: "Her hangi bir tüketim yok.",
    },
    {
      type: "Servis",
      text: "Servis Kullanımı",
      graphText: "Yıllık Servis Hareketi",
      warn: "Her hangi test yok.",
    },
    {
      type: "Plasiyer",
      text: "Plasiyer Kullanımı",
      graphText: "Yıllık Plasiyer Hareketi",
      warn: "Her hangi bir sunum yok.",
    },
  ];
  const [dashboardType, setDashboardType] = useState({
    type: "Firma",
    text: "Ürün Tüketimleri",
    graphText: "Yıllık Tüketim Hareketi",
    warn: "Her hangi bir tüketim yok.",
  });
  const [previousDashboardDeviceData, setPreviousDashboardDeviceData] =
    useState(null);
  const [previousDashboardBillsData, setPreviousDashboardBillsData] =
    useState(null);
  const [previousDashboardConsumentsData, setPreviousDashboardConsumentsData] =
    useState(null);

  const [activeConsumption, setActiveConsumption] = useState("today");

  const {
    data: dashboardConsumentData,
    isLoading: dashboardConsumentIsLoading,
    isFetching: dashboardConsumentIsFetching,
    error: dashboardConsumentError,
    refetch: dashboardConsumentRefetch,
  } = useGetDashBoardConsumentsQuery(
    { token, section: dashboardType.type },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const {
    data: dashboardBillsData,
    isLoading: dashboardBillsIsLoading,
    isFetching: dashboardBillsIsFetching,
    error: dashboardBillsError,
    refetch: dashboardBillsRefetch,
  } = useGetDashBoardBillsQuery(
    { token },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const {
    data: dashboardDeviceData,
    isLoading: dashboardDeviceIsLoading,
    isFetching: dashboardDeviceIsFetching,
    error: dashboardDeviceError,
    refetch: dashboardDeviceRefetch,
  } = useGetDashBoardDevicesQuery(
    { token },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  useEffect(() => {
    if (dashboardBillsData) {
      setPreviousDashboardBillsData(dashboardBillsData);
    }
  }, [dashboardBillsData]);

  useEffect(() => {
    if (dashboardConsumentData) {
      setPreviousDashboardConsumentsData(dashboardConsumentData);
    }
  }, [dashboardConsumentData]);

  useEffect(() => {
    if (dashboardDeviceData) {
      setPreviousDashboardDeviceData(dashboardDeviceData);
    }
  }, [dashboardDeviceData]);

  useEffect(() => {
    const timer = setInterval(() => {
      dashboardConsumentRefetch();
    }, 5 * 60 * 1000);
    return () => clearInterval(timer);
  }, [dashboardConsumentRefetch]);

  const handleClickConsumption = (choice) => {
    setActiveConsumption(choice);
  };

  const displayConsumentsData =
    dashboardConsumentData || previousDashboardConsumentsData;
  const displayBillsData = dashboardBillsData || previousDashboardBillsData;
  const displayDevicesData = dashboardDeviceData || previousDashboardDeviceData;

  const DashboardLink = ({
    type,
    text,
    graphText,
    warn,
    currentType,
    setDashboardType,
  }) => {
    const isActive = type === currentType;
    return (
      <div
        onClick={() =>
          setDashboardType({
            type,
            text,
            graphText,
            warn,
          })
        }
        className={`flex text-2xl tracking-widest font-Bold gap-2 p-2 cursor-pointer border-2 rounded-md border-main ${
          isActive ? "bg-main text-white" : "bg-white text-main"
        }`}
      >
        <p className={``}>{type.toUpperCase()}</p>
        <p className={` max-md:hidden`}>DASHBOARD</p>
      </div>
    );
  };

  return (
    <div className="flex  flex-col gap-4 w-full mb-20">
      {dashboardConsumentError ||
      dashboardDeviceError ||
      dashboardBillsError ? (
        <UnfindDataWarn Data={"Dashboard yüklenirken bir sorun oluştu !"} />
      ) : (
        <div className={`flex flex-col gap-4 h-full w-full `}>
          <div className="flex max-xl:flex-col w-full  gap-4">
            <div className=" relative h-[36rem] flex flex-col items-center justify-center w-full rounded-lg shadow-lg  bg-white  gap-8">
              <div className=" absolute top-0 left-0  w-full h-full opacity-40"></div>
              <div className=" absolute top-0 left-0 flex justify-between">
                <p className={`${styles.Bigtext} ml-12 pt-4 text-main`}>
                  {dashboardType.graphText}
                </p>
              </div>
              <div className=" flex w-full h-[36rem] flex-1 pt-10 ">
                <ConsumpitonGraph
                  dashboardConsumentIsLoading={dashboardConsumentIsLoading}
                  dashboardConsumentIsFetching={dashboardConsumentIsFetching}
                  dashboardType={dashboardType}
                  warn={dashboardType.warn}
                  monthlyConsumption={displayConsumentsData?.monthlyConsumption}
                />
              </div>
            </div>
            <div className=" relative h-[36rem] flex flex-col items-center justify-center  bg-white shadow-lg w-full rounded-lg ">
              <div className=" absolute top-0 left-0  w-full h-full opacity-10"></div>
              <div className="flex mx-4 max-sm:flex-col items-center sm:justify-between justify-center h-fit gap-4 p-4 relative">
                <p
                  className={`${styles.Bigtext} text-center text-main z-10 px-2`}
                >
                  {dashboardType.text}
                </p>
                <FilterButton
                  active={activeConsumption}
                  handleClick={handleClickConsumption}
                  button={
                    "z-10 p-2 border-2 border-main hover:bg-white hover:text-main rounded-md transition-all duration-300 shadow-xl hover:scale-110"
                  }
                />
              </div>
              <div className="flex w-full h-full max-sm:ml-4">
                <ProductConsumentCard
                  dashboardType={dashboardType}
                  consumption={
                    activeConsumption === "today"
                      ? displayConsumentsData?.todayConsumption
                      : activeConsumption === "yesterday"
                      ? displayConsumentsData.yesterdayConsumption
                      : activeConsumption === "thisweek"
                      ? displayConsumentsData.thisWeekConsumption
                      : activeConsumption === "thismonth"
                      ? displayConsumentsData.thisMonthConsumption
                      : displayConsumentsData.thisYearConsumption
                  }
                  warn={dashboardType.warn}
                  dashboardConsumentIsLoading={dashboardConsumentIsLoading}
                  dashboardConsumentIsFetching={dashboardConsumentIsFetching}
                />
              </div>
            </div>
          </div>
          <div className="xl:flex xl:flex-row w-full max-xl:space-y-4 gap-4 justify-between">
            <FirmInfoCard
              isLoading={dashboardBillsIsLoading}
              isFetching={dashboardBillsIsFetching}
              firms={displayBillsData?.notOrderedFirms}
              warn={"Her hangi bir fatura yok."}
            />
            <IncomeInfoCard
              isLoading={dashboardBillsIsLoading}
              isFetching={dashboardBillsIsFetching}
              incomes={displayBillsData?.revenue}
              warn={"Her hangi bir ciro yok."}
            />
          </div>
          <div className="xl:flex xl:flex-row w-full max-xl:space-y-4 gap-4 justify-between">
            <BillInfoCard
              isLoading={dashboardBillsIsLoading}
              isFetching={dashboardBillsIsFetching}
              bills={displayBillsData?.bills}
              warn={"Her hangi bir fatura yok."}
            />
            <DeviceInfoCard
              isLoading={dashboardDeviceIsLoading}
              isFetching={dashboardDeviceIsFetching}
              devices={displayDevicesData?.devices}
              warn={"Her hangi bir cihaz yok."}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DashBoardPage;
