import { RxCross2 } from "react-icons/rx";
export default function SidebarList({ children, ...props }) {
  return (
    <>
      {props.info && (
        <div
          onClick={props.onClick}
          className=" z-40 fixed top-0 left-0 h-screen bg-black opacity-60 w-screen"
        ></div>
      )}
      <div
        className={`fixed duration-300 overflow-hidden transition-all top-0 right-0  bg-slate-400 h-screen z-50 `}
      >
        <RxCross2
          onClick={props.onClick}
          className=" absolute top-0  w-12 h-12 cursor-pointer pointer-events-auto text-slate-100"
        />
        {props.info && (
          <div
            style={{ overflowY: "auto", maxHeight: "90vh" }}
            className=" bg-white p-4 mr-8 ml-16 mt-12 "
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
}
