import { useEffect, useState } from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { RiCupFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { GiCash } from "react-icons/gi";
import { BiBarcodeReader } from "react-icons/bi";
import { IoCodeSharp } from "react-icons/io5";
import { GrMoney } from "react-icons/gr";
import { GiReceiveMoney } from "react-icons/gi";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  if (totalPages <= 6) {
    // If total pages are 6 or less, show all pages
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Calculate the range of page numbers to display

    // Adjust startPage and endPage if they cause the displayed pages to be more than 6
    if (window.innerWidth < 1280) {
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(totalPages, currentPage + 1);

      if (startPage === 1) {
        endPage = startPage + 2;
      } else if (endPage === totalPages) {
        startPage = endPage - 2;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
      // Push the calculated range of pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 3);

      if (startPage === 1) {
        endPage = startPage + 5;
      } else if (endPage === totalPages) {
        startPage = endPage - 5;
      } else {
        startPage = currentPage - 5 + 3;
        endPage = currentPage + 3;
      }
      // Push the calculated range of pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
  }

  return (
    <div className="flex items-center justify-center space-x-1">
      <div
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200 disabled:opacity-50"
      >
        <MdOutlineArrowBackIos className="w-4 h-4 text-[#686D76] " />
      </div>
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={` text-center w-6 py-0.5 text-xl text-[#686D76]  rounded-full hover:bg-gray-200 ${
            currentPage === number ? "bg-gray-300" : ""
          }`}
        >
          {number}
        </button>
      ))}
      <div
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-2 py-1 cursor-pointer rounded-md hover:bg-gray-200 disabled:opacity-50"
      >
        <MdArrowForwardIos className="w-4 h-4 text-[#686D76] " />
      </div>
    </div>
  );
};

const ItemNumber = ({ length }) => {
  return (
    <div>
      <p className=" text-[#686D76] text-BASE">Firmalar 1 den {length}</p>
    </div>
  );
};

function timeAgo(createdInfo) {
  const now = new Date();
  const createdTime = new Date(createdInfo);
  const diffInMilliseconds = now - createdTime;

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30); // Approximate

  if (diffInMinutes < 60) {
    return `${diffInMinutes} dakika önce`;
  } else if (diffInHours < 24) {
    return `${diffInHours} saat önce`;
  } else if (diffInDays < 7) {
    return `${diffInDays} gün önce`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} hafta önce`;
  } else {
    return `${diffInMonths} ay önce`;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function parseProductName(productName) {
  return productName.slice(0, productName.indexOf("-"));
}

export default function FirmTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentData = data.slice(firstIndex, lastIndex);

  const onPageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(2);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="flex flex-col h-60 justify-between w-full 
    "
    >
      <div className=" ">
        {currentData.map((firm, index) => {
          return (
            <div
              key={index}
              className={`flex ${
                index !== 4 ? "border-b-2" : ""
              }  flex-1 flex-row gap-4 w-full  h-24 items-center justify-center cursor-pointer hover:bg-[#DDDDDD]`}
            >
              <div className=" w-1/6 h-24 space-y-1 max-md:w-3/6 flex flex-col items-center justify-center">
                {firm.lastOrderDate ? (
                  <>
                    <p className=" text-[#686D76]  text-BASE">
                      {firm.daysSinceLastOrder} gün önce
                    </p>
                    <p className=" text-[#B4B4B8] text-SM">
                      {formatDate(firm.lastOrderDate)}
                    </p>
                  </>
                ) : (
                  <p className=" text-[#686D76]  text-BASE">
                    Henüz fatura kesilmemiş.
                  </p>
                )}
              </div>
              <div className=" w-1/6 h-24 max-xl:w-2/6 max-md:w-3/6 space-y-1 flex flex-col items-center justify-center">
                <p className=" text-[#686D76]  text-BASE">
                  {firm.firmApellation}
                </p>
              </div>
              {firm.lastOrderInfo ? (
                <>
                  <div className="w-[20rem]  max-xl:w-3/6 max-md:hidden h-24 space-y-0.5 flex flex-col  justify-center">
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <ImProfile className=" w-4 h-4" />
                      <p className=" text-[#686D76]  text-BASE">
                        {firm.lastOrderInfo.billerName}
                      </p>
                    </div>
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <BiBarcodeReader className=" w-4 h-4" />
                      <p className=" text-[#686D76]  text-BASE">
                        {firm.lastOrderInfo.billNo}
                      </p>
                    </div>
                  </div>
                  <div className="w-3/6  h-24 max-xl:hidden space-y-0.5 flex flex-col justify-center">
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <GiReceiveMoney className=" w-4 h-4" />
                      <p className=" ">{firm.lastOrderInfo.income} ₺</p>
                    </div>
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <GiCash className=" w-4 h-4" />
                      <p className=" ">{firm.lastOrderInfo.price} ₺</p>
                    </div>
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <RiCupFill className=" w-4 h-4" />
                      <div className=" flex flex-row items-center gap-2">
                        <p className=" text-[#686D76]  text-BASE">
                          {firm.lastOrderInfo.productName}
                        </p>
                        <p className=" text-[#B4B4B8] text-BASE">
                          {firm.lastOrderInfo.quota}
                        </p>
                      </div>
                    </div>
                    <div className="text-[#686D76] flex flex-row gap-2 text-BASE">
                      <IoCodeSharp className=" w-4 h-4" />
                      <p className=" text-[#686D76]  text-BASE">
                        {firm.lastOrderInfo.productCode}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>

      <div className=" relative flex mt-4 flex-column items-center justify-between">
        <ItemNumber length={data.length} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
