import { Outlet, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
//Icons
import { TbListDetails } from "react-icons/tb";
import { MdOutlineKeyboardBackspace, MdCoffeeMaker } from "react-icons/md";
import { SlGraph } from "react-icons/sl";

import styles from "../../CustomStyles";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import TopBar from "../../Components/Utils/TopBar";
import useAuth from "../../Hooks/useAuth";

function InfoDevicePage() {
  const location = useLocation();
  const { deviceName, serialNo, createdInfo, updatedInfo, isActive } =
    location.state;
  const { auth } = useAuth();

  const links = [
    {
      to: "Genel",
      state: {
        id: location.state.id,
        serialNo: location.state.serialNo,
        deviceName: location.state.deviceName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
        isActive: location.state.isActive,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Genel Bilgiler</p>
      ),
      icon: <MdCoffeeMaker className={`${styles.PageHeaderIcon}`} />,
    },
    ...(auth.role === "admin" || auth.role === "management"
      ? [
          {
            to: "İstatistik",
            state: {
              id: location.state.id,
              serialNo: location.state.serialNo,
              deviceName: location.state.deviceName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
              isActive: location.state.isActive,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>Tüketim</p>
            ),
            icon: <SlGraph className={`${styles.PageHeaderIcon}`} />,
          },
          {
            to: "Rapor",
            state: {
              id: location.state.id,
              serialNo: location.state.serialNo,
              deviceName: location.state.deviceName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
              isActive: location.state.isActive,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>Rapor</p>
            ),
            icon: <TbListDetails className={`${styles.PageHeaderIcon}`} />,
          },
        ]
      : []),
  ];

  const backLink = {
    path: "/Anasayfa/Cihaz",
    icon: <MdOutlineKeyboardBackspace className={`${styles.PageHeaderIcon}`} />,
  };

  return (
    <div className="">
      {/* Device Top Information Close Button */}
      <TopBar links={links} backLink={backLink} />

      <div
        className={`flex w-full 
          mt-[2rem] flex-col justify-between gap-4 
          rounded-xl text-black`}
      >
        <Outlet />
      </div>
      <div
        className=" z-10 md:bg-background  md:w-fit md:rounded-lg md:text-gray-400 
      bg-main text-white w-full max-md:left-0 bottom-0 text-center max-md:h-12
      fixed md:bottom-5 flex gap-4 items-center max-md:justify-around max-md:pt-2 "
      >
        <p className={`${styles.textTitle}`}>{deviceName}</p>
        <p className={`${styles.textTitle} `}>{serialNo}</p>
        <p className={`${styles.textTitle} `}>{isActive ? "Aktif" : "Pasif"}</p>
        <p className={`${styles.textTitle} max-md:hidden`}>
          Oluşturulma({FormatDateHourString(createdInfo)})
        </p>
        <p className={`${styles.textTitle} max-md:hidden  `}>
          Son Güncelleme({FormatDateHourString(updatedInfo)})
        </p>
      </div>
    </div>
  );
}

export default InfoDevicePage;
