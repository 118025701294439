import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

//Icons
import { RiBillLine } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { GoDeviceMobile } from "react-icons/go";
import { HiOutlinePencilAlt, HiOutlineUserGroup } from "react-icons/hi";
import styles from "../../CustomStyles";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import TopBar from "../../Components/Utils/TopBar";
import useAuth from "../../Hooks/useAuth";

function EditFirmPage() {
  const location = useLocation();
  const { auth } = useAuth();

  const { bayserNo, firmName, createdInfo, updatedInfo } = location.state;

  const links = [
    {
      to: "Bilgi",
      state: {
        id: location.state.id,
        bayserNo: location.state.bayserNo,
        firmName: location.state.firmName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Firma Düzenleme</p>
      ),
      icon: <HiOutlinePencilAlt className={`${styles.PageHeaderIcon}`} />,
    },
    ...(auth.role === "admin" ||
    auth.role === "management" ||
    auth.role === "accounting"
      ? [
          {
            to: "Fatura",
            state: {
              id: location.state.id,
              bayserNo: location.state.bayserNo,
              firmName: location.state.firmName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>Faturalar</p>
            ),
            icon: <RiBillLine className={`${styles.PageHeaderIcon}`} />,
          },
        ]
      : []),
    {
      to: "Firmalar",
      state: {
        id: location.state.id,
        bayserNo: location.state.bayserNo,
        firmName: location.state.firmName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Alt Firmalar</p>
      ),
      icon: (
        <SiHomeassistantcommunitystore className={`${styles.PageHeaderIcon}`} />
      ),
    },
    {
      to: "Kullanıcılar",
      state: {
        id: location.state.id,
        bayserNo: location.state.bayserNo,
        firmName: location.state.firmName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Kullanıcılar</p>
      ),
      icon: <HiOutlineUserGroup className={`${styles.PageHeaderIcon}`} />,
    },
    ...(auth.role === "admin" ||
    auth.role === "management" ||
    auth.role === "manufacturer"
      ? [
          {
            to: "Cihazlar",
            state: {
              id: location.state.id,
              bayserNo: location.state.bayserNo,
              firmName: location.state.firmName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>Cihazlar</p>
            ),
            icon: <GoDeviceMobile className={`${styles.PageHeaderIcon}`} />,
          },
        ]
      : []),

    ...(auth.role === "admin"
      ? [
          {
            to: "Sil",
            state: {
              id: location.state.id,
              bayserNo: location.state.bayserNo,
              firmName: location.state.firmName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>
                Firmayı Sil
              </p>
            ),
            icon: <AiFillDelete className={`${styles.PageHeaderIcon}`} />,
          },
        ]
      : []),
  ];
  const backLink = {
    path: "/Anasayfa/Firma",
    icon: <MdOutlineKeyboardBackspace className={`${styles.PageHeaderIcon}`} />,
  };

  return (
    <div>
      {/* Device Top Information Close Button */}
      <TopBar links={links} backLink={backLink} />

      {/* Device Important Info. */}
      <div
        className={`flex w-full 
          flex-col justify-between gap-4 
          rounded-xl text-black`}
      >
        <Outlet />
      </div>
      <div
        className=" z-10 md:bg-[#053B50] md:w-screen md:pl-8 pl-4
      bg-main text-white w-full max-md:left-0 bottom-0 text-center max-md:h-12
      fixed md:bottom-0 md:left-20 flex gap-4 items-center  max-md:pt-2 "
      >
        <p className={`${styles.textTitle}`}>Bayser Numarası : {bayserNo}</p>
        <p className={`${styles.textTitle} `}>{firmName}</p>
        <p className={`${styles.textTitle} max-md:hidden`}>
          Oluşturulma : {FormatDateHourString(createdInfo)}
        </p>
        <p className={`${styles.textTitle} max-md:hidden`}>
          Son Güncelleme : {FormatDateHourString(updatedInfo)}
        </p>
      </div>
    </div>
  );
}

export default EditFirmPage;
