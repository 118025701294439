import { ThreeDots } from "react-loader-spinner";
import styles from "../../../CustomStyles";
import FirmTable from "./FirmTable";

export default function FirmInfoCard({ warn, firms, isLoading, isFetching }) {
  return (
    <div className="flex flex-col p-4 shadow-lg bg-white w-4/6 max-xl:w-full h-80 rounded-lg ">
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="bg-main"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : firms.length === 0 ? (
        <div className="flex w-full h-full row-start-4 col-start-2 items-center justify-center font-SemiBold tracking-widest">
          <p className={`${styles.Bigtext} p-4 rounded-md text-black`}>
            {warn}
          </p>
        </div>
      ) : (
        <>
          <div className=" relative flex flex-col gap-2 mb-4 ml-2">
            <p className="font-SemiBold mt-2 text-2xl tracking-widest text-main z-10">
              Sipariş Vermeyen Firmalar
            </p>
          </div>
          <FirmTable data={firms} />
        </>
      )}
    </div>
  );
}
