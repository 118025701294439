import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";
import {
  useFetchCityQuery,
  useFetchFirmQuery,
  useFetchTownQuery,
} from "../../store";

const isValidEmail = (email) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailPattern.test(email);
};

export default function CreateFirmForm({
  setDesignedInput,
  setInputFieldName,
  setShowConfirmModal,
  token,
  initialFValues,
}) {
  const responseFirm = useFetchFirmQuery(token);
  const responseCity = useFetchCityQuery(token);
  const responseTown = useFetchTownQuery(token);
  const Firms = responseFirm.data?.data?.firms || [];
  const Cities = responseCity.data?.data?.cities || [];
  const Towns = responseTown.data?.data?.towns || [];

  const FirmsOptions = [
    ...Firms?.map((option) => {
      return { title: option.firmName };
    }),
  ];
  const TownOptions = [
    ...Towns?.map((option) => {
      return { title: option.townName };
    }),
  ];
  const CityOptions = [
    ...Cities?.map((option) => {
      return { title: option.cityName };
    }),
  ];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firmName" in fieldValues)
      temp.firmName = fieldValues.firmName ? "" : "Firma seçiniz.";
    if ("email" in fieldValues)
      temp.email = isValidEmail(fieldValues.email)
        ? ""
        : "Doğru bir email adresi girin.";

    if ("officialNumber" in fieldValues)
      temp.officialNumber = fieldValues.officialNumber
        ? ""
        : "Tc numarası giriniz .";
    if ("taxNumber" in fieldValues)
      temp.taxNumber = fieldValues.taxNumber ? "" : "Vergi numarası giriniz .";
    if ("taxOffice" in fieldValues)
      temp.taxOffice = fieldValues.taxOffice ? "" : "Vergi dairesini giriniz .";
    if ("taxNumber" in fieldValues)
      temp.taxNumber = fieldValues.taxNumber ? "" : "Vergi numarası giriniz .";
    if ("tel" in fieldValues)
      temp.tel = fieldValues.tel ? "" : "Telefon girin.";
    if ("town" in fieldValues)
      temp.town = fieldValues.town ? "" : "İlçe seçin.";
    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "Şehir seçin.";
    if ("text" in fieldValues)
      temp.text = fieldValues.text ? "" : "Adres girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setInputFieldName({
        firmName: "Firma İsmi",
        mainFirmName: "Ana Firma İsmi",
        email: "Firma Email",
        tel: "Firma Telefon",
      });
      setShowConfirmModal(true);
      setDesignedInput({
        isActive: values.isActive,
        firmName: values.firmName,
        isCorporate: values.isCorporate,
        mainFirmName: values.mainFirmName,
        officialNumber: values.officialNumber,
        taxNumber: values.taxNumber,
        taxOffice: values.taxOffice,
        email: values.email,
        tel: values.tel,
        note: values.note,
        address: {
          city: values.city,
          town: values.town,
          text: values.text,
        },
        token: token,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <form
      className=" grid gap-4 w-full bg-white p-4 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <div className=" grid md:grid-cols-3 sm:grid-cols-2 gap-4 w-full ">
        <Controls.Checkbox
          label="Aktiflik"
          name="isActive"
          value={values.isActive}
          onChange={handleInputChange}
        />
        <Controls.Checkbox
          label="Ana Firma Yok"
          name="isCorporate"
          value={values.isCorporate}
          onChange={handleInputChange}
          error={errors.isCorporate}
        />
        {!values.isCorporate && (
          <Controls.SelectOptions
            label="Ana Firma"
            name="mainFirmName"
            value={values.mainFirmName}
            onChange={handleInputChange}
            options={FirmsOptions}
            error={errors.mainFirmName}
          />
        )}
        <Controls.Input
          label="Firma İsmi"
          name="firmName"
          value={values.firmName}
          onChange={handleInputChange}
          error={errors.firmName}
        />
        <Controls.Input
          label="Tc No"
          name="officialNumber"
          value={values.officialNumber}
          onChange={handleInputChange}
          error={errors.officialNumber}
        />
        <Controls.Input
          label="Vergi Numarası"
          name="taxNumber"
          value={values.taxNumber}
          onChange={handleInputChange}
          error={errors.taxNumber}
        />
        <Controls.Input
          label="Vergi Dairesi"
          name="taxOffice"
          value={values.taxOffice}
          onChange={handleInputChange}
          error={errors.taxOffice}
        />
        <Controls.TelInput
          label="Telefon Numarası"
          name="tel"
          value={values.tel}
          onChange={handleInputChange}
          error={errors.tel}
        />
        <Controls.Input
          label="Email"
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors.email}
        />
      </div>

      <div className=" grid md:grid-cols-3 sm:grid-cols-2 gap-4 w-full">
        <Controls.Input
          label="Adress"
          name="text"
          value={values.text}
          onChange={handleInputChange}
          error={errors.text}
        />{" "}
        <Controls.SelectOptions
          label="Şehir"
          name="city"
          value={values.city}
          onChange={handleInputChange}
          options={CityOptions}
          error={errors.city}
        />
        <Controls.SelectOptions
          label="İlçe"
          name="town"
          value={values.town}
          onChange={handleInputChange}
          options={TownOptions}
          error={errors.town}
        />
        <Controls.Input
          label="Açıklama"
          name="note"
          value={values.note}
          onChange={handleInputChange}
          error={errors.note}
        />
      </div>

      <button
        className={` z-20 max-md:fixed max-md:bottom-8 w-fit
        max-md:left-1/2 max-md:-translate-x-1/2 md:rounded-md rounded-full 
        max-md:border-l-4 max-md:border-b-4 max-md:border-r-4 max-md:border-white ${styles.button}`}
      >
        <p>Kaydet</p>
      </button>
    </form>
  );
}
