import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";

export default function DeviceCloseForm({ setDesignedInput, initialFValues }) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : !initialFValues.isActive
        ? "Makineyi açma sebebini yazınız."
        : "Makineyi kapatma sebebini yazınız.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setDesignedInput({
        isActive: !values.isActive,
        description: values.description,
        connect: values.connect,
      });
    }
  };

  return (
    <form
      className=" grid  gap-10  w-full items-center justify-center"
      onSubmit={handleSubmit}
    >
      <p className="justify-self-center text-main font-SemiBold text-3xl">
        Makineyi {initialFValues.isActive ? "Kapat" : "Aç"}
      </p>
      <div className=" grid gap-4">
        <Controls.Input
          label="Açıklama"
          name="description"
          value={values.description}
          onChange={handleInputChange}
          error={errors.description}
        />
        {initialFValues.isActive && (
          <Controls.Checkbox
            label="Makine bağlantısı kurulsun mu ?"
            name="connect"
            value={values.connect}
            onChange={handleInputChange}
          />
        )}
      </div>

      <button
        className={` z-20 md:rounded-md rounded-full w-fit justify-self-center
         ${styles.button}`}
      >
        <p>Kaydet</p>
      </button>
    </form>
  );
}
