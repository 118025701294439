import { FormHelperText } from "@mui/material";
import React from "react";

export default function Number(props) {
  const {
    name,
    label,
    error = null,
    value,
    onChange,
    disabled = false,
    min = 0,
    max,
  } = props;

  return (
    <div
      className={`relative flex flex-col gap-1  w-full ${
        disabled ? " opacity-40 pointer-events-none" : ""
      }`}
    >
      <p
        className={`${
          error ? "text-red-400" : ""
        } absolute -top-2 left-2 bg-white px-2 break-all font-[100]`}
      >
        {label}
      </p>
      <input
        className={`${
          error ? "border-red-400 border-1" : ""
        } text-[0.85rem] active:border-blue-400 border-gray-400 bg-transparent border-[1px] rounded-sm px-4 py-4 text-center`}
        type="number"
        value={value}
        name={name}
        step="0.01"
        onChange={onChange}
        aria-label="Quantity Input"
        min={min}
        max={max}
      />
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </div>
  );
}
