import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { useFetchDevicesQuery, useFetchPlayMakerQuery } from "../../store";
import styles from "../../CustomStyles";
import Loader from "../../Components/Utils/Loader";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import ListInfo from "../../Components/Table/ListInfo";
import DeviceTopSearchInfo from "../../Components/SearchBar/DeviceTopSearchBar";
import { GrStatusGoodSmall } from "react-icons/gr";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import DeviceCloseForm from "../../Forms/DeviceForm/DeviceCloseForm";
import { useMakeDeviceOperationMutation } from "../../store/devicesApi";
import Alerts from "../../Components/Utils/Alert";
import DevicePositionPlaymaker from "../../Forms/DeviceForm/DevicePositionPlaymaker";
import DevicePositionFirm from "../../Forms/DeviceForm/DevicePositionFirm";
import DevicePositionStorage from "../../Forms/DeviceForm/DevicePositionStorage";
import DevicePositionService from "../../Forms/DeviceForm/DevicePositionService";

function DevicePage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const ResultDevices = useFetchDevicesQuery(token);
  const DataDevice = ResultDevices.data?.data?.devices || [];
  const inputFieldName = {
    deviceName: "Cihaz İsmi",
    serialNo: "Seri Numarası",
  };
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [operationDetail, setOperationDetail] = useState({});
  useEffect(() => {
    setIsSearch(false);
  }, []);

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOperationDetail({});
    setOpen(false);
  };
  const [makeDeviceOperation, resultMakeDeviceOperation] =
    useMakeDeviceOperationMutation();
  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (device) => (
        <div className="flex flex-row justify-center">
          <NavLink
            to={`Düzenle/Bilgi`}
            state={{
              id: device._id,
              serialNo: device.serialNo,
              deviceName: device.deviceName,
              createdInfo: device.createdInfo,
              updatedInfo: device.updatedInfo,
            }}
          >
            <button className={`${styles.tableButton}`}>
              <BsFillPencilFill className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>
          <NavLink
            to={`Bilgi/Genel`}
            state={{
              id: device._id,
              serialNo: device.serialNo,
              deviceName: device.deviceName,
              createdInfo: device.createdInfo,
              updatedInfo: device.updatedInfo,
              isActive: device.isActive,
            }}
          >
            <button className={`${styles.tableButton}`} onClick={() => {}}>
              <TbReportAnalytics className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>
        </div>
      ),
    },
    {
      label: "Cihaz İsmi",
      render: (device) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Cihaz İsmi:</div>
          {device.deviceName}
        </>
      ),
      sortValue: (device) => device.deviceName,
    },
    {
      label: "Firma",
      render: (device) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Firma İsmi:</div>
          {device.firm?.firmName}
        </>
      ),
      sortValue: (device) => device.firm?.firmName,
    },
    {
      label: "Seri Numarası",
      render: (device) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Seri Numarası :</div>
          {device.serialNo}
        </>
      ),
    },
    {
      label: "Durum",
      render: (device) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Durum:</div>
          {device.deviceStatusName}
        </>
      ),
    },
    {
      label: "Aktiflik",
      render: (device) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Aktiflik:</div>
          {device.isActive ? (
            <span className="relative flex h-3 w-3 items-center">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#4ea529] opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-[#75f140]"></span>
            </span>
          ) : (
            <span className="relative flex h-3 w-3 items-center">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#F96666] opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-[#DF2E38]"></span>
            </span>
          )}
        </>
      ),
    },
    {
      label: "Son Bağlantı",
      render: (device) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Son Bağlantı:</div>
          {FormatDateHourString(device.lastConnectionDate)}
        </>
      ),
    },
    {
      label: "",
      render: (device) => (
        <>
          <Box sx={{ minWidth: 70 }}>
            <FormControl className=" bg-white rounded-md p-" fullWidth>
              <InputLabel
                style={{
                  color: "black",
                  backgroundColor: "white",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  borderRadius: "5px",
                }}
                id="demo-simple-select-label"
              >
                İşlem Yap
              </InputLabel>
              {device.isActive ? (
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={""}
                  label="İşlem Yap"
                  onChange={(event) => {
                    console.log(event.target.value);
                    if (
                      (!device.isActive &&
                        event.target.value !== "Firma" &&
                        event.target.value !== "Depo" &&
                        event.target.value !== "Plasiyer" &&
                        event.target.value !== "Servis") ||
                      device.isActive
                    ) {
                      setOperationDetail({
                        _id: device._id,
                        serialNo: device.serialNo,
                        deviceStatusName: device.deviceStatusName,
                        isActive: device.isActive,
                        operation: event.target.value,
                        firmName: device.firm?.firmName,
                      });
                      handleOpen();
                    }
                  }}
                >
                  <MenuItem value={"Aktiflik"}>
                    Makineyi {device.isActive ? "Kapat" : "Aç"}
                  </MenuItem>
                  <MenuItem value={"Servis"}>Servis Al</MenuItem>
                  <MenuItem value={"Plasiyer"}>Plasiyer Ver</MenuItem>
                  <MenuItem value={"Depo"}>Depoya Gönder</MenuItem>
                  <MenuItem value={"Firma"}>Firmaya Al</MenuItem>
                </Select>
              ) : (
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={""}
                  label="İşlem Yap"
                  onChange={(event) => {
                    setOperationDetail({
                      _id: device._id,
                      serialNo: device.serialNo,
                      deviceStatusName: device.deviceStatusName,
                      isActive: device.isActive,
                      operation: event.target.value,
                      firmName: device.firm?.firmName,
                    });
                    handleOpen();
                  }}
                >
                  <MenuItem value={"Aktiflik"}>
                    Makineyi {device.isActive ? "Kapat" : "Aç"}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Box>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 1000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setConfirm(false);
      setAlert(2);
      console.log(apiResponse.error.data.message);
      setMessage(apiResponse.error.data.message);
    }
    if (apiResponse.isSuccess) {
      setConfirm(false);
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultMakeDeviceOperation, "Cihaz güncellendi !");
  }, [resultMakeDeviceOperation.isSuccess, resultMakeDeviceOperation.isError]);

  return (
    <div className="">
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
            -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" fixed left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 rounded-md border-main border-4 bg-white w-[400px] p-4">
          {operationDetail.operation === "Aktiflik" && (
            <DeviceCloseForm
              initialFValues={{
                isActive: operationDetail.isActive,
                connect: true,
                description: "",
              }}
              setDesignedInput={(values) => {
                console.log(values);
                console.log({ _id: operationDetail._id, ...values, token });
                makeDeviceOperation({
                  operation: "Activity",
                  _id: operationDetail._id,
                  ...values,
                  token,
                });
                handleClose();
              }}
            />
          )}
          {operationDetail.operation === "Plasiyer" && (
            <DevicePositionPlaymaker
              initialFValues={{
                deviceStatusName: "Plasiyer",
                playmaker: "",
                connect: true,
                description: "",
              }}
              token={token}
              setDesignedInput={(values) => {
                console.log(values);
                console.log({ _id: operationDetail._id, ...values, token });
                makeDeviceOperation({
                  operation: "DeviceStatusName",
                  _id: operationDetail._id,
                  ...values,
                  token,
                });
                handleClose();
              }}
            />
          )}
          {operationDetail.operation === "Depo" && (
            <DevicePositionStorage
              initialFValues={{
                deviceStatusName: "Depo",
                firmName: "",
                connect: true,
                description: "",
              }}
              token={token}
              setDesignedInput={(values) => {
                console.log(values);
                console.log({ _id: operationDetail._id, ...values, token });
                makeDeviceOperation({
                  operation: "DeviceStatusName",
                  _id: operationDetail._id,
                  ...values,
                  token,
                });
                handleClose();
              }}
            />
          )}
          {operationDetail.operation === "Servis" && (
            <DevicePositionService
              initialFValues={{
                deviceStatusName: "Servis",
                connect: true,
                description: "",
              }}
              token={token}
              setDesignedInput={(values) => {
                console.log(values);
                console.log({ _id: operationDetail._id, ...values, token });
                makeDeviceOperation({
                  operation: "DeviceStatusName",
                  _id: operationDetail._id,
                  ...values,
                  token,
                });
                handleClose();
              }}
            />
          )}
          {operationDetail.operation === "Firma" && (
            <DevicePositionFirm
              initialFValues={{
                deviceStatusName: "Firma",
                firmName: operationDetail.firmName,
                connect: true,
                description: "",
              }}
              token={token}
              setDesignedInput={(values) => {
                console.log(values);
                console.log({ _id: operationDetail._id, ...values, token });
                makeDeviceOperation({
                  operation: "DeviceStatusName",
                  _id: operationDetail._id,
                  ...values,
                  token,
                });
                handleClose();
              }}
            />
          )}
        </div>
      </Modal>
      <div className=" w-full inline-block align-middle">
        <DeviceTopSearchInfo
          Length={DataDevice.length}
          TopInfo={"Cihaz Listesi"}
          AddButton={() => {
            navigate("Oluştur");
          }}
          SearchBarData={DataDevice}
          HandleSearch={handleSearch}
          SearchInputFieldName={inputFieldName}
        />
        {ResultDevices.isLoading ||
        ResultDevices.isFetching ||
        alert !== 0 ||
        resultMakeDeviceOperation.isLoading ? (
          <div className=" flex w-full h-full justify-center items-center">
            <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
          </div>
        ) : ResultDevices.error || ResultDevices?.data?.results === 0 ? (
          <UnfindDataWarn Data={"Cihaz bulunamadı"} />
        ) : (
          <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 mb-5 overflow-hidden shadow border ">
            <ListInfo
              Config={config}
              Key={"_id"}
              SetPaginationNumber={setPaginationNumber}
              PaginationNumber={paginationNumber}
              IsSearch={isSearch}
              FilteredData={filteredData}
              Data={DataDevice}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DevicePage;
