import React from "react";
import { useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
import Graph from "../../../Components/Graph/DashboardBar";

// Icon
import styles from "../../../CustomStyles";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import { useGetFirmConsumentLogReportQuery } from "../../../store";

export default function DetailDevice() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [active, setActive] = useState(0);
  const [filterType, setFilterType] = useState("today");

  const colorOfProduct = {
    Çay: "#C70039",
    "Filtre Kahve": "#42240C",
    "Türk Kahvesi": "#51C2D5",
  };

  const response = useGetFirmConsumentLogReportQuery({
    id: id,
    type: filterType,
    token: token,
  });
  const sortedHourlyConsumption = response?.data?.data;
  const productTotals = response?.data?.productTotals;

  console.log(sortedHourlyConsumption);
  function createProductDataAndBars(sortedHourlyConsumption) {
    if (!sortedHourlyConsumption) {
      return { productData: [], productBars: [] };
    }

    let ProductBars = new Set();
    let ProductData = [];

    for (let i = 0; i < 24; i++) {
      ProductData[i] = { name: i };
    }

    for (const log of sortedHourlyConsumption) {
      const hourIndex = parseInt(log.createdInfo, 10); // Başındaki sıfırları kaldırarak sayıya dönüştür.
      const productDataItem = { name: log.createdInfo };

      log.consumentInfo.forEach((product) => {
        productDataItem[product.productName] = product.consument;
        ProductBars.add(product.productName);
      });

      ProductData[hourIndex] = productDataItem;
    }

    const productBarsArray = Array.from(ProductBars).map((dataKey) => ({
      dataKey,
      fill: colorOfProduct[dataKey],
    }));

    ProductBars = productBarsArray;

    return { ProductData, ProductBars }; // Burada bir nesne dönüyoruz
  }

  const { ProductData, ProductBars } = createProductDataAndBars(
    sortedHourlyConsumption
  );

  function FilterButton({ button, active }) {
    const activeStyle = "bg-[#fff] text-main border-main";
    const passiveStyle =
      "bg-main text-white border-white hover:bg-white hover:text-main ";
    return (
      <div className="flex md:justify-center gap-2 md:gap-6">
        <button
          onClick={() => {
            setFilterType("today");
            setActive(0);
          }}
          className={`${button} ${styles.text} ${
            active === 0 ? activeStyle : passiveStyle
          }`}
        >
          Bugün
        </button>
        <button
          onClick={() => {
            setFilterType("yesterday");
            setActive(1);
          }}
          className={`${button} ${styles.text} ${
            active === 1 ? activeStyle : passiveStyle
          }`}
        >
          Dün
        </button>
        <button
          onClick={() => {
            setFilterType("week");
            setActive(2);
          }}
          className={`${button} ${styles.text} ${
            active === 2 ? activeStyle : passiveStyle
          }`}
        >
          Haftalık
        </button>
        <button
          onClick={() => {
            setFilterType("thismonth");
            setActive(3);
          }}
          className={`${button} ${styles.text} ${
            active === 3 ? activeStyle : passiveStyle
          }`}
        >
          Aylık
        </button>
      </div>
    );
  }
  return (
    <div
      className={`flex flex-col gap-4 mb-20 md:bg-white rounded-md  md:shadow-md shadow-main py-2`}
    >
      <div className=" flex max-md:flex-col justify-between max-md:gap-4">
        <div className=" flex flex-col ">
          <p className="flex items-center md:ml-6 font-SemiBold text-2xl text-gray-500">
            Tüketim Grafiği
          </p>

          {productTotals?.map((item, index) => (
            <span key={index} className="md:ml-6 text-2xl text-gray-500">
              {item.productName}: {item.total}
            </span>
          ))}
        </div>
        <div className=" md:mr-12">
          <FilterButton
            button={
              " p-2 bg-main border-2 border-main hover:bg-white hover:text-main rounded-md transition-all duration-300 shadow-xl hover:scale-110"
            }
            active={active}
          />
        </div>
      </div>
      {response.isFetching || response.isLoading ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : (
        <div className=" w-full h-[24rem] md:h-[32rem]">
          {response?.data?.results !== 0 ? (
            <Graph data={ProductData || []} bars={ProductBars || []} />
          ) : (
            <div className=" flex items-center justify-center h-full w-full">
              <UnfindDataWarn Data={"Tüketim verisi bulunamadı !"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
