import { useGetFirmTotalConsumentReportQuery } from "../../../store/reportsApi";
import useAuth from "../../../Hooks/useAuth";
import { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Box, Typography, Grid, Button } from "@mui/material";
import {
  useFetchFirmQuery,
  useFetchPlayMakerQuery,
  useFetchProductTypeQuery,
} from "../../../store";
import { CircularProgress } from "@mui/material";
import ReportLayout from "../../../Layout/Reports/ReportLayout";
import Loader from "../../../Components/Utils/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function ReportFirmConsumentTotalPage() {
  const { auth } = useAuth();
  const [playMakerId, setPlayMakerId] = useState("");
  const [productName, setProductName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [firmId, setFirmId] = useState("");
  const [firmName, setFirmName] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [productNameError, setProductNameError] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const token = auth.accessToken;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: playmakers, isLoading: isPlaymakersLoading } =
    useFetchPlayMakerQuery(token);
  const { data: productTypes, isLoading: isProductInfoLoading } =
    useFetchProductTypeQuery(token);
  const { data: firms, isLoading: isFirmsLoading } = useFetchFirmQuery(token);

  useEffect(() => {
    if (productTypes?.data?.productTypes?.length > 0) {
      setProductName(productTypes.data.productTypes[0].productTypeName);
    }
  }, [productTypes]);

  const {
    data: firmConsumentTotalReport,
    isLoading,
    error,
  } = useGetFirmTotalConsumentReportQuery(
    {
      token,
      playMakerId: playMakerId || "undefined",
      productName: productName || "undefined",
      startDate: startDate ? startDate.toISOString() : "undefined",
      endDate: endDate ? endDate.toISOString() : "undefined",
      firmId: firmId || "undefined",
      timeZone: timeZone || "UTC",
    },
    { skip: !shouldFetch }
  );

  console.log(firmConsumentTotalReport);
  useEffect(() => {
    if (firmConsumentTotalReport && firmConsumentTotalReport.data) {
      setParsedData(firmConsumentTotalReport.data);
      setShouldFetch(false);
    }
  }, [firmConsumentTotalReport]);

  const handleSearch = () => {
    if (!productName || !startDate || !endDate) {
      if (!productName) setProductNameError(true);
      return;
    }
    setShouldFetch(true);
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
    setProductNameError(false);
  };

  const handleFirmChange = (event) => {
    const selectedFirm = firms?.data?.firms.find(
      (firm) => firm.firmName === event.target.value.firmName
    );

    if (selectedFirm) {
      setFirmId(selectedFirm._id);
      setFirmName(event.target.value);
    }
  };

  // Dosya adı oluşturma
  const fileName = `Firma_Toplam_Tuketim_Raporu_${
    startDate ? startDate.toISOString().split("T")[0] : ""
  }_${endDate ? endDate.toISOString().split("T")[0] : ""}.xlsx`;

  // Excel verilerini hazırlama
  const excelData = parsedData.map((item) => ({
    "Firma Adı": item.firmName,
    "Ana Firma Adı": item.mainFirmName,
    "Ürün Adı": item.productName,
    "Toplam Tüketim": item.totalConsumption,
  }));

  return (
    <ReportLayout
      title="Firma Toplam Tüketim Raporu"
      fileName={fileName}
      excelData={excelData}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: 800 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="playmaker-select-label">Plasiyer</InputLabel>
              <Select
                labelId="playmaker-select-label"
                id="playmaker-select"
                value={playMakerId}
                onChange={(e) => setPlayMakerId(e.target.value)}
                label="Plasiyer"
                disabled={isPlaymakersLoading}
              >
                <MenuItem value="" disabled>
                  Plasiyer
                </MenuItem>
                {isPlaymakersLoading ? (
                  <MenuItem value="">
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : (
                  playmakers?.data?.playmakers?.map((playmaker) => (
                    <MenuItem key={playmaker.id} value={playmaker.id}>
                      {playmaker.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={productNameError}>
              <InputLabel id="product-select-label">Ürün Adı</InputLabel>
              <Select
                labelId="product-select-label"
                id="product-select"
                value={productName}
                onChange={handleProductNameChange}
                label="Ürün Adı"
                disabled={isProductInfoLoading}
              >
                {isProductInfoLoading ? (
                  <MenuItem value="">
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : (
                  productTypes?.data?.productTypes?.map((product) => (
                    <MenuItem key={product.id} value={product.productTypeName}>
                      {product.productTypeName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Başlangıç Tarihi"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Bitiş Tarihi"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="firm-select-label">Firma</InputLabel>
              <Select
                labelId="firm-select-label"
                id="firm-select"
                value={firmName}
                onChange={handleFirmChange}
                label="Firma"
                disabled={isFirmsLoading}
              >
                {isFirmsLoading ? (
                  <MenuItem value="">
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : (
                  firms?.data?.firms?.map((firm) => (
                    <MenuItem key={firm.id} value={firm}>
                      {firm.firmName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ minWidth: 200, borderRadius: 2 }}
          disabled={!productName || !startDate || !endDate}
        >
          Raporu Getir
        </Button>
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: 4,
          }}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </Box>
      )}
      {error && (
        <Typography align="center" color="error">
          Hata: {error.message}
        </Typography>
      )}
      {(!isLoading || !error) && parsedData.length > 0 && (
        <Box sx={{ overflowX: "auto" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Firma Adı</th>
                <th style={tableHeaderStyle}>Ana Firma Adı</th>
                <th style={tableHeaderStyle}>Ürün Adı</th>
                <th style={tableHeaderStyle}>Toplam Tüketim</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr
                  key={index}
                  style={index % 2 === 0 ? {} : alternateRowStyle}
                >
                  <td style={tableCellStyle}>{item.firmName}</td>
                  <td style={tableCellStyle}>{item.mainFirmName}</td>
                  <td style={tableCellStyle}>{item.productName}</td>
                  <td style={tableCellStyle}>{item.totalConsumption}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </ReportLayout>
  );
}

const tableHeaderStyle = {
  backgroundColor: "#222831",
  color: "white",
  padding: "12px",
  textAlign: "center",
  fontWeight: "bold",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
};

const alternateRowStyle = {
  backgroundColor: "#f0f0f0",
};
