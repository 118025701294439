import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import useAuth from "../../Hooks/useAuth";
import { useFetchFirmQuery } from "../../store";
import styles from "../../CustomStyles";
import Loader from "../../Components/Utils/Loader";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import ListInfo from "../../Components/Table/ListInfo";
import TopSearchInfo from "../../Components/SearchBar/TopSearchInfo";
import { VscDebugBreakpointData } from "react-icons/vsc";

function FirmPage() {
  const navigate = useNavigate();

  const { auth } = useAuth();
  const token = auth.accessToken;
  const [paginationNumber, setPaginationNumber] = useState(1);
  const inputFieldName = {
    firmName: "Firma İsmi",
    mainFirmName: "Ana Firma İsmi",
    bayserNo: "Bayser Numarası",
  };
  const ResultFirms = useFetchFirmQuery(token);

  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState();

  const DataFirm = ResultFirms?.data?.data?.firms || [];

  useEffect(() => {
    setIsSearch(false);
  }, []);

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (data) => (
        <div className="flex flex-row justify-center">
          <NavLink
            to={`Düzenle/Bilgi`}
            state={{
              id: data._id,
              firmName: data.firmName,
              bayserNo: data.bayserNo,
              createdInfo: data.createdInfo,
              updatedInfo: data.updatedInfo,
            }}
          >
            <button className={`${styles.tableButton} `}>
              <BsFillPencilFill className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>

          <NavLink
            to={`Bilgi/Genel`}
            state={{
              id: data._id,
              firmName: data.firmName,
              bayserNo: data.bayserNo,
              isActive: data.isActive,
              createdInfo: data.createdInfo,
              updatedInfo: data.updatedInfo,
            }}
          >
            <button className={` ${styles.tableButton}  `}>
              <TbReportAnalytics className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>
        </div>
      ),
    },
    {
      label: " Bayser No",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Bayser No:</div>
          {data.bayserNo}
        </>
      ),
      sortValue: (data) => data.bayserNo,
    },
    {
      label: "Ana Firma",
      render: (data) => (
        <div>
          <div className=" md:hidden opacity-40 font-Bold">Ana Firma:</div>

          {data.mainFirm?.firmName === undefined ||
          data.mainFirm?.firmName === ""
            ? "Ana Firma"
            : data.mainFirm?.firmName}
        </div>
      ),
      sortValue: (data) => data.mainFirmName,
    },
    {
      label: " Firma İsmi",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Firma İsmi:</div>
          {data.firmName}
        </>
      ),
      sortValue: (data) => data.name,
    },
    {
      label: "İletişim",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">İletişim:</div>
          <div className=" flex flex-col">
            <div>{data.email}</div>
            <div>{data.tel}</div>
          </div>
        </>
      ),
    },
    {
      label: "Adres",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Adres:</div>

          <div className=" flex flex-col">
            <div>{data.address.city}</div>
            <div>{data.address.town}</div>
          </div>
        </>
      ),
    },
    {
      label: "Durum",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Aktivite:</div>
          {data.isActive ? (
            <VscDebugBreakpointData
              className={` text-green-500 xl:w-8 xl:h-8 md:w-7 md:h-7 w-5 h-5 md:mx-auto transition-all duration-500`}
            />
          ) : (
            <VscDebugBreakpointData
              className={` text-red-500 xl:w-8 xl:h-8 md:w-7 md:h-7 w-5 h-5 mx-auto transition-all duration-500`}
            />
          )}
        </>
      ),
      sortValue: (data) => data.isActive,
    },
  ];

  const keyFn = (data) => {
    return data.id;
  };

  return (
    <div className=" w-full inline-block align-middle">
      <TopSearchInfo
        Length={DataFirm.length}
        TopInfo={"Firma Listesi"}
        AddButton={() => {
          navigate("Oluştur");
        }}
        SearchBarData={DataFirm}
        HandleSearch={handleSearch}
        SearchInputFieldName={inputFieldName}
      />
      {ResultFirms.isLoading || ResultFirms.isFetching ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : ResultFirms?.data?.results === 0 || ResultFirms.isError === true ? (
        <UnfindDataWarn Data={"Firma bulunamadı !"} />
      ) : (
        <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 mb-5 overflow-hidden shadow border ">
          <ListInfo
            Config={config}
            Key={"_id"}
            SetPaginationNumber={setPaginationNumber}
            PaginationNumber={paginationNumber}
            IsSearch={isSearch}
            FilteredData={filteredData}
            Data={DataFirm}
          />
        </div>
      )}
    </div>
  );
}

export default FirmPage;
