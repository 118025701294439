import { useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { NavLink, useLocation } from "react-router-dom";

// Icon
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";

//Store
import { useGetDeviceByFirmIDQuery } from "../../../store";

//Style
import styles from "../../../CustomStyles";

//Components
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import DataSearchBar from "../../../Components/SearchBar/DataSearchBar";
import ListInfo from "../../../Components/Table/ListInfo";
import FormatDateHourString from "../../../Components/Utils/FormatDateHourString";
import Syncronization from "./Syncronization";

function ControlDevice() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [choice, setChoice] = useState(0);
  const inputFieldName = {
    serialNo: "Seri Numarası",
    deviceName: "Cihaz İsmi",
  };

  const responseDatas = useGetDeviceByFirmIDQuery({ id, token });

  const HandleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };
  const DataDevice = responseDatas.data?.data?.devices || [];

  const config = [
    {
      class: "w-4",
      label: "Detay",
      render: (data) => (
        <div className="flex flex-row justify-center">
          <NavLink
            to={`/Anasayfa/Cihaz/Düzenle/Bilgi`}
            state={{
              id: data._id,
              serialNo: data.serialNo,
              deviceName: data.deviceName,
              createdInfo: data.createdInfo,
              updatedInfo: data.updatedInfo,
            }}
          >
            <button className={`${styles.tableButton}`}>
              <BsFillPencilFill className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>
          <NavLink
            to={`/Anasayfa/Cihaz/Bilgi/Genel`}
            state={{
              id: data._id,
              serialNo: data.serialNo,
              deviceName: data.deviceName,
              createdInfo: data.createdInfo,
              updatedInfo: data.updatedInfo,
              isActive: data.isActive,
            }}
          >
            <button className={`${styles.tableButton}`}>
              <TbReportAnalytics className={`${styles.buttonIcon}`} />
            </button>
          </NavLink>
        </div>
      ),
    },
    {
      label: "Cihaz İsmi",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Cihaz İsmi:</div>
          {data.deviceName}
        </>
      ),
      sortValue: (data) => data.name,
    },
    {
      label: "Durum",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Durum:</div>
          {data.deviceStatusName}
        </>
      ),
      sortValue: (data) => data.deviceStatusName,
    },
    {
      label: "Seri No",
      render: (data) => (
        <div className=" flex flex-col">
          <div className=" md:hidden opacity-40 font-Bold">Seri No:</div>
          {data.serialNo}
        </div>
      ),
    },
    {
      label: "Kota Bilgileri",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Kota Bilgileri:</div>
          <div className=" flex flex-col">
            {data.productInfo.productInfo.map((item) => {
              return (
                <div className=" flex justify-center gap-1">
                  <p className=" font-Medium">{item.productName} Kota:</p>

                  <p>{item.quota}</p>
                </div>
              );
            })}
          </div>
        </>
      ),
    },

    {
      label: "Aktivite",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Aktivite:</div>
          {data.isActive ? "Aktif" : "Pasif"}
        </>
      ),
      sortValue: (data) => data.isActive,
    },
    {
      label: "Son Bağlantı",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Son Bağlantı:</div>
          {FormatDateHourString(data.lastConnectionDate)}
        </>
      ),
    },
  ];

  return (
    <div className=" grid w-full">
      <div className=" fixed md:bottom-0 max-md:bottom-4 max-md:left-1/2 max-md:-translate-x-1/2 md:right-5 z-40 flex items-center gap-3 bg-transparent mb-6 ">
        <button
          onClick={() => setChoice(0)}
          className={`${
            choice === 0 ? "text-white bg-main" : "text-main bg-white"
          } ${
            styles.textTitle
          } max-md:border-l-4 max-md:border-b-4 max-md:border-r-4  max-md:shadow-md max-md:shadow-white border-main border-2 transition-all duration-500 p-2 rounded-md font-SemiBold tracking-wider z-20 cursor-pointer`}
        >
          <p>Cihazlar </p>
        </button>
        <p className="text-main z-20 max-md:hidden"> | </p>
        <button
          onClick={() => setChoice(1)}
          className={`${
            choice === 1 ? "text-white bg-main" : "text-main bg-white"
          } ${
            styles.textTitle
          }  max-md:border-l-4 max-md:border-b-4 max-md:border-r-4 max-md:shadow-md max-md:shadow-white border-main border-2 transition-all duration-500 p-2 rounded-md font-SemiBold tracking-wider z-20 cursor-pointer`}
        >
          <p>Senkronizasyon </p>
        </button>
      </div>
      {responseDatas.isFetching || responseDatas.isLoading ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : responseDatas.error ||
        responseDatas?.data?.data?.devices?.length === 0 ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10`}
        >
          <UnfindDataWarn Data={"Cihaz bulunamadı !"} />
        </div>
      ) : (
        <>
          <div className={` mb-12 mt-12 ${choice === 0 ? "" : "hidden"}`}>
            <div
              className={`bg-white w-full p-10  rounded-xl shadow-xl transition-all duration-300 mb-4 block`}
            >
              <DataSearchBar
                Data={DataDevice}
                handleSearch={HandleSearch}
                inputFieldName={inputFieldName}
              />
            </div>
            <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 mb-20 overflow-hidden shadow border ">
              <ListInfo
                Config={config}
                Key={"_id"}
                SetPaginationNumber={setPaginationNumber}
                PaginationNumber={paginationNumber}
                IsSearch={isSearch}
                FilteredData={filteredData}
                Data={DataDevice}
              />
            </div>
          </div>
          <div className={`mb-20 mt-12 ${choice === 1 ? "" : "hidden"}`}>
            <Syncronization />
          </div>
        </>
      )}
    </div>
  );
}

export default ControlDevice;
