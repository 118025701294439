import React, { useState, useEffect } from "react";
import Alerts from "../../../Components/Utils/Alert";
import { TbEdit } from "react-icons/tb";
import useAuth from "../../../Hooks/useAuth";
import styles from "../../../CustomStyles";
import { useLocation } from "react-router-dom";
import {
  useGetSyncByFirmIDQuery,
  useUpdateFirmSyncMutation,
} from "../../../store";
import Confirm from "../../../Components/Utils/Confirm";
import SyncronizationForm from "../../../Forms/FirmForm/SyncronizationForm";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";

export default function Syncronization() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [designModel, setDesignModel] = useState(false);
  const [action, setAction] = useState(0); // 1- add
  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [paginationNumber, setPaginationNumber] = useState(1);

  const [input, setInput] = useState({
    productName: "",
    quota: 0,
    quotaWarning: 0,
    quotaMax: 0,
    productPrice: 0,
    syncLevel: 0,
    id: id,
    token: token,
  });
  const inputFieldName = {
    productName: "Ürün İsmi",
    quota: "Kota",
    quotaWarning: "Kota Uyarı Seviyesi",
    quotaMax: "Maximum Kota",
    productPrice: "Fiyat",
    syncLevel: "Senkronizasyon Seviyesi",
  };

  const responseDatas = useGetSyncByFirmIDQuery({ id, token });
  const [update, resultUpdate] = useUpdateFirmSyncMutation();
  const Datas = responseDatas.data?.data?.productInfoes || [];
  const quota = responseDatas.data?.data?.quotaArray || [];

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setAlert(2);
      setMessage(apiResponse.data?.message);
      setInput((prevInput) => ({
        ...prevInput,
        token: token,
      }));
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
      window.location.reload();
    }
  };

  useEffect(() => {
    handleApiResponse(resultUpdate, "Senkronizasyon bilgileri güncellendi !");
  }, [resultUpdate]);

  const handleModal = (e) => {
    setShowConfirmModal(true);
    setInput(e);
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      update({ ...input, token, id });
      setAction(0);
      setInput({
        productName: "",
        productPrice: 0,
        quotaWarning: 0,
        quotaMax: 0,
        syncLevel: 0,
        token: token,
      });
      setDesignModel(false);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
    }
  };

  const DesignModel = () => {
    return (
      <SyncronizationForm
        handleModal={handleModal}
        initialFValues={{
          productName: input.productName,
          productPrice: input.productPrice,
          quotaMax: input.quotaMax,
          quotaWarning: input.quotaWarning,
          syncLevel: input.syncLevel,
          quota: input.quota,
        }}
      />
    );
  };

  const filteredDatas = Datas?.filter((Datas, index) => {
    if (
      index >= paginationNumber * 10 - 10 &&
      index <= paginationNumber * 10 - 1
    )
      return Datas;
  });

  const content = filteredDatas?.map((Datas, index) => {
    return (
      <div
        key={index}
        className={`bg-white shadow-md shadow-black rounded-xl transition-all relative w-full`}
      >
        <div
          className={`${styles.cardTitle} text-center w-full p-4 rounded-t-xl bg-main text-white `}
        >
          <p>{Datas.productName} Cihazı Senkronizasyon Bilgisi</p>
        </div>
        <div className=" p-4 flex flex-col justify-center items-center">
          <div className=" flex flex-col gap-4">
            <div className=" flex gap-2">
              <p className={`${styles.textTitle} `}>Kota:</p>
              <p className={`${styles.textTitle} `}>
                {quota.map((item) => {
                  if (item.name === Datas.productName)
                    return parseInt(item.quota) + parseInt(Datas.quota);
                })}
              </p>
            </div>
            <div className=" flex gap-2">
              <p className={`${styles.textTitle} `}>Dağıtılmamış Kota:</p>
              <p className={`${styles.textTitle} `}>{Datas.quota}</p>
            </div>
            <div className=" flex gap-2">
              <p className={`${styles.textTitle} `}>Fiyat:</p>
              <p className={`${styles.textTitle} `}>{Datas.productPrice}</p>
            </div>
            <div className=" flex gap-2">
              <p className={`${styles.textTitle} `}>Kota Tehlike Seviyesi:</p>
              <p className={`${styles.textTitle} `}>{Datas.quotaWarning}</p>
            </div>
            <div className=" flex gap-2">
              <p className={`${styles.textTitle} `}>Kota Maximum Seviyesi:</p>
              <p className={`${styles.textTitle} `}>{Datas.quotaMax}</p>
            </div>

            <div className=" flex gap-2">
              <p className={`${styles.textTitle} `}>Senkronizasyon Seviyesi:</p>
              <p className={`${styles.textTitle}`}>{Datas.syncLevel}</p>
            </div>
          </div>
          <div className=" text-center gap-4 mt-4 rounded-md border-2 border-main w-fit p-[4px]">
            <TbEdit
              onClick={() => {
                setInput({
                  ...input,
                  productPrice: Datas.productPrice,
                  productName: Datas.productName,
                  quota: Datas.quota,
                  quotaWarning: Datas.quotaWarning,
                  quotaMax: Datas.quotaMax,
                  syncLevel: Datas.syncLevel,
                  token: token,
                  id: id,
                });
                setDesignModel(true);
                setAction(1);
              }}
              className=" w-8 h-8 rounded-md border-2 border-main hover:text-slate-400 hover:border-slate-400 text-main cursor-pointer"
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={` mb-20 flex flex-col md:mr-10 md:ml-0 `}>
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />
      {responseDatas.isLoading || responseDatas.isFetching ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : responseDatas.isError || Datas?.length === 0 ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-fit justify-center items-center`}
        >
          <UnfindDataWarn Data={"Senkronizasyon ayarları bulunamadı !"} />
        </div>
      ) : (
        <div className=" grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-8 ">
          <>{content}</>
        </div>
      )}
      {designModel && (
        <>
          <div
            onClick={() => {
              setInput({
                productName: "",
                productPrice: 0,
                quotaWarning: 0,
                quotaMax: 0,
                syncLevel: 0,
              });
              setDesignModel(false);
              setAction(0);
            }}
            className="fixed inset-0 z-40 bg-gray-300 opacity-80 "
          ></div>
          <div
            className={`fixed z-50 top-1/2 -translate-x-1/2  left-1/2 -translate-y-1/2 overflow-y-scroll no-scrollbar 
           md:w-fit max-md:w-[300px] `}
          >
            {designModel && <DesignModel />}
          </div>
        </>
      )}
    </div>
  );
}
