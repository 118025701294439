import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";

export default function SyncronizationForm({ handleModal, initialFValues }) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("quotaWarning" in fieldValues)
      temp.quotaWarning = fieldValues.quotaWarning
        ? ""
        : "Kota uyarı seviyesi girin.";
    if ("quotaMax" in fieldValues)
      temp.quotaMax = fieldValues.quotaMax
        ? ""
        : "Maximum kota seviyesi girin.";
    if ("syncLevel" in fieldValues)
      temp.syncLevel = fieldValues.syncLevel
        ? ""
        : "Senkronizasyon seviyesi girin.";
    if ("productPrice" in fieldValues)
      temp.productPrice = fieldValues.productPrice ? "" : "Fiyat girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleModal({
        quotaWarning: values.quotaWarning,
        quotaMax: values.quotaMax,
        syncLevel: values.syncLevel,
        productPrice: values.productPrice,
        productName: values.productName,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <form
      className=" grid gap-4 w-full border-main border-4 bg-white p-4 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <div className=" flex flex-col gap-4 w-full ">
        <Controls.Input
          label="Ürün İsmi"
          name="productName"
          disabled={true}
          value={values.productName}
          onChange={handleInputChange}
          error={errors.productName}
        />
        <Controls.Number
          label="Fiyat"
          name="productPrice"
          min="1.0"
          max="20.0"
          value={values.productPrice}
          onChange={handleInputChange}
          error={errors.productPrice}
        />
        <Controls.Number
          label="Maximum Kota"
          name="quotaMax"
          min="500"
          max="20000"
          value={values.quotaMax}
          onChange={handleInputChange}
          error={errors.quotaMax}
        />
        <Controls.Number
          label="Kota Uyarı Seviyesi"
          name="quotaWarning"
          min="100"
          max="5000"
          value={values.quotaWarning}
          onChange={handleInputChange}
          error={errors.quotaWarning}
        />
        <Controls.Number
          label="Senkronizasyon Seviyesi"
          name="syncLevel"
          min="100"
          max="5000"
          value={values.syncLevel}
          onChange={handleInputChange}
          error={errors.syncLevel}
        />
      </div>

      <button className={` bg-main rounded-md p-4 text-white `}>
        <p>Kaydet</p>
      </button>
    </form>
  );
}
