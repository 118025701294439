import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";

const devicesApi = createApi({
  reducerPath: "devices",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  tagTypes: ["Devices"],
  endpoints(builder) {
    return {
      uploadVideo: builder.mutation({
        query: (input) => {
          const formData = new FormData();
          formData.append("video", input.videoFile); // 'videoFile' input objesindeki video dosyası olmalı

          return {
            url: `/devices/uploadVideo/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
              // 'Content-Type' başlığını eklemeyin, tarayıcı otomatik olarak doğru değeri ayarlar.
            },
            body: formData,
          };
        },
        invalidatesTags: ["Devices"],
      }),
      uploadManualQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/uploadManualQuota`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              data: input.data,
              newQuota: input.quota,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      uploadManualQR: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/uploadManualQR`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              data: input.data,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      updateSetting: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/uploadSetting/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              data: input.data,
              type: input.type,
              key: input.key,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      dowloadSetting: builder.query({
        query: (input) => {
          return {
            url: `/devices/downloadSetting/${input.id}/${input.type}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      updateDeviceQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/updateCounterQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      makeDeviceOperation: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/makeDeviceOperation/${input._id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      updateDeviceInformation: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/${input._id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      deleteDevice: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/${input.id}`,
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              password: input.password,
              passwordConfirm: input.passwordConfirm,
            },
          };
        },
        invalidatesTags: ["Devices"],
      }),
      getDevice: builder.query({
        query: (input) => {
          return {
            url: `/devices/${input.id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
        providesTags: ["Devices"],
      }),
      fetchDevices: builder.query({
        query: (token) => {
          return {
            url: "/devices",
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Devices"],
      }),
      getQuotaToLoadedCode: builder.query({
        query: (input) => {
          return {
            url: `/devices/getQuotaCanLoadToDevice/${input.id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
      }),
    };
  },
});

export const {
  useMakeDeviceOperationMutation,
  useUpdateDeviceInformationMutation,
  useFetchDevicesQuery,
  useGetDeviceQuery,
  useUpdateSettingMutation,
  useDowloadSettingQuery,
  useDeleteDeviceMutation,
  useGetQuotaToLoadedCodeQuery,
  useUpdateDeviceQuotaMutation,
  useUploadVideoMutation,
  useUploadManualQRMutation,
  useUploadManualQuotaMutation,
} = devicesApi;
export { devicesApi };
