import React from "react";
import { TextField } from "@mui/material";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled = false,
  } = props;
  return (
    <TextField
      className=" w-full"
      disabled={disabled}
      variant="outlined"
      label={label}
      name={name}
      value={value || ""}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
    />
  );
}
