import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
// Icon
import { useGetFirmByIDQuery } from "../../../store";
import styles from "../../../CustomStyles";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";

function InfoFirmPage() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const ResultMainFirm = useGetFirmByIDQuery({ id, token });
  const Data = ResultMainFirm?.data?.data?.firm || [];
  const [input, setInput] = useState({
    name: "",
    mainFirmID: "",
    mainFirmName: "",
    isActive: "",
    officialID: "",
    taxNumber: "",
    taxOffice: "",
    isCorporate: "",
    tel: "",
    email: "",
    address: { city: "", town: "", text: "" },
    playMakers: [],
  });

  useEffect(() => {
    if (ResultMainFirm.status === "fulfilled") setInput({ ...Data });
  }, [ResultMainFirm]);

  return (
    <>
      {ResultMainFirm.isLoading ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : ResultMainFirm.error || ResultMainFirm?.data?.results === 0 ? (
        <UnfindDataWarn Data={"Cihaz bulunamadı"} />
      ) : (
        <>
          <div className=" flex flex-col gap-4 bg-white px-2 py-4 rounded-md shadow-md  h-full transition-all duration-300">
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 h-fit">
              {input.officialID && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>TC No</p>
                  <p className={`${styles.inputTag}`}>{input.officialID}</p>
                </div>
              )}
              {input.taxNumber && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Vergi Numarası</p>
                  <p className={`${styles.inputTag}`}>{input.taxNumber}</p>
                </div>
              )}
              {input.taxOffice && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Vergi Dairesi</p>
                  <p className={`${styles.inputTag}`}>{input.taxOffice}</p>
                </div>
              )}
              {input.tel && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Telefon Numarası</p>
                  <p className={`${styles.inputTag}`}>{input.tel}</p>
                </div>
              )}
              {input.email && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Email</p>
                  <p className={`${styles.inputTag}`}>{input.email}</p>
                </div>
              )}
              {input.address && input.address.city && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Şehir</p>
                  <p className={`${styles.inputTag}`}>{input.address.city}</p>
                </div>
              )}
              {input.address && input.address.town && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>İlçe</p>
                  <p className={`${styles.inputTag}`}>{input.address.town}</p>
                </div>
              )}
            </div>
            <div className=" grid sm:grid-cols-2 grid-cols-1 gap-10 h-fit">
              {input.address && input.address.text && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Adres</p>
                  <p className={`${styles.inputTag} h-fit`}>
                    {input.address.text}
                  </p>
                </div>
              )}
              {input.note && (
                <div className="flex flex-col justify-between">
                  <p className={`${styles.tagText} mb-3`}>Not</p>
                  <p className={`${styles.inputTag} h-fit`}>{input.note}</p>
                </div>
              )}
            </div>
            {input?.playMakers?.length !== 0 && (
              <>
                <p className={`${styles.tagText}`}>Plasiyer</p>
                <div className=" grid lg:grid-cols-2 grid-cols-1 gap-10 h-fit">
                  <div className=" grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2 pr-16 items-center">
                    {input?.playMakers?.map((item) => {
                      return (
                        <>
                          <p className={`py-2 w-full ${styles.inputTag}`}>
                            {item.name}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default InfoFirmPage;
