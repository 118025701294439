import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";

// Icon
import { TiDeleteOutline } from "react-icons/ti";
import {
  useDeleteBillMutation,
  useGetBillsQuery,
  useGetFirmProductsQuery,
  useUpdateGiveFirmProductQuotaMutation,
  useUpdateTakeBackFirmProductQuotaMutation,
} from "../../../store";
import { useLocation } from "react-router-dom";
import styles from "../../../CustomStyles";
import Alerts from "../../../Components/Utils/Alert";
import Confirm from "../../../Components/Utils/Confirm";
import ListInfo from "../../../Components/Table/ListInfo";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";
import DataSearchBar from "../../../Components/SearchBar/DataSearchBar";
import DesignModal from "../../../Components/Utils/DesignModal";
import BillQuotaDesignForm from "../../../Forms/FirmForm/BillQuotaDesignForm";
import FormatDateHourString from "../../../Components/Utils/FormatDateHourString";

function UpdateBill() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [giveQuota, setQuotaInfo] = useState({ quota: 0, productTypeName: "" });
  const [deleteInput, setDeleteInput] = useState("");
  const [inputFieldName, setInputFieldName] = useState({
    billNo: "Fatura Numarası",
  });
  const [infoText, setInfoText] = useState("");
  const [giveQuotaDesignModal, setGiveQuotaDesignModal] = useState(false);
  const [takeBackQuotaDesignModal, setTakeBackQuotaDesignModal] =
    useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [action, setAction] = useState(0); // 1- add 2-update 3-delete 0- default

  const [Delete, resultDelete] = useDeleteBillMutation();
  const [UpdateTakeback, resultUpdateTakeback] =
    useUpdateTakeBackFirmProductQuotaMutation();
  const [UpdateGive, resultUpdateGive] =
    useUpdateGiveFirmProductQuotaMutation();
  const responseDatas = useGetBillsQuery({ id, token });
  const DataBill = responseDatas.data?.data?.bills || [];
  const responseProducts = useGetFirmProductsQuery({ id, token });
  const ProductDatas = responseProducts.data?.data || [];

  const HandleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  useEffect(() => {
    setIsSearch(false);
  }, []);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 3000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setAlert(2);
      setMessage(apiResponse.error.data?.message);
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
      responseDatas.refetch();
    }
  };

  useEffect(() => {
    handleApiResponse(resultDelete, "Fatura başarıyla silindi !");
  }, [resultDelete]);

  useEffect(() => {
    handleApiResponse(resultUpdateGive, "Bedelsiz fatura başarıyla eklendi !");
  }, [resultUpdateGive]);

  useEffect(() => {
    handleApiResponse(
      resultUpdateTakeback,
      "Bedelsiz fatura başarıyla eklendi !"
    );
  }, [resultUpdateTakeback]);

  const HandleModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setGiveQuotaDesignModal(false);
      setTakeBackQuotaDesignModal(false);

      if (action === 1) {
        UpdateGive({ ...giveQuota, token, id });
      } else if (action === 2) {
        const Object = { id: deleteInput.id, token: deleteInput.token };
        Delete(Object);
        setInfoText("Faturayı silmek istediğinize emin misiniz ?");
      } else if (action === 3) {
        UpdateTakeback({ ...giveQuota, id, token });
      }
      setShowConfirmModal(false);
      setIsSearch(false);
      setAction(0);
    } else {
      setShowConfirmModal(false);
      setAction(0);
    }
  };

  const config = [
    {
      label: " Sil",
      render: (data) => (
        <button
          onClick={(e) => {
            setAction(2);
            setDeleteInput({
              id: data._id,
              token: token,
              billNo: data.billNo,
              message: "Faturayı silmek isteğinize emin misiniz ?",
            });
            HandleModal();
          }}
          className=" hover:text-slate-400 text-main transition-all duration-500"
        >
          <TiDeleteOutline className={`${styles.buttonIcon}`} />
        </button>
      ),
    },
    {
      label: "İşlemi Yapan",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">İşlemi Yapan:</div>
          {data.billerName}
        </>
      ),
      sortValue: (data) => data.billNo,
    },
    {
      label: " Fatura No",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Fatura No:</div>
          {data.billNo}
        </>
      ),
      sortValue: (data) => data.billNo,
    },
    {
      label: "Ürün İsmi",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün İsmi:</div>
          {data.productName}{" "}
        </>
      ),
      sortValue: (data) => data.productName,
    },
    {
      label: "Miktar",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Miktar:</div>
          {data.quota}
        </>
      ),
      sortValue: (data) => data.quota,
    },

    /**
    {
      label: "Gelir",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Gelir:</div>
          {data.income}
        </>
      ),
      sortValue: (data) => data.income,
    },
    {
      label: "Birim Fiyat",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Birim Fiyat:</div>
          {data.price.toFixed(2)}
        </>
      ),
      sortValue: (data) => data.price.toFixed(2),
    },   */

    {
      label: "Tarih",
      render: (data) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Tarih:</div>
          {FormatDateHourString(data.createdInfo)}
        </>
      ),
      sortValue: (data) => data.createdInfo,
    },
    {
      label: "Fatura Tipi",
      render: (data) => (
        <div className={` p-2 text-center `}>
          <p
            style={{
              backgroundColor: `${
                data.billType === "Bill"
                  ? "#A6FF96"
                  : data.billType === "FreeUpload"
                  ? "#EBEF95"
                  : data.billType === "GetReturn"
                  ? "#FFC8C8"
                  : "#FF6969"
              }`,
            }}
            className=" rounded-md shadow-md p-2"
          >
            {data.billType === "Bill"
              ? "Ekleme"
              : data.billType === "FreeUpload"
              ? "Bedelsiz Yükleme"
              : data.billType === "GetReturn"
              ? "İade Alma"
              : "Silme"}
          </p>
        </div>
      ),
    },
  ];

  const DesignTag = () => {
    if (!giveQuotaDesignModal)
      return (
        <BillQuotaDesignForm
          HandleModal={(input) => {
            setQuotaInfo(input);
            setShowConfirmModal(true);
            setAction(3);
            setGiveQuotaDesignModal(false);
            setInputFieldName({
              productName: "Ürün İsmi",
              quota: "Düşürülecek Kota",
            });
            setInfoText("Bu kota firmadan düşürülecek bundan emin misiniz ?");
          }}
          initialFValues={{ productName: "", quota: 0 }}
          Token={token}
          id={id}
          type="Kota İade"
          ProductDatas={ProductDatas}
        />
      );
    else
      return (
        <BillQuotaDesignForm
          HandleModal={(input) => {
            setQuotaInfo(input);
            setShowConfirmModal(true);
            setAction(1);
            setTakeBackQuotaDesignModal(false);
            setInputFieldName({
              productName: "Ürün İsmi",
              quota: "Yüklenecek Kota",
            });
            setInfoText("Bu kota firmaya yüklenecek bundan emin misiniz ?");
          }}
          initialFValues={{ productName: "", quota: 0 }}
          Token={token}
          id={id}
          type="Kota Yükleme"
          ProductDatas={ProductDatas}
        />
      );
  };

  return (
    <div className=" grid w-full mb-20">
      {alert !== 0 && (
        <div
          className="fixed z-10 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={action === 1 ? giveQuota : {}}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />
      <div className="  z-30 fixed md:bottom-5 max-md:bottom-9 max-md:left-1/2 max-md:-translate-x-1/2 md:right-5 grid gap-4 grid-cols-2 ">
        <button
          onClick={() => setGiveQuotaDesignModal(true)}
          className={`${styles.buttonSearch} w-fit h-fit  max-md:border-l-4 max-md:border-b-4 max-md:border-r-4 max-md:border-white`}
        >
          <p className=" max-md:hidden">Kota </p>
          <p>Bedelsiz </p>
          <p className=" max-md:hidden">Yükle</p>
        </button>

        <button
          onClick={() => setTakeBackQuotaDesignModal(true)}
          className={`${styles.buttonSearch} md:ml-4 w-fit h-fit max-md:border-l-4 max-md:border-b-4 max-md:border-r-4 max-md:border-white`}
        >
          <p className=" max-md:hidden">Kota </p>
          <p>İade </p> <p className=" max-md:hidden">Al</p>
        </button>
      </div>

      {responseDatas.isLoading ||
      resultDelete.isLoading ||
      resultUpdateGive.isLoading ||
      resultUpdateTakeback.isLoading ||
      responseDatas.status !== "fulfilled" ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : responseDatas.error || responseDatas?.data?.results === 0 ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10`}
        >
          <UnfindDataWarn Data={"Fatura bulunamadı !"} />
        </div>
      ) : (
        <>
          <div
            className={`bg-white shadow-lg w-full mt-10  rounded-xl  transition-all duration-300 mb-4 ${
              true ? "block p-10" : " overflow-hidden h-0"
            }`}
          >
            <DataSearchBar
              Data={[...DataBill].sort((a, b) => {
                const dateA = new Date(a.createdInfo);
                const dateB = new Date(b.createdInfo);
                return dateB - dateA; // Büyükten küçüğe doğru sıralama yapmak için dateB - dateA kullanılır.
              })}
              handleSearch={HandleSearch}
              inputFieldName={inputFieldName}
            />
          </div>
          <div className="flex flex-col h-full items-center gap-6 bg-white rounded-xl   shadow border ">
            <ListInfo
              Config={config}
              Key={"_id"}
              SetPaginationNumber={setPaginationNumber}
              PaginationNumber={paginationNumber}
              IsSearch={isSearch}
              FilteredData={filteredData}
              Data={[...DataBill].sort((a, b) => {
                const dateA = new Date(a.createdInfo);
                const dateB = new Date(b.createdInfo);
                return dateB - dateA; // Büyükten küçüğe doğru sıralama yapmak için dateB - dateA kullanılır.
              })}
            />
          </div>
        </>
      )}

      <DesignModal
        onClose={() => {
          setShowConfirmModal(false);
          setGiveQuotaDesignModal(false);
          setTakeBackQuotaDesignModal(false);
          setAction(0);
        }}
        designModal={giveQuotaDesignModal || takeBackQuotaDesignModal}
        Tag={DesignTag}
      />
    </div>
  );
}

export default UpdateBill;
