import { Calendar } from "react-calendar";
import styled from "styled-components";

const StyledCalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */
  /* ... */

  /* ~~~ navigation styles ~~~ */
  .react-calendar__navigation {
    display: flex;

    .react-calendar__navigation__label {
      font-weight: bold;
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    }
  }
  /* ~~~ label styles ~~~ */
  .react-calendar__month-view__weekdays {
    text-align: center;
  }

  /* ~~~ button styles ~~~ */
  button {
    margin: 3px;
    background-color: #2c76bf;
    border: 0;
    border-radius: 3px;
    color: white;
    padding: 10px 24px;
    border: 3px solid #2c76bf;

    &:hover {
      background-color: #ffff;
      color: #004080;
      border: 3px solid #2c76bf;
    }

    &:active {
      background-color: #a5c1a5;
    }
  }

  /* ~~~ day grid styles ~~~ */
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
    .react-calendar__tile {
      max-width: initial !important;
    }
  }
  /* ~~~ neighboring month & weekend styles ~~~ */
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.7;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #ffff;
  }
  /* ~~~ active day styles ~~~ */
  .react-calendar__tile--range {
    background-color: #bf7d2c;
    border: 3px solid #bf7d2c;
  }
  /* ~~~ other view styles ~~~ */
  .react-calendar__year-view__months,
  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;

    &.react-calendar__year-view__months {
      grid-template-columns: 33.3% 33.3% 33.3%;
    }

    .react-calendar__tile {
      max-width: initial !important;
    }
  }
  /* Mobil varsayılan stil tanımlamaları */
  button {
    margin: 1px;
    padding: 4px 16px;
  }

  /* Tablet için stil tanımlamaları */
  @media (min-width: 768px) {
    button {
      padding: 9px 20px;
    }
  }

  /* Masaüstü için stil tanımlamaları */
  @media (min-width: 1024px) {
    button {
      padding: 10px 24px;
    }
  }
`;

export default function CalendarContainer({ onChange, value }) {
  return (
    <StyledCalendarContainer>
      <Calendar locale="tr-TR" onChange={onChange} value={value} />
    </StyledCalendarContainer>
  );
}
