import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
//Icons
import { MdOutlineKeyboardBackspace } from "react-icons/md";

import { BsPersonPlusFill } from "react-icons/bs";
import styles from "../../CustomStyles";

function CreateUserLayout() {
  const TopInformation = function () {
    return (
      <div className=" z-40 w-full h-fit flex items-center justify-between border-b-4 border-main">
        <div className="flex overflow-y-scroll no-scrollbar">
          <div
            className={`flex items-center px-2 z-20 transition-all duration-200  
           text-main gap-4`}
          >
            <BsPersonPlusFill className={`${styles.PageHeaderIcon}`} />
            <p className={`${styles.PageHeader}`}>Kullanıcı Oluştur</p>
          </div>
        </div>
        <NavLink
          to="/Anasayfa/Kullanıcı"
          className={`flex px-6 py-3 z-20 transition-all duration-200  
          text-main hover:text-red-400 gap-4`}
        >
          <MdOutlineKeyboardBackspace className={`${styles.PageHeaderIcon}`} />
        </NavLink>
      </div>
    );
  };

  return (
    <div>
      <TopInformation />
      <div
        className={`flex w-full 
          mt-[2rem] flex-col justify-between gap-4 
          rounded-xl text-black bg-white`}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default CreateUserLayout;
