import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";

export default function SelectOptions(props) {
  const {
    name,
    label,
    error = null,
    value,
    onChange,
    options,
    disabled = false,
  } = props;

  return (
    <FormControl
      className=" w-full"
      key={name}
      variant="outlined"
      {...(error && { error: true })}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        label={label}
        name={name}
        value={value || ""}
        onChange={onChange}
      >
        {options.map((item) => (
          <MenuItem key={item.title} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
