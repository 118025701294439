import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import jsQR from "jsqr";
import styles from "../../CustomStyles";
import { useUploadManualQRMutation } from "../../store";
import useAuth from "../../Hooks/useAuth";
import { useUploadManualQuotaMutation } from "../../store/devicesApi";

export default function ManualQuota({ setIsOpen, isOpen }) {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [quota, setQuota] = useState(0);
  const [qrContent, setQrContent] = useState("");
  const [deviceInfo, setDeviceInfo] = useState();
  const [openKey, setOpenKey] = useState("");

  const [uploadManualQR, resultUploadManualQR] = useUploadManualQRMutation();
  const [uploadManualQuota, resultUploadManualQuota] =
    useUploadManualQuotaMutation();

  const handleInputChange = (event) => {
    setQuota(event.target.value);
  };

  useEffect(() => {
    if (resultUploadManualQR.data?.data) {
      setDeviceInfo(resultUploadManualQR.data.data);
    }
  }, [resultUploadManualQR]);

  useEffect(() => {
    if (resultUploadManualQuota.data?.data) {
      setDeviceInfo(resultUploadManualQuota.data.data);
    }
  }, [resultUploadManualQuota]);

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={() => {
        setQrContent("");
        setDeviceInfo();
        setIsOpen(false);
      }}
    >
      <div className=" flex flex-col w-full items-center justify-center content-center gap-4">
        <div className=" flex flex-col items-center gap-2">
          <input
            type="text"
            value={qrContent}
            onChange={(event) => setQrContent(event.target.value)}
            placeholder=""
            style={{
              padding: "10px",
              borderRadius: "5px",
              width: "100%",
              borderWidth: "1.5px",
              borderColor: "black",
            }}
          />
          <button
            className={`${styles.button}`}
            onClick={() => {
              uploadManualQR({ token, data: qrContent });
            }}
          >
            <p>Kodu Yükle</p>
          </button>
        </div>
        {deviceInfo && (
          <div className=" flex flex-col w-full gap-1">
            <p>
              <b>Ürün:</b> {deviceInfo.device.type}
            </p>
            <p>
              <b>Seri No:</b> {deviceInfo.device.serialNo}
            </p>
            <p>
              <b>Makine Kota:</b> {deviceInfo.device.quota}
            </p>
            <p>
              <b>Sayaç:</b> {deviceInfo.device.totalCounter}
            </p>
            <p>
              <b>Sistemdeki Kota:</b> {deviceInfo.targetProductInfo.quota}
            </p>
            {deviceInfo.encryptedKey && (
              <div className="rounded-lg bg-sale p-4 text-center text-white break-words">
                <p>
                  <b>Makine Yükleme Kodu:</b>
                </p>
                <p>{deviceInfo.encryptedKey}</p>
              </div>
            )}
          </div>
        )}
        {deviceInfo && (
          <div className=" flex flex-col items-center gap-2">
            <input
              type="number"
              value={quota}
              onChange={handleInputChange}
              placeholder="Enter quota"
              style={{
                padding: "10px",
                borderRadius: "5px",
                width: "100%",
                borderWidth: "1.5px",
                borderColor: "black",
              }}
            />

            <button
              className={`${styles.button}`}
              onClick={() => {
                if (quota) {
                  uploadManualQuota({
                    token,
                    data: qrContent,
                    quota: quota,
                  });
                }
              }}
            >
              <p>Kod Üret</p>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: 200,
    transform: "translate(-50%, -50%)",
  },
};
