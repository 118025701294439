import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import Alerts from "../../../Components/Utils/Alert";
import Confirm from "../../../Components/Utils/Confirm";
import {
  useGetDeviceQuery,
  useUpdateDeviceInformationMutation,
} from "../../../store";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import DeviceInfoEditForm from "../../../Forms/DeviceForm/DeviceInfoEditForm";

function UpdateInfo() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [designedInput, setDesignedInput] = useState({});
  const responseData = useGetDeviceQuery({ token, id });
  const [updateDevice, resultUpdateDevice] =
    useUpdateDeviceInformationMutation();
  const Device = responseData?.data?.data?.device || [];

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 3000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setConfirm(false);
      setAlert(2);
      console.log(apiResponse.error.data.message);
      setMessage(apiResponse.error.data.message);
    }
    if (apiResponse.isSuccess) {
      setConfirm(false);
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultUpdateDevice, "Cihaz güncellendi !");
  }, [resultUpdateDevice.isSuccess, resultUpdateDevice.isError]);

  const handleCloseModel = (boolean) => {
    console.log(designedInput);
    if (boolean) {
      setConfirm(true);
      updateDevice({ ...designedInput, _id: Device._id, token });
    }
    setShowConfirmModal(false);
  };

  return (
    <div>
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={designedInput}
        infoText={"Bilgileri kaydetmek istediğinize emin misiniz?"}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      {responseData.isFetching ||
      responseData.status !== "fulfilled" ||
      confirm ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center`}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : responseData.error ? (
        <UnfindDataWarn Data={"Cihaz bulunamadı"} />
      ) : (
        <DeviceInfoEditForm
          input={{ ...Device }}
          setDesignedInput={setDesignedInput}
          setShowConfirmModal={setShowConfirmModal}
          token={token}
          initialFValues={{
            note: Device.note,
          }}
        />
      )}
    </div>
  );
}

export default UpdateInfo;
