import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import {
  useDowloadSettingQuery,
  useUpdateSettingMutation,
} from "../../../../store";
import useAuth from "../../../../Hooks/useAuth";
import Loader from "../../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../../Components/Utils/UnfindDataWarn";
import Alerts from "../../../../Components/Utils/Alert";
import Confirm from "../../../../Components/Utils/Confirm";
import { useEffect, useState } from "react";
import DeviceSettingEditForm from "../../../../Forms/DeviceForm/DeviceSettingEditForm";
import styles from "../../../../CustomStyles";

export default function GeneralSetting() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const location = useLocation();
  const [GeneralSettings, setGeneralSettings] = useState({});
  const [DesignedGeneralSettings, setDesignedGeneralSettings] = useState({});
  const [updateSetting, resultUpdateSetting] = useUpdateSettingMutation();
  const responseGeneralSetting = useDowloadSettingQuery({
    id: location.state.id,
    token: token,
    type: "GS",
  });
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [inputFieldName, setInputFieldName] = useState({});
  const [initialFValues, setInitialFValues] = useState({});

  const generateInitialFValues = (settings) => {
    return Object.keys(settings).reduce((acc, key) => {
      acc[key] = settings[key].value;
      return acc;
    }, {});
  };

  const secondGenerateInitialFValues = (settings) => {
    return Object.keys(settings).reduce((acc, key) => {
      acc[key] = settings[key];
      return acc;
    }, {});
  };

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError && confirm) {
      setConfirm(false);
      setAlert(2);
      setMessage(apiResponse.error.data.message);
    }
    if (apiResponse.isSuccess && confirm) {
      setConfirm(false);
      setAlert(1);
      setMessage(successMessage);
      setInitialFValues(secondGenerateInitialFValues(DesignedGeneralSettings));
    }
  };

  const getChangedValues = (original, initial, updated) => {
    const changes = { index: updated.index };
    const label = {};
    const lastValue = {};
    for (const key in initial) {
      if (updated.hasOwnProperty(key)) {
        if (initial[key] !== updated[key]) {
          if (original[key].type === "Number") {
            changes[key] = Number(updated[key]);
            lastValue[key] = Number(initial[key]);
          } else {
            changes[key] = updated[key];
            lastValue[key] = initial[key];
          }
          label[key] = original[key].label;
        }
      }
    }
    return { changes, label, lastValue };
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);
      const changes = getChangedValues(
        GeneralSettings,
        initialFValues,
        DesignedGeneralSettings
      );
      updateSetting({
        id: location.state.id,
        token,
        key: "generalSetting",
        type: "GS",
        data: changes,
      });
    }
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (responseGeneralSetting.status === "fulfilled") {
      const tempGeneralSetting = {
        ...responseGeneralSetting?.data?.data?.settings,
      };
      setGeneralSettings(tempGeneralSetting);
      setInitialFValues(generateInitialFValues(tempGeneralSetting));
      setDesignedGeneralSettings(tempGeneralSetting);
    }
  }, [responseGeneralSetting.isSuccess]);

  useEffect(() => {
    handleApiResponse(resultUpdateSetting, "Ayarlar cihaza gönderildi !");
  }, [resultUpdateSetting.isSuccess, resultUpdateSetting.isError]);

  return (
    <>
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={DesignedGeneralSettings}
        inputFieldName={inputFieldName}
        infoText={"Bilgileri kaydetmek istediğinize emin misiniz?"}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      {responseGeneralSetting.isFetching || confirm || alert !== 0 ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center`}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : responseGeneralSetting.error ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10`}
        >
          <UnfindDataWarn Data={"Cihaz bağlantısı yok !"} />
        </div>
      ) : (
        <div className=" bg-white rounded-md px-2 py-4 mt-6 mb-20 w-full">
          {GeneralSettings && (
            <DeviceSettingEditForm
              Settings={GeneralSettings}
              setDesignedInput={setDesignedGeneralSettings}
              setInputFieldName={setInputFieldName}
              setShowConfirmModal={setShowConfirmModal}
              initialFValues={initialFValues}
            />
          )}
        </div>
      )}
      {responseGeneralSetting.error &&
        !(responseGeneralSetting.isFetching || confirm) && (
          <button
            onClick={() => {
              responseGeneralSetting.refetch();
            }}
            className={` z-20 transition-all duration-200 text-main active:scale-90 
            fixed flex flex-col items-center justify-center md:bottom-10 md:right-10 rounded-full
            bottom-8 max-md:px-4 max-md:py-2 max-md:text-white max-md:bg-main max-md:left-1/2 max-md:-translate-x-1/2 max-md:pl-1 max-md:border-white max-md:border-b-4  max-md:border-l-4 max-md:border-r-4 `}
          >
            <FontAwesomeIcon
              className=" max-md:hidden"
              style={{ width: "4rem", height: "4rem" }}
              icon={faCloudArrowDown}
            />
            <p className={`${styles.BigtextTitle} `}>Ayarları İndir</p>{" "}
          </button>
        )}
    </>
  );
}
