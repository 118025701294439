import { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { BiPlusMedical } from "react-icons/bi";
import DataSearchBar from "./DataSearchBar";
import styles from "../../CustomStyles";

export default function TopSearchInfo({
  Length,
  TopInfo,
  AddButton,
  SearchBarData,
  HandleSearch,
  SearchInputFieldName,
}) {
  const [searchBar, setSearchBar] = useState(true);

  const hideSearchBar = () => {
    setSearchBar(searchBar === true ? false : true);
  };

  return (
    <div className=" w-full flex flex-col gap-4">
      <div className="flex  gap-3 items-center text-main w-fit">
        <div className=" flex items-center gap-2 ">
          <div className={`${styles.PageHeader}`}>{TopInfo}</div>
          <div
            className={`${styles.PageHeader}  bg-main text-white px-2 h-fit rounded-full`}
          >
            {Length}
          </div>
        </div>
        <div
          onClick={() => {
            AddButton();
          }}
          className={`xl:text-xl md:text-BASE cursor-pointer text-SM items-center gap-4 text-white 
          flex bg-main rounded-md px-2 py-2 transition-all duration-300 active:scale-90`}
        >
          <p className=" font-SemiBold">Ekle</p>
          <BiPlusMedical className={`xl:w-8 xl:h-8 md:w-7 md:h-7 w-5 h-5`} />
        </div>
        <button
          className={`xl:text-xl max-md:hidden md:text-BASE cursor-pointer text-SM items-center gap-4 text-white 
          flex justify-center bg-main rounded-md px-4 py-2 transition-all duration-300 active:scale-90`}
          onClick={hideSearchBar}
        >
          <p>FİLTRELE</p>
          <IoIosArrowUp
            className={`${
              searchBar ? "flex" : "hidden"
            } xl:w-8 xl:h-8 md:w-7 md:h-7 w-5 h-5 transition-all duration-500`}
          />
          <IoIosArrowDown
            className={`${
              searchBar ? "hidden" : "flex"
            } xl:w-8 xl:h-8 md:w-7 md:h-7 w-5 h-5 transition-all duration-500`}
          />
        </button>
      </div>

      <div
        className={`bg-white px-4 rounded-xl shadow transition-all duration-300 mb-6 ${
          searchBar ? "block py-6" : " overflow-hidden h-0"
        }`}
      >
        <DataSearchBar
          Data={SearchBarData}
          handleSearch={HandleSearch}
          inputFieldName={SearchInputFieldName}
        />
      </div>
    </div>
  );
}
