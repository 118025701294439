import styles from "../../CustomStyles";

export default function FilterButton({ button, handleClick, active }) {
  const activeStyle = "bg-white text-main border-main";
  const passiveStyle =
    "bg-main text-white border-white hover:bg-white hover:text-main ";

  return (
    <div className="flex justify-center gap-2 md:gap-4 ">
      <button
        onClick={() => {
          handleClick("today");
        }}
        className={`${button} ${styles.text} ${
          active === "today" ? activeStyle : passiveStyle
        }`}
      >
        Bugün
      </button>
      <button
        onClick={() => {
          handleClick("yesterday");
        }}
        className={`${button} ${styles.text} ${
          active === "yesterday" ? activeStyle : passiveStyle
        }`}
      >
        Dün
      </button>
      <button
        onClick={() => {
          handleClick("thisweek");
        }}
        className={`${button} ${styles.text} ${
          active === "thisweek" ? activeStyle : passiveStyle
        }`}
      >
        Haftalık
      </button>
      <button
        onClick={() => {
          handleClick("thismonth");
        }}
        className={`${button} ${styles.text} ${
          active === "thismonth" ? activeStyle : passiveStyle
        }`}
      >
        Aylık
      </button>
      <button
        onClick={() => {
          handleClick("thisyear");
        }}
        className={`${button} ${styles.text} ${
          active === "thisyear" ? activeStyle : passiveStyle
        }`}
      >
        Yıllık
      </button>
    </div>
  );
}
