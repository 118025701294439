import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import styles from "../../CustomStyles";
import { useUploadVideoMutation } from "../../store";
import { useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";

export default function VideoUpload(props) {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const location = useLocation();
  const { id } = location.state;

  const { width, height } = props;
  const [uploadVideo, resultUploadVideo] = useUploadVideoMutation();

  const inputRef = useRef();

  const [source, setSource] = useState();
  const [file, setFile] = useState();

  const handleFileChange = (event) => {
    const url = URL.createObjectURL(event.target.files[0]);
    setSource(url);
    setFile(event.target.files[0]);
  };

  const handleChoose = (event) => {
    console.log("g");
    console.log(file);
    uploadVideo({ id: id, token: token, videoFile: file });
  };

  return (
    <form
      className={`  ${!source ? " cursor-pointer mt-10" : ""}`}
      onClick={() => {
        if (!source) document.querySelector(".input-video").click();
      }}
    >
      {!source && (
        <div className=" relative">
          <input
            ref={inputRef}
            className="input-video"
            type="file"
            onChange={handleFileChange}
            accept=".mov,.mp4"
            hidden
          />
          <div
            className={`transition-all duration-200 text-main 
            absolute top-1/2 -translate-y-1/2 flex flex-col items-center justify-center
            `}
          >
            <FontAwesomeIcon
              style={{ width: "4rem", height: "4rem" }}
              icon={faCloudArrowUp}
            />
            <p className={`${styles.BigtextTitle} w-full h-full`}>Video Seç</p>
          </div>
        </div>
      )}
      {source && (
        <div className=" relative">
          <video
            className="VideoInput_video"
            width="100%"
            height={height}
            controls
            src={source}
          />
          <div
            onClick={handleChoose}
            className={` cursor-pointer active:opacity-50 transition-all duration-200 text-main  w-fit
           flex flex-col justify-center items-center rounded-md border-dotted border-main border-4 mt-10 p-4
          `}
          >
            <FontAwesomeIcon
              style={{ width: "4rem", height: "4rem" }}
              icon={faCloudArrowUp}
            />
            <p className={`${styles.BigtextTitle} w-full h-full`}>
              Video Yükle
            </p>
          </div>
        </div>
      )}
    </form>
  );
}
