import React from "react";
import Controls from "../Components/Form/Controls";
import { useForm } from "../Components/Form/useForm";
import styles from "../CustomStyles";
import { AiFillDelete } from "react-icons/ai";

export default function DeleteForm({ handleOpenModal, initialFValues }) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "Şifreyi girin.";
    if ("passwordConfirm" in fieldValues)
      temp.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "Şifreyi onaylamalısınız.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleOpenModal(values);
    }
  };

  return (
    <form
      className=" bg-white rounded-md px-2 shadow-md flex max-sm:flex-col gap-4 h-fit overflow-y-scroll no-scrollbar py-4 "
      onSubmit={handleSubmit}
    >
      <Controls.Input
        label="Şifre"
        name="password"
        type="password"
        value={values.password}
        onChange={handleInputChange}
        error={errors.password}
      />
      <Controls.Input
        label="Şifre Onay"
        name="passwordConfirm"
        type="passwordConfirm"
        value={values.passwordConfirm}
        onChange={handleInputChange}
        error={errors.passwordConfirm}
      />
      <button className={` w-fit h-fit rounded-full ${styles.button}`}>
        <AiFillDelete className={` ${styles.buttonIcon}`} />
        <p>Sil</p>
      </button>
    </form>
  );
}
