import { ThreeDots } from "react-loader-spinner";
import BillTable from "./BillTable";
import styles from "../../../CustomStyles";

export default function BillInfoCard({ warn, bills, isLoading, isFetching }) {
  return (
    <div className=" flex flex-col p-4 shadow-lg bg-white w-2/6 max-xl:w-full  h-full rounded-lg ">
      {isFetching || isLoading ? (
        <div className="flex-1  flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="bg-main"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : bills.length === 0 ? (
        <div className=" flex w-full h-full row-start-4 col-start-2 items-center  justify-center font-SemiBold tracking-widest">
          <p className={`${styles.Bigtext}  p-4 rounded-md text-black`}>
            {warn}
          </p>
        </div>
      ) : (
        <>
          <div className=" flex gap-2 ml-2">
            <p
              className={` font-SemiBold text-2xl tracking-widest text-main z-10`}
            >
              Fatura Hareketleri
            </p>
          </div>
          <BillTable data={bills} />
        </>
      )}
    </div>
  );
}
