import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";
import { useGetFirmProductsQuery } from "../../store";

export default function BillQuotaDesignForm({
  HandleModal,
  initialFValues,
  type = "",
  ProductDatas = [],
}) {
  const ProductDataOptions = [
    ...ProductDatas?.map((option) => {
      return { quota: option.quota, title: option.productName };
    }),
  ];
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("productName" in fieldValues)
      temp.productName = fieldValues.productName ? "" : "Ürün seçiniz.";
    if ("quota" in fieldValues)
      temp.quota = fieldValues.quota ? "" : "Kota değerini girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      HandleModal(values);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <form
      className=" grid gap-4 w-full items-center justify-center border-main border-4 bg-white p-4 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <p className={` ${styles.BigtextTitle} text-center text-main`}>{type}</p>
      <div className=" flex flex-col gap-4 w-full ">
        <Controls.SelectOptions
          label="Ürün"
          name="productName"
          value={values.productName}
          onChange={handleInputChange}
          options={ProductDataOptions}
          error={errors.productName}
        />
        <Controls.Number
          label="Kota"
          name="quota"
          value={values.quota}
          onChange={handleInputChange}
          error={errors.quota}
          min={0}
          max={10000}
        />
      </div>

      <button
        className={`${styles.buttonSearch}  
     px-6 py-3 w-fit self-center mb-2`}
      >
        <p>Faturayı Oluştur</p>
      </button>
    </form>
  );
}
