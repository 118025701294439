import { Outlet, useLocation } from "react-router-dom";
import { SlGraph } from "react-icons/sl";

//Icons
import { TbListDetails } from "react-icons/tb";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BsInfoLg } from "react-icons/bs";

import styles from "../../CustomStyles";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import TopBar from "../../Components/Utils/TopBar";
import useAuth from "../../Hooks/useAuth";

function InfoFirmPage() {
  const location = useLocation();
  const { bayserNo, firmName, isActive, createdInfo, updatedInfo } =
    location.state;
  const { auth } = useAuth();

  const links = [
    {
      to: "Genel",
      state: {
        id: location.state.id,
        bayserNo: location.state.bayserNo,
        isActive: location.state.bayserNo,
        firmName: location.state.firmName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Genel Bilgiler</p>
      ),
      icon: <BsInfoLg className={`${styles.PageHeaderIcon}`} />,
    },
    ...(auth.role === "admin" || auth.role === "management"
      ? [
          {
            to: "İstatistik",
            state: {
              id: location.state.id,
              bayserNo: location.state.bayserNo,
              isActive: location.state.bayserNo,
              firmName: location.state.firmName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>İstatistik</p>
            ),
            icon: (
              <SlGraph className={`${styles.PageHeaderIcon}  max-md:ml-4`} />
            ),
          },
          {
            to: "Rapor",
            state: {
              id: location.state.id,
              bayserNo: location.state.bayserNo,
              isActive: location.state.bayserNo,
              firmName: location.state.firmName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
            },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>Rapor</p>
            ),
            icon: (
              <TbListDetails
                className={`${styles.PageHeaderIcon} max-md:ml-4`}
              />
            ),
          },
        ]
      : []),
  ];
  const backLink = {
    path: "/Anasayfa/Firma",
    icon: <MdOutlineKeyboardBackspace className={`${styles.PageHeaderIcon}`} />,
  };

  return (
    <div>
      {/* Device Top Information Close Button */}
      <TopBar links={links} backLink={backLink} />

      {/* Device Important Info. */}
      <div
        className={`flex w-full 
          mt-[2rem] flex-col justify-between gap-4 
          rounded-xl text-black`}
      >
        <Outlet />
      </div>
      <div
        className=" z-10 md:bg-[#053B50] md:w-screen md:pl-8 pl-4
       bg-main text-white w-full max-md:left-0 bottom-0 text-center max-md:h-12
       fixed md:bottom-0 md:left-20 flex gap-4 items-center  max-md:pt-2 "
      >
        <p className={`${styles.textTitle}`}>Bayser Numarası : {bayserNo}</p>
        <p className={`${styles.textTitle} `}>{firmName}</p>
        <p className={`${styles.textTitle} max-md:hidden`}>
          Oluşturulma : {FormatDateHourString(createdInfo)}
        </p>
        <p className={`${styles.textTitle} max-md:hidden`}>
          Son Güncelleme : {FormatDateHourString(updatedInfo)}
        </p>
      </div>
    </div>
  );
}

export default InfoFirmPage;
